import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RenderItemNameCell } from "../../../utils/customCellRenderer";
import { formatNumber } from "../../../utils/dataTransformer";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";

const ManuItemListTable = ({ control, errors, disabled, isUnderQuality }) => {
  const [headers, setHeaders] = useState([]);
  const { manufactureOrder } = useSelector((state) => state.manufactureOrder);
  const { item } = useSelector((state) => state.itemMaster);
  const [itemState, setItemState] = useState({});

  useEffect(() => {
    setHeaders([
      { label: "รายการ", width: 40 },
      { label: "ชื่อสินค้า", width: 250 },
      { label: "จำนวนสั่งผลิตจริง", width: 150 },
      { label: "จำนวนนำเข้า", width: 150 },
      { label: "หน่วย", width: 150 },
    ]);
  }, [disabled, isUnderQuality]);

  useEffect(() => {
    if (item.document_id) {
      setItemState(item);
    }
  }, [item]);

  // const handleChange = (v, field) => {
  //   field.onChange(v);
  // };

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" key={index} width={header.width}>
                  <Typography textAlign="center" fontSize={14} fontWeight={700}>
                    {header.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <Typography fontSize={14}>{1}</Typography>
              </TableCell>
              <TableCell align="center">
                <RenderItemNameCell
                  data={{
                    ...itemState,
                    item_document_id: itemState?.document_id,
                    item_name: itemState?.name,
                    item_description: itemState?.description,
                  }}
                  index={1}
                  control={control}
                  errors={errors}
                  viewOnly={true}
                  // handleShowItemModal={handleShowItemModal}
                />
              </TableCell>
              <TableCell align="center">
                {disabled ? (
                  <Typography fontSize={14}>
                    {formatNumber(manufactureOrder.actual_production_qty) || 0}
                  </Typography>
                ) : (
                  <CustomizedNumberTextField
                    control={control}
                    name="actual_production_qty"
                    disabled={disabled}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: "10px" },
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={14}>
                  {formatNumber(manufactureOrder.goods_receive_qty)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize={14}>
                  {manufactureOrder.stock_uom}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManuItemListTable;
