import { Box, Typography } from "@mui/material";
import React from "react";

const PDFHeader = ({ documentType, noCompany, children }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img height={40} alt="vpi-logo" src="/static/logo.png" />
          <Box ml={5} width={700}>
            {children}
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            โอนย้าย
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
