import InventoryService from "../../../services/Inventory";
import {
  createActivityLogPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { goodsTransferActions } from "./goodsTransfer-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { createLog } from "../../../services/Logs/firebase";

export const getAllGoodsTransfer = (input, params) => async (dispatch) => {
  dispatch(goodsTransferActions.onLoading("allItems"));
  try {
    const allItems = await InventoryService.getAllGTAggrid(input);
    if (params) params.successCallback(allItems.results, allItems.count);
    dispatch(goodsTransferActions.loadedAllItems(allItems.results));
    dispatch(goodsTransferActions.loadedAllCount(allItems.count));
  } catch (err) {
    dispatch(
      goodsTransferActions.rejectedActions({ ...err, name: "allItems" })
    );
    params.failCallback();
  }
};

export const getAllGoodsTransferNoItem =
  (input, params) => async (dispatch) => {
    dispatch(goodsTransferActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGTAggridNoItem(input);
      dispatch(goodsTransferActions.loadedAllItems(allItems.results));
      dispatch(goodsTransferActions.loadedAllCount(allItems.count));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      dispatch(
        goodsTransferActions.rejectedActions({ ...err, name: "allItems" })
      );
      params.failCallback();
    }
  };

export const getAllGoodsTransferReport =
  (input, params) => async (dispatch) => {
    dispatch(goodsTransferActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGTReportAggrid(input);
      if (params) params.successCallback(allItems.results, allItems.count);
      dispatch(goodsTransferActions.loadedAllItems(allItems.results));
      dispatch(goodsTransferActions.loadedAllCount(allItems.count));
    } catch (err) {
      dispatch(
        goodsTransferActions.rejectedActions({ ...err, name: "allItems" })
      );
      params.failCallback();
    }
  };

export const getGoodsTransferById = (input) => async (dispatch) => {
  dispatch(goodsTransferActions.onLoading("item"));
  try {
    const Item = await InventoryService.getGT(input);
    dispatch(goodsTransferActions.loadedItem(Item));
  } catch (err) {
    dispatch(goodsTransferActions.rejectedActions({ ...err, name: "item" }));
  }
};

export const createGoodsTransferWithApprove =
  (input, enqueueSnackbar, navigate, user, location) => async (dispatch) => {
    dispatch(goodsTransferActions.onLoading("item"));
    try {
      const item = await InventoryService.createGTWithApprove(input);
      navigate(`/inventory/transfer/${encodeURIComponent(item.document_id)}`);
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารโอนย้ายสำเร็จ", {
          variant: "success",
        });
      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_transfer",
        "สร้างใบนำออก"
      );
      const createActivityLogApprove = createActivityLogStatusPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_transfer",
        null,
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLogApprove,
      });
      createLog(
        "info",
        "goodsTransferCreateWithApprove",
        location,
        user?.email,
        input,
        item
      );
    } catch (err) {
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารโอนย้ายไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      createLog(
        "error",
        "goodsTransferCreateWithApprove",
        location,
        user?.email,
        input,
        err
      );
      dispatch(goodsTransferActions.rejectedActions({ ...err, name: "item" }));
    }
  };
