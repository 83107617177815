import { createContext, useEffect, useReducer } from "react";
import AuthService from "../services/Auth";
import jwt from "jwt-decode";
import moment from "moment";
import Cookies from "universal-cookie";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["LOGIN"] = "LOGIN";
  ActionType["LOGOUT"] = "LOGOUT";
  ActionType["VERIFY"] = "VERIFY";
  ActionType["REFRESH"] = "REFRESH";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isAccessTokenExpired: false,
  isRefreshTokenExpired: false,
  user: null,
  permissions: [],
};

const cookies = new Cookies();

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
});

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      isAccessTokenExpired,
      isRefreshTokenExpired,
      user,
      permissions,
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isAccessTokenExpired,
      isRefreshTokenExpired,
      isInitialized: true,
      user,
      permissions,
    };
  },
  LOGIN: (state, action) => {
    const { user, permissions } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isAccessTokenExpired: false,
      isRefreshTokenExpired: false,
      user,
      permissions,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isAccessTokenExpired: true,
    isRefreshTokenExpired: true,
    user: null,
    permissions: [],
  }),
  VERIFY: (state, action) => {
    const { permissions, isAuthenticated } = action.payload;

    return {
      ...state,
      permissions,
      isAuthenticated,
      isInitialized: true,
    };
  },
  REFRESH: (state, action) => {
    const { isAccessTokenExpired, isRefreshTokenExpired, user } =
      action.payload;

    return {
      ...state,
      user,
      isAccessTokenExpired,
      isRefreshTokenExpired,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      const accessToken = await cookies.get("access_token");
      const refreshToken = await cookies.get("refresh_token");
      try {
        if (accessToken) {
          const user = await AuthService.verifyToken();
          const { role_list, ...otherUser } = user;
          let allPermissions = ["GENERAL__GENERAL__VIEW"];
          role_list?.forEach((role) =>
            allPermissions.push(...role.permission_list)
          );
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              isAccessTokenExpired: false,
              isRefreshTokenExpired: refreshToken ? false : true,
              user: otherUser,
              permissions: allPermissions,
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              permission: [],
              isAccessTokenExpired: true,
              isRefreshTokenExpired: refreshToken ? false : true,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            permission: [],
            isAccessTokenExpired: accessToken ? false : true,
            isRefreshTokenExpired: refreshToken ? false : true,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email, password) => {
    const auth = await AuthService.postLogin({
      email: email.toLowerCase(),
      password,
    });
    const { exp } = await jwt(auth.token.access_token);
    const { exp: refreshTokenExp } = await jwt(auth.token.refresh_token);
    const formatExpireDate = moment.unix(exp).format();
    const formatRefreshExpireDate = moment.unix(refreshTokenExp).format();

    cookies.set("access_token", auth.token.access_token, {
      expires: new Date(formatExpireDate),
      path: "/",
    });

    cookies.set("refresh_token", auth.token.refresh_token, {
      expires: new Date(formatRefreshExpireDate),
      path: "/",
    });

    const user = auth.employee;

    if (user) {
      const { role_list, ...otherUser } = user;
      let allPermissions = ["GENERAL__GENERAL__VIEW"];
      role_list?.forEach((role) =>
        allPermissions.push(...role.permission_list)
      );
      dispatch({
        type: ActionType.LOGIN,
        payload: {
          user: otherUser,
          permissions: allPermissions,
        },
      });
    }
  };

  const logout = async () => {
    cookies.remove("access_token", { path: "/" });
    cookies.remove("refresh_token", { path: "/" });
    dispatch({ type: ActionType.LOGOUT });
  };

  const verify = async () => {
    const user = await AuthService.verifyToken();
    const { role_list } = user;
    let allPermissions = ["GENERAL__GENERAL__VIEW"];
    role_list?.forEach((role) => allPermissions.push(...role.permission_list));
    dispatch({
      type: ActionType.VERIFY,
      payload: {
        permissions: allPermissions,
        isAuthenticated: true,
      },
    });
  };

  const refresh = async () => {
    const accessToken = await cookies.get("access_token");
    if (!accessToken) {
      const refreshToken = await cookies.get("refresh_token");
      if (refreshToken) {
        dispatch({
          type: ActionType.REFRESH,
          payload: {
            isAccessTokenExpired: true,
            isRefreshTokenExpired: false,
          },
        });
        try {
          const auth = await AuthService.postRefreshToken();
          const { exp } = await jwt(auth.access_token);
          const formatExpireDate = moment.unix(exp).format();
          cookies.set("access_token", auth.access_token, {
            expires: new Date(formatExpireDate),
            path: "/",
          });
          const user = await AuthService.verifyToken();
          const { role_list, ...otherUser } = user;
          dispatch({
            type: ActionType.REFRESH,
            payload: {
              user: otherUser,
              isAccessTokenExpired: false,
              isRefreshTokenExpired: false,
            },
          });
        } catch (err) {
          dispatch({
            type: ActionType.REFRESH,
            payload: {
              user: null,
              isAccessTokenExpired: true,
              isRefreshTokenExpired: true,
            },
          });
        }
      } else {
        dispatch({
          type: ActionType.REFRESH,
          payload: {
            user: null,
            isAccessTokenExpired: true,
            isRefreshTokenExpired: true,
          },
        });
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        verify,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
