import { Box, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedTab from "../../../components/Custom/CustomizedTab";
import { deliveryTripActions } from "../../../features/Logistic/DeliveryTrip/delivery-trip-slice";
import DeliveryTripTable from "../../../components/Table/DocumentTable/DeliveryTripTable";
import { useSnackbar } from "notistack";
import { getAllDeliveryTrips } from "../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { filterDateWithUnix } from "../../../utils/filterparams";
import { usePermission } from "../../../hooks/use-auth";

const DeliveryTrip = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFilter = searchParams.get("filter");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const { createPermission } = usePermission();

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.deliveryTrip.index"),
    },
  ];

  const currentTab = pathname + (isFilter ? `?filter=${isFilter}` : "");

  const tabs = [
    {
      label: t("inventory.all"),
      path: `${pathname}`,
    },
    {
      label: t("status.draft"),
      path: `${pathname}?filter=draft`,
    },
    {
      label: t("status.waitDeliver"),
      path: `${pathname}?filter=waitDeliver`,
    },
    {
      label: t("status.delivering"),
      path: `${pathname}?filter=delivering`,
    },
    {
      label: t("status.completed"),
      path: `${pathname}?filter=completed`,
    },
    {
      label: t("status.notCompleted"),
      path: `${pathname}?filter=notCompleted`,
    },
    {
      label: t("status.cancelled"),
      path: `${pathname}?filter=cancelled`,
    },
  ];

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        issue_date,
        delivery_date,
        created_by,
        employee_list,
        vehicle,
        in_date,
        out_date,
        goods_deliver_list,
      } = filterModel;

      const formatFilterModel = {
        ...request.filterModel,
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        in_date: in_date && filterDateWithUnix(in_date),
        out_date: out_date && filterDateWithUnix(out_date),
        vehicle: vehicle && {
          filterType: "object",
          filter: {
            license_plate: {
              ...vehicle,
              mode: "insensitive",
            },
          },
        },
        goods_deliver_list: goods_deliver_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...goods_deliver_list, mode: "insensitive" },
          },
        },
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
      };

      dispatch(
        getAllDeliveryTrips(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onFilterChanged = useCallback(
    (params) => {
      const instance = params.api.getFilterInstance("render_status");
      switch (isFilter) {
        case "draft":
          instance.setModel({ filterType: "set", values: ["draft"] });
          break;
        case "waitDeliver":
          instance.setModel({ filterType: "set", values: ["waitDeliver"] });
          break;
        case "delivering":
          instance.setModel({ values: ["delivering"] });
          break;
        case "completed":
          instance.setModel({ filterType: "set", values: ["completed"] });
          break;
        case "notCompleted":
          instance.setModel({ filterType: "set", values: ["notCompleted"] });
          break;
        case "cancelled":
          instance.setModel({ filterType: "set", values: ["cancelled"] });
          break;
        default:
          instance.setModel({
            values: [
              "draft",
              "waitDeliver",
              "delivering",
              "completed",
              "notCompleted",
            ],
          });
          break;
      }
      params.api.onFilterChanged();
    },
    [isFilter]
  );

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    if (gridRef.current.api) {
      onFilterChanged(gridRef.current);
    }
    return () => {
      dispatch(deliveryTripActions.resetAllDeliveryTrips());
    };
  }, [gridRef?.current?.api, dispatch, onFilterChanged]);

  const expandedAllHandler = useCallback(() => {
    gridRef.current.api.forEachNode((node) => {
      node.setExpanded(true);
    });
    setIsOpen(true);
  }, []);

  const collapseAllHandler = useCallback(() => {
    gridRef.current.api.forEachNode((node) => {
      node.setExpanded(false);
    });
    setIsOpen(false);
  }, []);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("logistic.deliveryTrip.index")}</Typography>
        {createPermission && (
          <CustomizedButton
            title={"สร้างรอบจัดส่ง"}
            variant="contained"
            onClick={() => navigate(`${pathname}/add`)}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomizedTab table tabs={tabs} currentTab={currentTab} divider />
        <Box>
          {!isOpen && (
            <CustomizedButton
              title="เปิดทั้งหมด"
              startIcon={<KeyboardArrowRightIcon />}
              onClick={expandedAllHandler}
            />
          )}
          {isOpen && (
            <CustomizedButton
              title="ปิดทั้งหมด"
              startIcon={<KeyboardArrowDownIcon />}
              onClick={collapseAllHandler}
            />
          )}
        </Box>
      </Box>
      <DeliveryTripTable gridRef={gridRef} onGridReady={onGridReady} />
    </>
  );
};

export default DeliveryTrip;
