import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import HistoryIcon from "@mui/icons-material/History";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdjustmentDetail from "./Detail";
import { dateToUnix } from "../../../utils/date-converter";
import {
  createGoodsAdjustmentWithApprove,
  getGoodsAdjustmentById,
} from "../../../features/Inventory/GoodsAdjustment/goodsAdjustment-actions";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import RightDrawer from "../../../components/UI/RightDrawer";
import GlobalService from "../../../services/Global";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { validation } from "./validation";
import { goodsAdjustmentActions } from "../../../features/Inventory/GoodsAdjustment/goodsAdjustment-slice";
import GoodsAdjustmentTable from "../../../components/Table/DocumentTable/GoodsAdjustmentTable";
import { useAuth } from "../../../hooks/use-auth";
import InventoryService from "../../../services/Inventory";
import moment from "moment";
import { useSnackbar } from "notistack";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import ControlledDatePicker from "../../../components/Custom/ControlledDatePicker";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { formatGAPayload } from "../../../utils/dataTransformer";
import CustomizedAvatar from "../../../components/Custom/CustomizedAvatar";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";

const AdjustmentContainer = ({ isCreate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { id } = useParams();
  const { item, isLoading } = useSelector((state) => state.goodsAdjustment);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createDateIsOpen, setCreateDateIsOpen] = useState(false);
  const [adjustmentDateIsOpen, setAdjustmentDateIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { location } = useLocation();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: item,
    resolver: yupResolver(validation),
  });

  const {
    control: barcodeControl,
    handleSubmit: handleBarcodeSubmit,
    resetField: resetFieldBarcode,
  } = useForm({
    defaultValues: {
      barcode: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goods_adjustment_list",
    keyName: "genId",
  });

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.adjustment.index"),
      to: "/inventory/adjustment",
    },
    {
      name: id ?? t("inventory.adjustment.add"),
    },
  ];

  const generateDocumentId = useCallback(async () => {
    setValue("document_id", "");
    const newDocumentId = await GlobalService.getRunningDocumentId(
      "goods_adjustment"
    );
    return setValue("document_id", newDocumentId);
  }, [setValue]);

  useEffect(() => {
    dispatch(getAllLocation());
    if (id) {
      dispatch(getGoodsAdjustmentById({ uniqueInput: { document_id: id } }));
    } else {
      generateDocumentId();
    }
    return () => dispatch(goodsAdjustmentActions.resetItem());
  }, [dispatch, generateDocumentId, id]);

  useEffect(() => {
    //replace default values of form hook with new value from redux
    if (id) {
      Object.entries(item).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      setValue("created_date", moment());
      setValue("document_date", moment().endOf("day"));
    }
  }, [id, item, setValue]);

  const watchWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const onSubmit = async (data) => {
    const serializedData = await formatGAPayload(data, false);
    dispatch(
      createGoodsAdjustmentWithApprove(
        serializedData,
        enqueueSnackbar,
        navigate,
        user,
        location
      )
    );
  };

  useEffect(() => {
    if (errors && errors?.goods_adjustment_list?.message) {
      enqueueSnackbar(errors?.goods_adjustment_list?.message, {
        variant: "error",
      });
    } else if (errors && Array.isArray(errors?.goods_adjustment_list)) {
      if (
        errors?.goods_adjustment_list.some(
          (goods) =>
            goods.stock_entry_list.trace_entry_list.message ===
            "กรุณาเพิ่ม SN ในรายการสินค้า"
        )
      ) {
        enqueueSnackbar("กรุณาเพิ่ม SN ในรายการสินค้า", {
          variant: "error",
        });
      }
    }
  }, [enqueueSnackbar, errors]);

  const onBarcodeSubmitHandler = async (data) => {
    if (data.trim().length > 0) {
      try {
        await InventoryService.scanBarcode({
          uniqueInput: {
            batch_number: data,
          },
        });
      } catch (error) {
        return enqueueSnackbar("ไม่พบ Barcode นี้อยู่ในระบบ", {
          variant: "error",
        });
      }
      const serialNumber = data
        .split("#", 4)
        .filter((_, index) => index !== 0)
        .join("#");
      const { results } =
        await InventoryService.getItemCurrentStockSerialNumber({
          startRow: 0,
          endRow: 999,
          filterModel: {
            batch_number: {
              filter: data,
              filterType: "text",
              type: "equals",
            },
          },
        });
      const checkItemIsActive = results.some((te) => !te.item_is_active);
      if (checkItemIsActive)
        return enqueueSnackbar(
          "ไม่สามารถสแกนได้ เนื่องจากสินค้านี้มีสถานะหยุดการใช้งานอยู่",
          {
            variant: "error",
          }
        );
      const filterTraceEntry = results.filter(
        (result) => result.warehouse_document_id === watchWarehouse
      );
      const lastIndexTraceEntry = filterTraceEntry[filterTraceEntry.length - 1];
      if (lastIndexTraceEntry) {
        const goodsAdjustmentList = getValues("goods_adjustment_list");
        const foundMatchItem = goodsAdjustmentList.findIndex(
          (item) =>
            item.stock_entry_list.item.document_id ===
            lastIndexTraceEntry.item_document_id
        );
        const currItemList = getValues(`goods_adjustment_list`);
        //1MT10.0-L-10M#1#GR2211020010#00001#10
        //1MT10.0-L-10M#1#GR2211070002#00002#10
        const formatTraceEntry = {
          ...lastIndexTraceEntry,
          previous_quantity: lastIndexTraceEntry.current_quantity,
          uom: {
            name: lastIndexTraceEntry.base_uom_name,
            document_id: lastIndexTraceEntry.base_uom_document_id,
          },
          item: {
            name: lastIndexTraceEntry.item_name,
            document_id: lastIndexTraceEntry.item_document_id,
            sku: lastIndexTraceEntry.item_sku,
          },
          warehouse: {
            document_id: lastIndexTraceEntry.warehouse_document_id,
            name: lastIndexTraceEntry.warehouse_thai_name,
          },
          source_bin_location: {
            document_id: lastIndexTraceEntry.source_bin_location_document_id,
          },
          source_bin_location_document_id:
            lastIndexTraceEntry.source_bin_location_document_id || "",
          batch_number: data,
          is_scanned: true,
          posted_quantity: 0,
          created_by: user,
          scanned_by: user,
          scanned_date: new Date(),
          posted_date: moment().unix(),
          location_list: filterTraceEntry.map((result) => {
            return {
              id: result.source_bin_location_document_id,
              label: `${result.source_bin_location_document_id} (${result.current_quantity} ${lastIndexTraceEntry.base_uom_name})`,
              value: result.source_bin_location_document_id,
              current_quantity: result.current_quantity,
            };
          }),
        };

        const formatItemList = [
          ...currItemList,
          {
            initial_quantity: 0,
            posted_quantity: 0,
            posted_value: 0,
            stock_entry_list: {
              source_warehouse_document_id:
                formatTraceEntry.warehouse.document_id,
              posted_quantity: 0,
              posted_value: 0,
              posted_date: dateToUnix(new Date()),
              uom: formatTraceEntry.uom,
              item: formatTraceEntry.item,
              created_by: user,
              trace_entry_list: [formatTraceEntry],
            },
          },
        ];
        if (
          results.some(
            (result) => result.warehouse_document_id === watchWarehouse
          )
        ) {
          if (foundMatchItem >= 0) {
            const currTraceEntry = getValues(
              `goods_adjustment_list[${foundMatchItem}].stock_entry_list.trace_entry_list`
            );
            if (currTraceEntry.some((teList) => teList.batch_number === data)) {
              enqueueSnackbar(
                "QR/Barcode สินค้านี้ถูกสแกนและบันทึกลงรายการแล้ว",
                {
                  variant: "error",
                }
              );
            } else {
              setValue(
                `goods_adjustment_list[${foundMatchItem}].stock_entry_list.trace_entry_list`,
                [...currTraceEntry, formatTraceEntry]
              );
              enqueueSnackbar(`สแกน SN: ${serialNumber} สำเร็จ`, {
                variant: "success",
              });
            }
          } else {
            setValue(`goods_adjustment_list`, formatItemList);
            enqueueSnackbar(`สแกน SN: ${serialNumber} สำเร็จ`, {
              variant: "success",
            });
          }
        } else {
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      {isLoading.item ? null : (
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
        >
          <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
              <Typography variant="h5">
                {t("inventory.adjustment.goodsAdjustmentList")}
              </Typography>
              <CustomizedStatus status={item.status} />
            </Box>
            <CustomizedTooltip title="ดูการเคลื่อนไหว">
              <IconButton onClick={() => setOpenDrawer(true)}>
                <HistoryIcon fontSize="small" color=" rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </CustomizedTooltip>
            <RightDrawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              title={t("inventory.activity")}
              documentId={id}
              documentType="goods_adjustment"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: "2rem",
            }}
          >
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
              <Typography>{t("inventory.adjustment.adjustmentBy")}</Typography>
              <CustomizedAvatar
                avatars={
                  item.created_by ? [{ ...item.created_by }] : [{ ...user }]
                }
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center", gap: 1 }}>
              <ControlledDatePicker
                name="created_date"
                control={control}
                error={errors.created_date}
                isOpen={createDateIsOpen}
                onClose={() => setCreateDateIsOpen(false)}
                onOpen={() => setCreateDateIsOpen(true)}
                label={t("inventory.stockEntry.createdDate")}
                sx={{ width: { sx: "auto", md: 155 } }}
                disabled
              />
              <ControlledDatePicker
                name="document_date"
                control={control}
                error={errors.document_date}
                isOpen={adjustmentDateIsOpen}
                onClose={() => setAdjustmentDateIsOpen(false)}
                onOpen={() => setAdjustmentDateIsOpen(true)}
                label={t("inventory.adjustment.adjustmentDate")}
                disabled={Boolean(id)}
                sx={{ width: { sx: "auto", md: 155 } }}
                required
              />
            </Box>
          </Box>
          <AdjustmentDetail
            isCreate={isCreate}
            control={control}
            resetFieldBarcode={resetFieldBarcode}
            reset={reset}
            getValues={getValues}
            errors={errors}
            disabled={id}
            generateDocumentId={generateDocumentId}
          />
          <CustomizedBox>
            <Typography sx={{ fontWeight: 700, mb: 3 }}>
              สแกน Barcode
            </Typography>
            {!id ? (
              <Grid container mb={2}>
                <Grid item xs={4}>
                  <Controller
                    control={barcodeControl}
                    name="barcode"
                    render={({ field }) => (
                      <CustomizedTextField
                        {...field}
                        label={t("inventory.scanBarcode")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey === false) {
                            const data = e.target.value;
                            handleBarcodeSubmit(onBarcodeSubmitHandler(data));
                            resetFieldBarcode("barcode");
                          }
                        }}
                        disabled={!watchWarehouse}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            <GoodsAdjustmentTable
              control={control}
              fields={fields}
              append={append}
              remove={remove}
              disabled={id}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
            />
          </CustomizedBox>
          {isCreate ? (
            <Box display="flex" gap={1}>
              <CustomizedButton
                type="submit"
                variant="contained"
                title="ปรับปรุง"
                disabled={isLoading.item}
              />
            </Box>
          ) : null}
        </form>
      )}
    </>
  );
};

export default AdjustmentContainer;
