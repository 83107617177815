import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomizedMenuOption from "../../../Custom/CustomizedMenuOption";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import RightDrawer from "../../../UI/RightDrawer";
import CustomizedChips from "../../../Custom/CustomizedChips";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomizedStatus from "../../../Custom/CustomizedStatus";
import { mapStatusToThai } from "../../../../utils/date-converter";
import ChangeStatusConfirmation from "../../../UI/Confirmation/ChangeStatusConfirmation";
import CancelConfirmation from "../../../UI/Confirmation/CancelConfirmation";
import { usePermission } from "../../../../hooks/use-auth";

const WorkOrderHeaderForm = ({
  control,
  errors,
  disabled,
  editButtonClickHandler,
  changeStatusHandler,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { workOrder } = useSelector((state) => state.workOrder);

  const [openDrawer, setOpenDrawer] = useState(false);

  const { cancelPermission, editPermission } = usePermission();

  const selectModifyOptions = [
    {
      value: "แก้ไข",
      disabled: !editPermission,
    },
  ];

  const selectStatusOptions = [
    {
      value: "กำลังผลิต",
      disabled: workOrder.status === "in_progress" || !editPermission,
    },
    { value: "เสร็จสิ้น", disabled: !editPermission },
    {
      value: "ยกเลิก",
      disabled: !cancelPermission,
    },
  ];

  const [modal, setModal] = useState({
    value: false,
    currStatus: "",
    nextStatus: "",
  });
  const [cancelConfirmation, setCancelConfirmation] = useState(false);

  const watchReferenceDocumentId = useWatch({
    control,
    name: "manufacture_order_document_id",
  });
  const watchStatus = useWatch({ control, name: "status" });

  const disabledButton =
    watchStatus === "finished" || watchStatus === "canceled";

  const referenceNavigation = (currentDocument, currentDocumentId) => {
    switch (currentDocument) {
      case "manufacture-order":
        window.open(
          `/manufacture/order/${encodeURIComponent(currentDocumentId)}`,
          "_blank"
        );
        break;
      default:
        return "";
    }
  };

  const openConfirmationHandler = (nextStatus) => {
    setModal({ value: true, currStatus: workOrder.status, nextStatus });
  };

  const closeConfirmationHandler = () => {
    setModal((prev) => ({
      ...prev,
      value: false,
    }));
    setTimeout(() => {
      setModal((prev) => ({
        ...prev,
        currStatus: "",
        nextStatus: "",
      }));
    }, 700);
  };

  const confirmationAction = () => {
    changeStatusHandler(modal.nextStatus);
    closeConfirmationHandler();
  };

  const closeCancelConfirmationHandler = () => {
    setCancelConfirmation(false);
  };

  const cancelConfirmationAction = () => {
    // TODO: Cancel API
    closeCancelConfirmationHandler();
    changeStatusHandler("canceled");
  };

  const DocumentNameSection = ({ control }) => {
    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography variant="h5">
          {id ? workOrder.name : `สร้าง${t("manufacture.workOrder.index")}`}
        </Typography>
        <CustomizedStatus status={watchStatus} />
      </Box>
    );
  };

  return (
    <>
      <Grid container my={3} alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6.5} xl={5} mb={3}>
          <DocumentNameSection control={control} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5.5} xl={7}>
          <Grid
            container
            spacing={1}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <Grid item xs={5.5} sm={5.5} md={3.5} lg={3.5} xl={2.5}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"ตัวเลือก"}
                options={selectModifyOptions}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "แก้ไข":
                      editButtonClickHandler();
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id || disabledButton}
              />
            </Grid>
            <Grid item xs={5.5} sm={5.5} md={3.5} lg={3.5} xl={2.5}>
              <CustomizedMenuOption
                fullWidth
                size="medium"
                label={"สถานะการผลิต"}
                variant="contained"
                options={selectStatusOptions}
                onSelect={(e) => {
                  switch (e.target.innerText) {
                    case "กำลังผลิต":
                      openConfirmationHandler("in_progress");
                      break;
                    case "เสร็จสิ้น":
                      openConfirmationHandler("finished");
                      break;
                    case "ยกเลิก":
                      setCancelConfirmation(true);
                      break;
                    default:
                      break;
                  }
                }}
                disabled={!id || disabledButton}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CustomizedTooltip title="ดูความเคลื่อนไหว" enterNextDelay={200}>
                <IconButton
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestoreOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Grid>
          </Grid>
        </Grid>
        <RightDrawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          title={t("inventory.activity")}
          documentId={id}
          documentType="work_order"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3} md={2} lg={1.5} xl={1} alignSelf="center">
            <Typography fontWeight="bold" sx={{ ml: 1 }}>
              {"อ้างอิงถึง"}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9} md={10} lg={10.5} xl={11}>
            {watchReferenceDocumentId ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  width: "65vw",
                }}
              >
                <CustomizedChips
                  onClick={() =>
                    referenceNavigation(
                      "manufacture-order",
                      watchReferenceDocumentId
                    )
                  }
                  value={watchReferenceDocumentId}
                  color="primary"
                />
              </Box>
            ) : (
              "-"
            )}
          </Grid>
        </Grid>
      </Grid>
      <ChangeStatusConfirmation
        documentName="ใบสั่งงาน"
        currStatus={mapStatusToThai(workOrder.status)}
        nextStatus={mapStatusToThai(modal.nextStatus)}
        openConfirmation={modal.value}
        closeConfirmationHandler={closeConfirmationHandler}
        confirmationAction={confirmationAction}
      />
      <CancelConfirmation
        openCancelConfirmation={cancelConfirmation}
        cancelConfirmationAction={cancelConfirmationAction}
        closeCancelConfirmationHandler={closeCancelConfirmationHandler}
      />
    </>
  );
};

export default WorkOrderHeaderForm;
