import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useAuth } from "../../../hooks/use-auth";
import { Controller, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ControlledTextField from "../../Controlled/ControlledTextField";
import ControlledSelect from "../../Controlled/ControlledSelect";
import CustomizedTextField from "../../Custom/CustomizedTextField";
import CustomizedDateTime from "../../Custom/CustomizedDateTime";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import { formatDateTime } from "../../../utils/date-converter";
import CloseIcon from "@mui/icons-material/Close";
import DeleteItemConfirmation from "../../UI/Confirmation/DeleteItemConfirmation";
import CustomizedMenuOption from "../../Custom/CustomizedMenuOption";
import { useDispatch, useSelector } from "react-redux";
import { getAllWorkCenter } from "../../../features/Setting/Manufacture/manufacture-actions";
import { manufactureActions } from "../../../features/Setting/Manufacture/manufacture-slice";
import { changeStatusWorkOrder } from "../../../features/Manufacture/WorkOrder/work-order-actions";
import ChangeStatusConfirmation from "../../UI/Confirmation/ChangeStatusConfirmation";
import { mapStatusToThai } from "../../../utils/date-converter";
// import { useSnackbar } from "notistack";
import ControlledServerSideCreatable from "../../Controlled/ControlledSSCreatable";
import CancelConfirmation from "../../UI/Confirmation/CancelConfirmation";
import { userPermission } from "../../../utils/userInfo";
import CustomizedNumberTextField from "../../Custom/CustomizedNumberTextField";
import CustomizedComboBox from "../../Custom/CustomizedComboBox";

const exceptThisSymbols = ["e", "E", "+", "-", "."];

const mapOption = (option) => {
  switch (option) {
    case "in_progress":
      return "กำลังผลิต";
    default:
      break;
  }
};

const formatExportedWorkOrderStatus = (status) => {
  switch (status) {
    case "day":
      return "วัน";
    case "hour":
      return "ชั่วโมง";
    case "minute":
      return "นาที";
    default:
      return "วัน";
  }
};

const timeUnit = [
  {
    value: "minute",
    label: "นาที",
  },
  {
    value: "hour",
    label: "ชั่วโมง",
  },
  {
    value: "day",
    label: "วัน",
  },
];

const WorkInfoTable = ({
  fields,
  remove,
  control,
  errors,
  setValue,
  update,
  disabled,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  const [headers, setHeaders] = useState([]);
  const status = useWatch({
    control,
    name: "render_status",
  });
  const { enqueueSnackbar } = useSnackbar();
  const { user, permissions } = useAuth();

  const [modal, setModal] = useState({
    value: false,
    currStatus: "",
    nextStatus: "",
    id: null,
    index: null,
    row: null,
  });
  const [deletedIndex, setDeletedIndex] = useState(null);

  const [openDeleteItemConfirmation, setOpenDeleteItemConfirmation] =
    useState(false);

  const disabledPermission = userPermission(
    permissions,
    "manufacture",
    "work-order",
    "edit"
  );

  const disabledStatusButton = ["finished", "canceled"];
  const selectStatusOptions = [
    {
      value: "กำลังผลิต",
      disabled: disabledPermission,
    },
    {
      value: "เสร็จสิ้น",
      disabled: disabledPermission,
    },
    {
      value: "ยกเลิก",
      disabled: disabledPermission,
    },
  ];

  useEffect(() => {
    dispatch(getAllWorkCenter());
    if (!disabled)
      setHeaders([
        { label: "รายการ", width: 40 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.machine"), width: 150 },
        { label: t("manufacture.workOrder.start_date"), width: 175 },
        { label: t("manufacture.workOrder.duration"), width: 120 },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        { label: t("manufacture.workOrder.description"), width: 150 },
        { label: "", width: 20 },
      ]);
    else
      setHeaders([
        { label: "รายการ", width: 40 },
        { label: t("manufacture.workOrder.name"), width: 200 },
        { label: t("manufacture.workOrder.plant"), width: 150 },
        { label: t("manufacture.workOrder.machine"), width: 150 },
        { label: t("manufacture.workOrder.start_date"), width: 175 },
        { label: t("manufacture.workOrder.duration"), width: 120 },
        { label: t("manufacture.workOrder.unitDuration"), width: 130 },
        { label: t("manufacture.workOrder.description"), width: 150 },
        { label: t("manufacture.bom.status"), width: 120 },
        { label: "", width: 150 },
      ]);
    return () => {
      dispatch(manufactureActions.resetAllWorkCenter());
    };
  }, [disabled, t, dispatch, id, status]);

  const { allWorkCenter } = useSelector((state) => state.workCenter);

  const openDeleteItemConfirmationHandler = (index) => {
    setOpenDeleteItemConfirmation(true);
    setDeletedIndex(index);
  };

  const closeDeleteItemConfirmationHandler = () => {
    setOpenDeleteItemConfirmation(false);
    setDeletedIndex(null);
  };

  const deleteItemConfirmationAction = () => {
    remove(deletedIndex);
    closeDeleteItemConfirmationHandler();
  };

  const cancelConfirmationAction = () => {
    // TODO: Cancel API
    changeStatusWoHandler();
    closeConfirmationHandler();
  };

  const openConfirmationHandler = (
    id,
    currStatus,
    nextStatus,
    index,
    row,
    inCancel
  ) => {
    setModal({
      value: true,
      isCancel: inCancel ?? false,
      currStatus: currStatus,
      nextStatus,
      id,
      index,
      row,
    });
  };

  const closeConfirmationHandler = () => {
    setModal((prev) => ({
      ...prev,
      value: false,
      isCancel: false,
    }));
    setTimeout(() => {
      setModal((prev) => ({
        ...prev,
        currStatus: "",
        nextStatus: "",
        id: null,
        row: null,
      }));
    }, 700);
  };

  const confirmationAction = () => {
    changeStatusWoHandler();
    closeConfirmationHandler();
  };

  const changeStatusWoHandler = () => {
    const input = {
      uniqueInput: { id: parseInt(modal.id) },
      statusInput: modal.nextStatus,
      prevStatus: modal.prevStatus,
    };
    try {
      dispatch(changeStatusWorkOrder(input, enqueueSnackbar, user));
      handleUpdateStatus(modal.index, modal.row, modal.nextStatus);
    } catch (error) {
      enqueueSnackbar("เปลี่ยนสถานะไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

  const handleUpdateStatus = (index, row, status) => {
    update(index, { ...row, status: status });
  };

  const RenderMachineFields = ({ control, errors, setValue, rowIndex }) => {
    const watchPlant = useWatch({
      control,
      name: `work_order_list[${rowIndex}].plant`,
    });

    return (
      <Controller
        name={`work_order_list[${rowIndex}].machine`}
        control={control}
        render={({ field }) => (
          <CustomizedComboBox
            {...field}
            onChange={(e, v) => {
              if (v) {
                field.onChange(v);
              } else field.onChange("");
            }}
            fullWidth
            error={Boolean(
              errors.work_order_list &&
                errors.work_order_list[rowIndex] &&
                errors.work_order_list[rowIndex].machine
            )}
            helperText={
              errors.work_order_list &&
              errors.work_order_list[rowIndex] &&
              errors.work_order_list[rowIndex].machine &&
              errors.work_order_list[rowIndex].machine.message
            }
            options={
              allWorkCenter &&
              allWorkCenter.length > 0 &&
              allWorkCenter
                .filter((wc) => {
                  if (watchPlant !== "") return wc.plant === watchPlant;
                  else return wc;
                })
                .map((wc) => wc.name)
            }
            disabled={disabled}
          />
        )}
      />
    );
  };

  // <ControlledSelect
  //     name={`work_order_list[${rowIndex}].machine`}
  //     control={control}
  // options={
  //   allWorkCenter &&
  //   allWorkCenter.length > 0 &&
  //   allWorkCenter
  //     .filter((wc) => {
  //       if (watchPlant !== "") return wc.plant === watchPlant;
  //       else return wc;
  //     })
  //     .map((wc) => wc.name)
  // }
  // error={Boolean(
  //   errors.work_order_list &&
  //     errors.work_order_list[rowIndex] &&
  //     errors.work_order_list[rowIndex].machine
  // )}
  // helperText={
  //   errors.work_order_list &&
  //   errors.work_order_list[rowIndex] &&
  //   errors.work_order_list[rowIndex].machine &&
  //   errors.work_order_list[rowIndex].machine.message
  // }
  //     onChange={(e) =>
  //       setValue(
  //         `work_order_list[${rowIndex}].plant`,
  //         allWorkCenter.find((wc) => wc.name === e.target.value).plant
  //       )
  //     }
  //   />

  return (
    <Box sx={{ my: 3 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: (theme) => theme.palette.primary.light,
            }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align="center" key={index} width={header.width}>
                  <Box minWidth={header.width}>
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={700}
                    >
                      {header.label}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map((row, index) => (
              <TableRow key={row.uid}>
                <TableCell align="center">
                  <Typography fontSize={14}>{index + 1}</Typography>
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography
                      fontSize={14}
                      onClick={() =>
                        window.open(
                          `/manufacture/work-order/${row.id}`,
                          "_blank"
                        )
                      }
                      paragraph
                      component="span"
                      color="primary"
                      sx={{ cursor: "pointer" }}
                    >
                      {row.name}
                    </Typography>
                  ) : (
                    <ControlledServerSideCreatable
                      dataName="work_order_list_name"
                      name={`work_order_list[${index}].name`}
                      control={control}
                      error={Boolean(
                        errors.work_order_list &&
                          errors.work_order_list[index] &&
                          errors.work_order_list[index].name
                      )}
                      helperText={
                        errors.work_order_list &&
                        errors.work_order_list[index] &&
                        errors.work_order_list[index].name &&
                        errors.work_order_list[index].name.message
                      }
                      documentType="work_order"
                      defaultOptions={[]}
                      setValue={setValue}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>{row.plant}</Typography>
                  ) : (
                    <ControlledSelect
                      name={`work_order_list[${index}].plant`}
                      control={control}
                      options={[
                        ...new Set(allWorkCenter?.map((wc) => wc.plant)),
                      ]}
                      error={Boolean(
                        errors.work_order_list &&
                          errors.work_order_list[index] &&
                          errors.work_order_list[index].plant
                      )}
                      helperText={
                        errors.work_order_list &&
                        errors.work_order_list[index] &&
                        errors.work_order_list[index].plant &&
                        errors.work_order_list[index].plant.message
                      }
                      onChange={() =>
                        setValue(`work_order_list[${index}].machine`, "")
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>{row.machine}</Typography>
                  ) : (
                    <RenderMachineFields
                      rowIndex={index}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>
                      {formatDateTime(row.start_date_time)}
                    </Typography>
                  ) : (
                    <Controller
                      name={`work_order_list[${index}].start_date_time`}
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomizedDateTime
                          inputFormat="dd/MM/yyyy HH:mm"
                          onChange={onChange}
                          value={value}
                          ref={ref}
                          renderInput={(params) => (
                            <CustomizedTextField
                              {...params}
                              ref={params.inputRef}
                              error={Boolean(
                                errors.work_order_list &&
                                  errors.work_order_list[index] &&
                                  errors.work_order_list[index].start_date_time
                              )}
                              helperText={
                                errors.work_order_list &&
                                errors.work_order_list[index] &&
                                errors.work_order_list[index].start_date_time &&
                                errors.work_order_list[index].start_date_time
                                  .message
                              }
                            />
                          )}
                          disabled={disabled}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>
                      {parseInt(row.duration).toLocaleString()}
                    </Typography>
                  ) : (
                    <CustomizedNumberTextField
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      type="text"
                      name={`work_order_list[${index}].duration`}
                      control={control}
                      error={Boolean(
                        errors.work_order_list &&
                          errors.work_order_list[index] &&
                          errors.work_order_list[index].duration
                      )}
                      helperText={
                        errors.work_order_list &&
                        errors.work_order_list[index] &&
                        errors.work_order_list[index].duration &&
                        errors.work_order_list[index].duration.message
                      }
                      onChange={(e, field) =>
                        field.onChange(e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>
                      {formatExportedWorkOrderStatus(row.duration_unit)}
                    </Typography>
                  ) : (
                    <ControlledSelect
                      options={timeUnit}
                      name={`work_order_list[${index}].duration_unit`}
                      control={control}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          style: { textAlign: "center" },
                        },
                      }}
                      error={Boolean(
                        errors.work_order_list &&
                          errors.work_order_list[index] &&
                          errors.work_order_list[index].duration_unit
                      )}
                      helperText={
                        errors.work_order_list &&
                        errors.work_order_list[index] &&
                        errors.work_order_list[index].duration_unit &&
                        errors.work_order_list[index].duration_unit.message
                      }
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {disabled ? (
                    <Typography fontSize={14}>{row.description}</Typography>
                  ) : (
                    <ControlledTextField
                      name={`work_order_list[${index}].description`}
                      control={control}
                    />
                  )}
                </TableCell>
                {disabled && (
                  <TableCell align="center">
                    <CustomizedStatus status={row.status} />
                  </TableCell>
                )}
                {disabled && (
                  <TableCell align="center">
                    <CustomizedMenuOption
                      fullWidth
                      size="medium"
                      label={"สถานะการผลิต"}
                      variant="outlined"
                      color="secondary"
                      options={selectStatusOptions.map(
                        ({ value, disabled }) => ({
                          value: value,
                          disabled:
                            disabled || value === mapOption(row.status)
                              ? true
                              : false,
                        })
                      )}
                      onSelect={(e) => {
                        switch (e.target.innerText) {
                          case "กำลังผลิต":
                            openConfirmationHandler(
                              row.id,
                              row.status,
                              "in_progress",
                              index,
                              row
                            );
                            break;
                          case "เสร็จสิ้น":
                            openConfirmationHandler(
                              row.id,
                              row.status,
                              "finished",
                              index,
                              row
                            );
                            break;
                          case "ยกเลิก":
                            openConfirmationHandler(
                              row.id,
                              row.status,
                              "canceled",
                              index,
                              row,
                              true
                            );
                            break;
                          default:
                            break;
                        }
                      }}
                      disabled={disabledStatusButton.includes(row.status)}
                    />
                  </TableCell>
                )}
                {!disabled && (
                  <TableCell align="center">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ ml: 1, color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => {
                        if (id) openDeleteItemConfirmationHandler(index);
                        else remove(index);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ChangeStatusConfirmation
        documentName="ใบสั่งงาน"
        currStatus={mapStatusToThai(modal.currStatus)}
        nextStatus={mapStatusToThai(modal.nextStatus)}
        openConfirmation={modal.value && !modal.isCancel}
        closeConfirmationHandler={closeConfirmationHandler}
        confirmationAction={confirmationAction}
      />
      <CancelConfirmation
        openCancelConfirmation={modal.value && modal.isCancel}
        cancelConfirmationAction={cancelConfirmationAction}
        closeCancelConfirmationHandler={closeConfirmationHandler}
      />
      <DeleteItemConfirmation
        title={"ต้องการลบ การทำงาน ใช่หรือไม่"}
        openDeleteItemConfirmation={openDeleteItemConfirmation}
        deleteItemConfirmationAction={deleteItemConfirmationAction}
        closeDeleteItemConfirmationHandler={closeDeleteItemConfirmationHandler}
      />
    </Box>
  );
};

export default WorkInfoTable;
