export const initialState = {
  isLoading: {
    allItems: false,
    item: false,
  },
  allItems: [],
  count: 0,
  categoryItem: [],
  uomGroup: [],
  property: [],
  item: {
    document_id: "",
    name: "",
    sku: "",
    description: "",
    type: "สินค้า",
    barcode: "",
    tag_list: [],
    is_purchasable: true,
    is_salable: true,
    is_stockable: true,
    is_manufactuable: true,
    is_active: true,
    remark_status: "",
    weight: 0,
    length: 0,
    height: 0,
    width: 0,
    thickness: 0,
    volume: 0,
    weight_uom: { name: "กิโลกรัม" },
    height_uom: { name: "เซนติเมตร" },
    length_uom: { name: "เซนติเมตร" },
    width_uom: { name: "เซนติเมตร" },
    thickness_uom: { name: "เซนติเมตร" },
    package_weight: 0,
    package_length: 0,
    package_height: 0,
    package_width: 0,
    package_weight_uom: { name: "กิโลกรัม" },
    package_height_uom: { name: "เซนติเมตร" },
    package_length_uom: { name: "เซนติเมตร" },
    package_width_uom: { name: "เซนติเมตร" },
    volume_uom: "",
    base_uom: "",
    purchase_uom: "",
    sales_uom: "",
    deliver_uom: "",
    purchase_minimum_qty: 0,
    purchase_standard_price: 0,
    purchase_vat_type: "ไม่มี",
    sales_standard_price: 0,
    sales_maximum_discount: 0,
    sales_vat_type: "ไม่มี",
    base_uom_document_id: "",
    sales_uom_document_id: "",
    purchase_uom_document_id: "",
    deliver_uom_document_id: "",
    manufacture_type: "",
    main_bom: null,
    main_bom_name: "",
    manufacture_minimum_qty: "",
    mrp_default_method: "no_setting",
    uom_group: {
      id: "",
      base_uom: "",
      convertable_uom_list: [],
      name: "",
      uom_conversion_list: [],
    },
    uom_conversion_list: [],
    item_group_sub_level_1: { id: "", name: "", sub_level_2_list: [] },
    attribute_list: [],
    vendor_item_id: "",
  },
  error: null,
};
