import { Box, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedMenuBox from "../../../components/Custom/CustomizedMenuBox";

const SalesReport = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const breadcrumbs = [
    {
      name: t("sales.index"),
      to: "/sales",
    },
    {
      name: t("sales.report.index"),
    },
  ];

  const menuList = [
    { title: t("sales.quotation.index"), navigateTo: "quotation" },
    // { title: t("sales.order.index") + " (เก่า)", navigateTo: "order-old" },
    { title: t("sales.order.index"), navigateTo: "order" },
    // { title: t("sales.depositInvoice.index"), navigateTo: "deposit-invoice" },
    // { title: t("sales.invoice.index"), navigateTo: "invoice" },
    // { title: t("sales.paymentReceipt.index"), navigateTo: "payment-receipt" },
    { title: t("sales.return.index"), navigateTo: "return" },
    // { title: t("sales.creditNote.index"), navigateTo: "credit-note" },
    {
      title: t("sales.report.salesByCustomer"),
      navigateTo: "sales-by-customer",
    },
    { title: t("sales.report.salesByItem"), navigateTo: "sales-by-item" },
  ];

  const renderMenuList = () =>
    menuList.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <CustomizedMenuBox
          title={menu.title}
          linkTo={`${pathname}/${menu.navigateTo}`}
          isGridItem
        />
      </Grid>
    ));

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 2 }}>
        <Grid container spacing={1}>
          {renderMenuList()}
        </Grid>
      </Box>
    </>
  );
};

export default SalesReport;
