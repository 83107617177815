export const initialState = {
  isLoading: {
    allItems: false,
    item: false,
  },
  allItems: [],
  count: 0,
  item: {
    document_id: "",
    source_warehouse_document_id: "",
    remark: "",
    created_date: null,
    document_date: null,
    goods_adjustment_list: [],
  },
  error: null,
};
