import {
  Grid,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CustomizedButton from "../Custom/CustomizedButton";
import { exportAsExcel } from "../../utils/exporter";
import { useTranslation } from "react-i18next";

const ImportErrorTable = ({
  sx,
  data,
  keyName,
  title,
  errorDescription,
  columnName,
  documentType,
}) => {
  const { t } = useTranslation();
  const mappedData = data.map((record) => {
    return {
      [columnName]: record,
    };
  });
  const fileName = `${documentType}_${columnName}_error`;

  return (
    <Box sx={sx}>
      <Box>
        <Typography variant="body">{"ไม่สามารถสร้างได้เนื่องจาก"}</Typography>
      </Box>
      <Box>
        <List
          sx={{
            listStyleType: "disc",
            listStylePosition: "inside",
          }}
        >
          <ListItem
            sx={{
              display: "list-item",
            }}
          >
            <Typography
              sx={{
                display: "inline",
              }}
            >
              {errorDescription}
            </Typography>
          </ListItem>
        </List>
        <Box>
          <Grid container sx={{ mt: 2 }} columnSpacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <TableContainer
                sx={{
                  border: "1px solid #D6CFCF",
                  borderRadius: "3px",
                  borderBottom: "none",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.light,
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">
                        <Box width="max-content">{title}</Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((record, index) => (
                      <TableRow key={`${keyName}-${index}`}>
                        <TableCell align="center">
                          <Box width="max-content">{record}</Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <CustomizedButton
                sx={{ mt: { xs: 2, md: 0 } }}
                title="ดาวน์โหลด"
                variant="contained"
                onClick={() =>
                  exportAsExcel(mappedData, fileName, "Error", t, documentType)
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ImportErrorTable;
