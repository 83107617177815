import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { NavbarContextProvider } from "./contexts/navbar-context";
import { AuthContextProvider } from "./contexts/auth-context";
import { ApprovalContextProvider } from "./contexts/approval-context";
import CustomizedSnackbar from "./components/Custom/CustomizedSnackbar";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import moment from "moment";
import "moment/locale/th";

moment.locale("th");

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <CustomizedSnackbar>
          <AuthContextProvider>
            <ApprovalContextProvider>
              <NavbarContextProvider>
                <App />
              </NavbarContextProvider>
            </ApprovalContextProvider>
          </AuthContextProvider>
        </CustomizedSnackbar>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
