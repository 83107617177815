import { Box } from "@mui/material";
import React from "react";
import AgGrid from "../../../Table/AgGrid";
import { dtColumndefs } from "../ColumnDefs/dtColumnDefs";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

const DeliveryTripFooter = ({
  gridRef,
  footerGridRef,
  control,
  errors,
  getValues,
  setValue,
  handleShowItemModal,
  openDeleteItemConfirmationHandler,
  viewOnly,
  deliveryPlanControl,
}) => {
  const { t } = useTranslation();
  const watchStatus = useWatch({
    control,
    name: "render_status",
  });
  const watchItemList = useWatch({
    control,
    name: "item_list",
  });

  const watchVehicle = useWatch({
    control: deliveryPlanControl,
    name: "vehicle",
  });

  const sumQty = watchItemList.reduce((prev, curr) => prev + curr.qty, 0);
  const sumVolume = watchItemList.reduce(
    (prev, curr) => prev + (curr.volume * curr.qty) / 1000000,
    0
  );
  const sumWeight = watchItemList.reduce(
    (prev, curr) => prev + curr.weight * curr.qty,
    0
  );

  return (
    <Box sx={{ height: 60 }}>
      <AgGrid
        ref={footerGridRef}
        columnDefs={dtColumndefs(
          t,
          watchStatus,
          control,
          errors,
          getValues,
          setValue,
          handleShowItemModal,
          openDeleteItemConfirmationHandler,
          viewOnly,
          true,
          watchVehicle
        )}
        rowData={[
          {
            qty: sumQty,
            volume: sumVolume,
            weight: sumWeight,
          },
        ]}
        suppressMenu
        disabledSidebar={true}
        disableFloatingFilter
        suppressContextMenu
        headerClass={["center"]}
        isClientSide
        disableResized
        headerHeight="0"
        alignedGrids={gridRef.current ? [gridRef.current] : undefined}
      />
    </Box>
  );
};

export default DeliveryTripFooter;
