import GlobalService from "../../../services/Global";
import InventoryService from "../../../services/Inventory";
import ManufactureService from "../../../services/Manufacture";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import {
  dateToUnix,
  unixToDateWithFormat,
  unixToDateWithFormatCalendar,
} from "../../../utils/date-converter";
import { manufactureOrderActions } from "./manufacture-order-slice";
import {
  manufactureOrdersExportFormatter,
  underqualityItemsExportFormatter,
} from "../../../utils/manufacturePayloadFormatter";
import { filterStatusValueFormatter } from "../../../utils/filterparams";

const formatManufactureOrderPayload = (data) => {
  const {
    id,
    production_qty,
    actual_production_qty,
    ingredient_list,
    created_date,
    issue_date,
    expect_date,
    expect_product_date,
    delivery_date,
    contact_document_id,
    creator_document_id,
    bom_name,
    customer,
    created_by,
    thickness_tolerance_negative,
    thickness_tolerance_positive,
    width_tolerance_negative,
    width_tolerance_positive,
    employee_list,
    template_remark_id,
    remark,
    stock_uom,
    work_order_list,
    customer_contact_name,
    customer_detail,
    goods_receive_qty,
    target_status,
    render_status,
    group,
    bom_detail,
    ...payload
  } = data;
  const unixIssueDate = dateToUnix(issue_date);
  const unixExpectDate = dateToUnix(expect_date);
  const unixExpectProductDate = dateToUnix(expect_product_date);
  const unixDeliveryDate = dateToUnix(delivery_date);

  const formatItemList = ingredient_list?.map(
    ({
      current_ordered_purchase_qty,
      current_ordered_manufacture_qty,
      current_committed_sales_qty,
      current_committed_manufacture_qty,
      current_stock_qty,
      current_available_qty,
      qty,
      ...otherItemList
    }) => ({ ...otherItemList, qty: parseFloat(qty) })
  );

  const formatBomDetail = {
    ...bom_detail,
    thickness_tolerance_negative: parseFloat(thickness_tolerance_negative),
    thickness_tolerance_positive: parseFloat(thickness_tolerance_positive),
    width_tolerance_negative: parseFloat(width_tolerance_negative),
    width_tolerance_positive: parseFloat(width_tolerance_positive),
  };

  const employee_document_id_list = employee_list?.map(
    (employee) => employee.document_id
  );

  const formatGroup = group?.map((grp) => grp.name);

  const formatPayload = {
    ...payload,
    bom_detail: formatBomDetail,
    employee_document_id_list,
    work_order_list: work_order_list?.map(
      (
        {
          manufacture_order_document_id,
          start_date_time,
          duration,
          uid,
          ...otherList
        },
        index
      ) => ({
        ...otherList,
        indexing: index + 1,
        start_date_time: dateToUnix(start_date_time),
        duration: parseInt(duration),
      })
    ),
    customer_contact_document_id: contact_document_id,
    production_qty: parseFloat(production_qty),
    actual_production_qty: parseFloat(actual_production_qty),
    issue_date: unixIssueDate,
    expect_date: unixExpectDate,
    expect_product_date: unixExpectProductDate,
    delivery_date: unixDeliveryDate,
    ingredient_list: formatItemList,
    uom: stock_uom,
    group: formatGroup,
    internal_remark_id: template_remark_id ? template_remark_id : null,
    internal_remark: remark,
  };
  return formatPayload;
};

const formatManufactureOrder = async (data) => {
  const {
    ingredient_list,
    customer_contact_document_id,
    bom_detail,
    uom,
    customer_detail,
    internal_remark_id,
    internal_remark,
    ...payload
  } = data;

  const allItemsDocumentId = ingredient_list?.map(
    (item) => item.item_document_id
  );

  const items = await InventoryService.getAllItems({
    findManyInput: {
      where: {
        document_id: {
          in: allItemsDocumentId,
        },
      },
    },
  });

  const formatItemList = [];

  ingredient_list.forEach((item) => {
    const foundItemIndex = items.findIndex(
      (realItem) => realItem.document_id === item.item_document_id
    );
    formatItemList.push({
      ...item,
      current_ordered_purchase_qty:
        items[foundItemIndex]?.current_ordered_purchase_qty,
      current_ordered_manufacture_qty:
        items[foundItemIndex]?.current_ordered_manufacture_qty,
      current_committed_sales_qty:
        items[foundItemIndex]?.current_committed_sales_qty,
      current_committed_manufacture_qty:
        items[foundItemIndex]?.current_committed_manufacture_qty,
      current_stock_qty: items[foundItemIndex]?.current_stock_qty,
      current_available_qty: items[foundItemIndex]?.current_available_qty,
    });
  });

  const formatCustomer = `${customer_detail.document_id} - ${
    customer_detail.contact_name_1
  }${
    customer_detail.contact_name_2 ? ` ${customer_detail.contact_name_2}` : ""
  }`;

  const {
    thickness_tolerance_negative,
    thickness_tolerance_positive,
    width_tolerance_negative,
    width_tolerance_positive,
    ...otherBomDetail
  } = bom_detail;

  const formatPayload = {
    ...payload,
    contact_document_id: customer_contact_document_id,
    ingredient_list: formatItemList,
    bom_name: otherBomDetail.name,
    bom_detail: otherBomDetail,
    thickness_tolerance_negative,
    thickness_tolerance_positive,
    width_tolerance_negative,
    width_tolerance_positive,
    template_remark_id: internal_remark_id,
    remark: internal_remark,
    stock_uom: uom,
    customer: formatCustomer,
  };

  return formatPayload;
};

export const getAllManufactureOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrders"));
    try {
      const allManufactureOrders =
        await ManufactureService.getAllManufactureOrders(input);
      params.successCallback(
        allManufactureOrders.results,
        allManufactureOrders.count
      );
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrders(
          allManufactureOrders.results
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrders",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllManufactureOrdersCalendarView =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrders"));
    try {
      const { results } = await ManufactureService.getAllManufactureOrders(
        input
      );
      const formatResult = results.map(
        ({
          document_id,
          expect_date,
          expect_product_date,
          item_document_id,
          item_name,
          type,
          production_qty,
          uom,
          group,
          employee_list,
          render_status,
        }) => {
          const formatType = type ? type : null;
          const formatProductionQty = production_qty ? production_qty : 0;
          const formatUom = uom ? uom : null;
          const formatGroup =
            group && group.length > 0
              ? group.map((value) => value.name).join(" , ")
              : null;
          const formatEmployeeList =
            employee_list && employee_list.length > 0
              ? employee_list
                  .map(
                    (employee) => `${employee.first_name} ${employee.last_name}`
                  )
                  .join(" , ")
              : null;
          const formatStatus = filterStatusValueFormatter(render_status);
          const formatTitle = [
            item_document_id,
            item_name,
            formatType,
            formatProductionQty,
            formatUom,
            formatGroup,
            formatEmployeeList,
            formatStatus,
          ]
            .filter((title) => title !== null)
            .join(" - ");
          return {
            title: formatTitle,
            start: unixToDateWithFormatCalendar(expect_date),
            end: unixToDateWithFormatCalendar(expect_product_date),
            extendedProps: {
              id: document_id,
              status: render_status,
            },
          };
        }
      );
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrdersCalendar(formatResult)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrders",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getManufactureOrderById =
  (document_id, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const manufacture = await ManufactureService.getManufactureOrderById(
        document_id
      );
      const formattedManufactureOrder = await formatManufactureOrder(
        manufacture
      );
      dispatch(
        manufactureOrderActions.loadedManufactureOrder(
          formattedManufactureOrder
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createManufactureOrder =
  (data, isChangeStatus, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    const formatPayload = formatManufactureOrderPayload(data);
    try {
      const createdManufactureOrder =
        await ManufactureService.createManufactureOrder(formatPayload);

      const formattedManufactureOrder = await formatManufactureOrder(
        createdManufactureOrder
      );
      const activityLogPayload = {
        document_id: data.document_id,
        creator_document_id: user.document_id,
      };
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "manufacture_order",
        "สร้างใบสั่งผลิต"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      if (
        data.reference_document_list &&
        data.reference_document_list.length > 0
      ) {
        const createRefActivityLog = createActivityLogPayload(
          activityLogPayload,
          "sales_order",
          "สร้างใบสั่งผลิต"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
      }

      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "manufacture_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isChangeStatus) {
        const documentInput = {
          document_type: "manufacture_order",
          document_id: formattedManufactureOrder.document_id,
        };
        const { status } = await GlobalService.postNextStatus(documentInput);
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "manufacture_order",
          null,
          "pending_manu"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });
        dispatch(manufactureOrderActions.updateManufactureOrderStatus(status));
        enqueueSnackbar("ยืนยันสำเร็จ", {
          variant: "success",
        });
        await InventoryService.updateItemQuantity(
          createdManufactureOrder.item_document_id,
          {
            current_ordered_manufacture_qty: {
              increment: createdManufactureOrder.actual_production_qty,
            },
          }
        );
        await createdManufactureOrder.ingredient_list.forEach(async (list) => {
          await InventoryService.updateItemQuantity(list.item_document_id, {
            current_committed_manufacture_qty: {
              increment: list.qty,
            },
          });
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "manufacture_order",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrders(
          formattedManufactureOrder
        )
      );
      navigate(
        `/manufacture/order/${encodeURIComponent(
          createdManufactureOrder.document_id
        )}`
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          if (isChangeStatus) {
            enqueueSnackbar("ยืนยันไม่สำเร็จ", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
        }
      });
    }
  };

export const updateManufactureOrder =
  (updateDocumentId, data, isChangeStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const formatPayload = formatManufactureOrderPayload(data);
      const updatedManufactureOrder =
        await ManufactureService.updateManufactureOrder(
          updateDocumentId,
          formatPayload
        );
      const formattedManufactureOrder = await formatManufactureOrder(
        updatedManufactureOrder
      );
      dispatch(
        manufactureOrderActions.loadedManufactureOrder(
          formattedManufactureOrder
        )
      );

      const activityLogPayload = {
        document_id: data.document_id,
        creator_document_id: user.document_id,
      };

      const activityLogWithEmployee = {
        document_id: data.document_id,
        creator_document_id: user.document_id,
        employee_list: data.employee_list,
      };

      if (isChangeStatus) {
        const documentInput = {
          document_type: "manufacture_order",
          document_id: updateDocumentId,
        };
        const { status } = await GlobalService.postNextStatus(documentInput);
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "manufacture_order",
          "draft",
          "pending_manu"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });
        dispatch(manufactureOrderActions.updateManufactureOrderStatus(status));
        enqueueSnackbar("ยืนยันใบสั่งผลิตสำเร็จ", {
          variant: "success",
        });
        await InventoryService.updateItemQuantity(
          updatedManufactureOrder.item_document_id,
          {
            current_ordered_manufacture_qty: {
              increment: updatedManufactureOrder.actual_production_qty,
            },
          }
        );
        await updatedManufactureOrder.ingredient_list.forEach(async (list) => {
          await InventoryService.updateItemQuantity(list.item_document_id, {
            current_committed_manufacture_qty: {
              increment: list.qty,
            },
          });
        });
      } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogPayload,
          "manufacture_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(activityLogWithEmployee, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogWithEmployee,
            "add_related_employee",
            addedEmployeeList,
            "manufacture_order"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogWithEmployee,
            "delete_related_employee",
            deletedEmployeeList,
            "manufacture_order"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }
        enqueueSnackbar("แก้ไขใบสั่งผลิตสำเร็จ", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("แก้ไขใบสั่งผลิตไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const updateManufactureOrderStep =
  (documentId, stepNumber, currStatus, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const { status } = await GlobalService.setDocumentStep({
        document_id: documentId,
        document_type: "manufacture_order",
        step: stepNumber,
      });
      const activityLogPayload = {
        document_id: documentId,
        creator_document_id: user.document_id,
      };

      dispatch(manufactureOrderActions.updateManufactureOrderStatus(status));
      if (stepNumber === 3) {
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "manufacture_order",
          currStatus,
          "in_progress"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });

        enqueueSnackbar("ปรับสถานะเป็นกำลังผลิตสำเร็จ", {
          variant: "success",
        });
      } else if (stepNumber === 4) {
        const createActivityLogStatus = createActivityLogStatusPayload(
          activityLogPayload,
          "manufacture_order",
          currStatus,
          "finished"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLogStatus,
        });
        enqueueSnackbar("ปรับสถานะเป็นเสร็จสิ้นสำเร็จ", {
          variant: "success",
        });
      }
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      if (stepNumber === 3) {
        enqueueSnackbar("ปรับสถานะเป็นกำลังผลิตไม่สำเร็จ", {
          variant: "error",
        });
      } else if (stepNumber === 4) {
        enqueueSnackbar("ปรับสถานะเป็นเสร็จสิ้นไม่สำเร็จ", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("แก้ไขใบสั่งผลิตไม่สำเร็จ", {
          variant: "error",
        });
      }
    }
  };

export const getAllManufactureOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrdersExport"));
    try {
      const { results } = await ManufactureService.getAllManufactureOrders(
        input
      );
      let manufactureOrders = manufactureOrdersExportFormatter(results);
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrdersExport(
          manufactureOrders
        )
      );
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllManufactureOrdersExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allManufactureOrdersExport"));
    try {
      const { results } =
        await ManufactureService.getAllManufactureOrdersReport(input);
      let manufactureOrders = manufactureOrdersExportFormatter(results);
      dispatch(
        manufactureOrderActions.loadedAllManufactureOrdersExport(
          manufactureOrders
        )
      );
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allManufactureOrdersExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const cancelManufacture =
  (documentId, currStatus, user, enqueueSnackbar, payload) =>
  async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const {
        item_document_id,
        ingredient_list,
        actual_production_qty,
        goods_receive_qty,
      } = payload;
      const { is_canceled } = await GlobalService.cancelDocument({
        document_type: "manufacture_order",
        document_id: documentId,
      });
      enqueueSnackbar("ยกเลิกใบสั่งผลิตสำเร็จ", {
        variant: "success",
      });
      dispatch(manufactureOrderActions.loaded("manufactureOrder"));
      if (is_canceled) {
        dispatch(
          manufactureOrderActions.updateManufactureOrderStatus("cancelled")
        );
      }

      const activityLogPayload = {
        document_id: documentId,
        creator_document_id: user.document_id,
      };

      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "manufacture_order",
        currStatus,
        "cancelled"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      if (currStatus !== "draft") {
        await InventoryService.updateItemQuantity(item_document_id, {
          current_ordered_manufacture_qty: {
            decrement: actual_production_qty - goods_receive_qty,
          },
        });

        await ingredient_list.forEach(async (list) => {
          await InventoryService.updateItemQuantity(list.item_document_id, {
            current_committed_manufacture_qty: {
              decrement: list.qty - (list.goods_issue_qty || 0),
            },
          });
        });
      }
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("ยกเลิกใบสั่งผลิตไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getAllUnderqualityItemsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allUnderqualityItemsExport"));
    try {
      const { results } = await ManufactureService.getAllUnderqualityItems(
        input
      );
      let manufactureOrders = underqualityItemsExportFormatter(results);
      dispatch(
        manufactureOrderActions.loadedAllUnderqualityItemsExport(
          manufactureOrders
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allUnderqualityItemsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllUnderqualityItemsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("allUnderqualityItemsExport"));
    try {
      const { results } =
        await ManufactureService.getAllUnderqualityItemsReport(input);
      let manufactureOrders = underqualityItemsExportFormatter(results);
      dispatch(
        manufactureOrderActions.loadedAllUnderqualityItemsExport(
          manufactureOrders
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "allUnderqualityItemsExport",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodReceiveTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("manufactureOrder"));
    try {
      const { results } = await InventoryService.getAllGRAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "contains",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsReceive) => ({
        ...goodsReceive,
        created_date: unixToDateWithFormat(goodsReceive.created_date),
        document_date: unixToDateWithFormat(goodsReceive.document_date),
      }));

      dispatch(manufactureOrderActions.loadedGoodReceiveTab(formatResults));
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "manufactureOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodIssueTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(manufactureOrderActions.onLoading("purchaseOrder"));
    try {
      const { results } = await InventoryService.getAllGIAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "contains",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsIssue) => ({
        ...goodsIssue,
        created_date: unixToDateWithFormat(goodsIssue.created_date),
        document_date: unixToDateWithFormat(goodsIssue.document_date),
      }));

      dispatch(manufactureOrderActions.loadedGoodIssueTab(formatResults));
    } catch (err) {
      dispatch(
        manufactureOrderActions.rejectedActions({
          ...err,
          name: "purchaseOrder",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
