import PurchaseService from "../../../services/Purchase";
import GlobalService from "../../../services/Global";
import ActivityLogsService from "../../../services/ActivityLogs";
import { purchaseRequestActions } from "./purchase-request-slice";
import {
  purchaseQueryFormatter,
  purchaseRequestExportFormatter,
} from "../../../utils/purchasePayloadFormatter";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";

export const getAllPurchaseRequest =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("allPurchaseRequest"));
    try {
      const allPurchaseRequest = await PurchaseService.getAllPurchaseRequest(
        input
      );
      params.successCallback(
        allPurchaseRequest.results,
        allPurchaseRequest.count
      );
      dispatch(
        purchaseRequestActions.loadedAllPurchaseRequest(allPurchaseRequest)
      );
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "allPurchaseRequest",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseRequestSelf =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("allPurchaseRequest"));
    try {
      const allPurchaseRequest =
        await PurchaseService.getAllPurchaseRequestSelf(input);
      params.successCallback(
        allPurchaseRequest.results,
        allPurchaseRequest.count
      );
      dispatch(
        purchaseRequestActions.loadedAllPurchaseRequest(allPurchaseRequest)
      );
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "allPurchaseRequest",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseRequestExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("allPurchaseRequest"));
    try {
      const { results } = await PurchaseService.getAllPurchaseRequest(input);
      let purchaseRequest = purchaseRequestExportFormatter(results);
      dispatch(
        purchaseRequestActions.loadedAllPurchaseRequestExport(purchaseRequest)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "allPurchaseRequest",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllPurchaseRequestExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("allPurchaseRequest"));
    try {
      const { results } = await PurchaseService.getAllPurchaseRequestReport(
        input
      );
      let purchaseRequest = purchaseRequestExportFormatter(results);
      dispatch(
        purchaseRequestActions.loadedAllPurchaseRequestExport(purchaseRequest)
      );
    } catch (err) {
      console.log(err);
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "allPurchaseRequest",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseRequestById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const purchaseRequest = await PurchaseService.getPurchaseRequestById(
        uniqueInput
      );
      const formatPurchaseRequest = await purchaseQueryFormatter(
        purchaseRequest
      );
      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_request"
        );

      const approvalProgress = await PurchaseService.getApprovalProgress({
        reference_document_type: "purchase_request",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        purchaseRequestActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        purchaseRequestActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );
      dispatch(
        purchaseRequestActions.loadedPurchaseRequest(formatPurchaseRequest)
      );
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseOrderTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const purchaseOrderTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredPurchaseOrder = referenceDocument.filter(
        (document) => document.document_type === "purchase_order"
      );
      for (const [index, value] of filteredPurchaseOrder.entries()) {
        const purchaseOrder = await PurchaseService.getPurchaseOrderById({
          document_id: value.document_id,
        });
        purchaseOrderTab[index] = purchaseOrder;
      }
      dispatch(
        purchaseRequestActions.loadedPurchaseOrderTab(
          purchaseOrderTab.map((item) => ({
            ...item,
            contact_name: item.contact?.contact_name_1,
          }))
        )
      );
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewPurchaseRequest =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const { creator_document_id, ...otherPayload } = payload;
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const result = await PurchaseService.createPurchaseRequest(otherPayload);
      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "purchase_request",
        "สร้างใบขอซื้อสำเร็จ"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_list.length > 0) {
        payload.reference_document_list.forEach(async (list) => {
          const createRefActivityLog = createActivityLogPayload(
            {
              ...activityLogPayload,
              document_id: list.reference_document_id,
            },
            "sales_order",
            "สร้างใบขอซื้อ"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: createRefActivityLog,
          });
        });
      }

      if (state && state?.isCopied) {
        const activityLogPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogPayload,
          "purchase_request"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "purchase_request",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_request",
          null,
          "wait_approve"
        );
        await PurchaseService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_request",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }

      navigate(`/purchase/request/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบขอซื้อไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const updatePurchaseRequest =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const { document_id, creator_document_id, ...otherPayload } = payload;
      const purchaseRequest = await PurchaseService.updatePurchaseRequest(
        { document_id },
        otherPayload
      );
      const formatPurchaseRequest = await purchaseQueryFormatter(
        purchaseRequest,
        "purchase_request"
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "purchase_request",
        };
        if (status === "notApproved") {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_request",
            "not_approved",
            "wait_approve"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_request",
            step: 1,
          });
          await PurchaseService.postNextStatus(documentInput);
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
          dispatch(
            purchaseRequestActions.loadedPurchaseRequest({
              ...formatPurchaseRequest,
              render_status: "waitApprove",
            })
          );
        } else {
          const updatedPurchaseRequest = await PurchaseService.postNextStatus(
            documentInput
          );
          if (
            updatedPurchaseRequest?.approval_progress_list
              ?.map((approval) => approval?.approval_status)
              .includes("PENDING")
          ) {
            dispatch(
              purchaseRequestActions.updateApprovalStatus({
                approval_list: updatedPurchaseRequest.approval_progress_list,
                user,
              })
            );
            const sendToApproveActivityLog = createActivityLogStatusPayload(
              activityLogPayload,
              "purchase_request",
              "draft",
              "wait_approve"
            );
            dispatch(
              purchaseRequestActions.loadedPurchaseRequest({
                ...formatPurchaseRequest,
                render_status: "waitApprove",
              })
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: sendToApproveActivityLog,
            });
            enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
              variant: "success",
            });
          } else {
            if (status === "approved") {
              const createActivityLog = createActivityLogPayload(
                activityLogPayload,
                "purchase_request",
                "บันทึกใบขอซื้อ"
              );
              await ActivityLogsService.createActivityLogs({
                createActivityLogInput: createActivityLog,
              });

              const sendToApproveActivityLog = createActivityLogStatusPayload(
                activityLogPayload,
                "purchase_request",
                "approved",
                "finished"
              );
              await ActivityLogsService.createActivityLogs({
                createActivityLogInput: sendToApproveActivityLog,
              });
              enqueueSnackbar("บันทึกใบขอซื้อสำเร็จ", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("อัพเดทใบขอซื้อสำเร็จ", {
                variant: "success",
              });
            }
            dispatch(
              purchaseRequestActions.loadedPurchaseRequest({
                ...formatPurchaseRequest,
                render_status: updatedPurchaseRequest.status,
              })
            );
          }
        }
      } else {
        if (status === "notApproved") {
          const setDocumentStep = await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "purchase_request",
            step: 1,
          });

          const sendToDraftActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "purchase_request",
            "not_approved",
            "draft"
          );

          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToDraftActivityLog,
          });

          dispatch(
            purchaseRequestActions.loadedPurchaseRequest({
              ...formatPurchaseRequest,
              render_status: setDocumentStep.status,
            })
          );
          enqueueSnackbar("บันทึกร่างสำเร็จ", {
            variant: "success",
          });
        } else {
          const editActivityLog = createActivityLogEditPayload(
            activityLogPayload,
            "purchase_request"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: editActivityLog,
          });
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "purchase_request"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "purchase_request"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
          switch (status) {
            case "draft":
              dispatch(
                purchaseRequestActions.loadedPurchaseRequest(
                  formatPurchaseRequest
                )
              );
              enqueueSnackbar("บันทึกร่างสำเร็จ", {
                variant: "success",
              });
              break;
            default:
              dispatch(
                purchaseRequestActions.loadedPurchaseRequest({
                  ...formatPurchaseRequest,
                  render_status: status,
                })
              );
              enqueueSnackbar("แก้ไขใบขอซื้อสำเร็จ", {
                variant: "success",
              });
          }
        }
      }
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      console.error("err", err);
      switch (status) {
        case "draft":
          enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
            variant: "error",
          });
          break;
        default:
          enqueueSnackbar("อัพเดทใบขอซื้อไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approvePurchaseRequest =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_request"
        );

      const { approval_progress_list } =
        await PurchaseService.postApproveStatus(documentInput);

      dispatch(
        purchaseRequestActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          purchaseRequestActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );

        dispatch(
          purchaseRequestActions.updatePurchaseRequestStatus("waitApprove")
        );

        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_request"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "purchase_request"
        );

        dispatch(
          purchaseRequestActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(
          purchaseRequestActions.updatePurchaseRequestStatus("approved")
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "purchase_request",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declinePurchaseRequest =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_request",
        "wait_approve",
        "not_approved"
      );

      const { approval_progress_list } =
        await PurchaseService.postDeclineStatus(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });

      const approvalTemplates =
        await PurchaseService.getApprovalTemplatesByDocumentType(
          "purchase_request"
        );

      dispatch(
        purchaseRequestActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        purchaseRequestActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );

      dispatch(
        purchaseRequestActions.updatePurchaseRequestStatus("notApproved")
      );
    } catch (err) {
      console.error(err);
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelPurchaseRequest =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(purchaseRequestActions.onLoading("purchaseRequest"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "purchase_request",
        null,
        "cancelled"
      );
      await PurchaseService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(purchaseRequestActions.updatePurchaseRequestStatus("cancelled"));
    } catch (err) {
      dispatch(
        purchaseRequestActions.rejectedActions({
          ...err,
          name: "purchaseRequest",
        })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
