import InventoryService from "../../../services/Inventory";
import {
  createActivityLogEditPayload,
  createActivityLogPayload,
} from "../../../utils/activityLogsPayloadFormatter";
// import GlobalService from "../../../services/Global";
import { itemMasterActions } from "./itemMaster-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";

export const getAllItems = (input, enqueueSnackbar) => async (dispatch) => {
  dispatch(itemMasterActions.onLoading("allItems"));
  try {
    const allItems = await InventoryService.getAllItems(input);
    dispatch(itemMasterActions.loadedAllItems({ allItems }));
  } catch (err) {
    dispatch(itemMasterActions.rejectedActions({ ...err, name: "allItems" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const getAllItemsAggrid =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(itemMasterActions.onLoading("allItems"));
    try {
      const ItemsAggrid = await InventoryService.getAllItemsAggrid(input);
      params.successCallback(ItemsAggrid.results, ItemsAggrid.count);
      dispatch(
        itemMasterActions.loadedAllItems({
          allItems: ItemsAggrid.results,
          count: ItemsAggrid.count,
        })
      );
    } catch (err) {
      dispatch(itemMasterActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllItemsAggridReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(itemMasterActions.onLoading("allItems"));
    try {
      const ItemsAggrid = await InventoryService.getAllItemsAggridReport(input);
      params.successCallback(ItemsAggrid.results, ItemsAggrid.count);
      dispatch(
        itemMasterActions.loadedAllItems({
          allItems: ItemsAggrid.results,
          count: ItemsAggrid.count,
        })
      );
    } catch (err) {
      dispatch(itemMasterActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getItem = (uniqueInput, enqueueSnackbar) => async (dispatch) => {
  dispatch(itemMasterActions.onLoading("item"));
  try {
    const item = await InventoryService.getItem(uniqueInput);
    dispatch(itemMasterActions.loadedItem(item));
  } catch (err) {
    dispatch(itemMasterActions.rejectedActions({ ...err, name: "item" }));
    enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
      variant: "error",
    });
  }
};

export const updateItem =
  (uniqueInput, updateInput, enqueueSnackbar, navigate, user) =>
  async (dispatch) => {
    dispatch(itemMasterActions.onLoading("allItems"));
    try {
      const updatedItem = await InventoryService.updateItem(
        uniqueInput,
        updateInput
      );
      enqueueSnackbar("แก้ไขสินค้าสำเร็จ", {
        variant: "success",
      });
      // navigate(`/inventory/items`);
      const editActivityLog = createActivityLogEditPayload(
        { ...uniqueInput, creator_document_id: user.document_id },
        "item"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: editActivityLog,
      });
      window.location.reload();
      // navigate(
      //   `/inventory/items/${updatedItem.document_id}?tab=item&subtab=general`
      // );
      dispatch(itemMasterActions.loadedItem(updatedItem));
    } catch (err) {
      dispatch(itemMasterActions.rejectedActions({ ...err, name: "allItems" }));
      enqueueSnackbar("แก้ไขสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const createItem =
  (createItemInput, enqueueSnackbar, navigate, user) => async (dispatch) => {
    dispatch(itemMasterActions.onLoading("item"));
    try {
      const newItem = await InventoryService.createItem(createItemInput);
      dispatch(itemMasterActions.loadedItem(newItem));
      enqueueSnackbar("สร้างสินค้าสำเร็จ", {
        variant: "success",
      });
      navigate(
        `/inventory/items/` +
          encodeURIComponent(newItem.document_id) +
          "?tab=item&subtab=general"
      );
      const createActivityLog = createActivityLogPayload(
        { ...createItemInput, creator_document_id: user.document_id },
        "item",
        "สร้างสินค้า"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
    } catch (err) {
      dispatch(itemMasterActions.rejectedActions({ ...err, name: "item" }));
      enqueueSnackbar("สร้างสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const createItemWithUom =
  (createItemInput, enqueueSnackbar, navigate, user) => async (dispatch) => {
    dispatch(itemMasterActions.onLoading("item"));
    try {
      const newItem = await InventoryService.createItemWithUom(createItemInput);
      dispatch(itemMasterActions.loadedItem(newItem));
      enqueueSnackbar("สร้างสินค้าสำเร็จ", {
        variant: "success",
      });
      const createActivityLog = createActivityLogPayload(
        { ...createItemInput, creator_document_id: user.document_id },
        "item",
        "สร้างสินค้า"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      navigate(
        `/inventory/items/` +
          encodeURIComponent(newItem.document_id) +
          "?tab=item&subtab=general"
      );
    } catch (err) {
      dispatch(itemMasterActions.rejectedActions({ ...err, name: "item" }));
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(
            errorMessageHandler(
              error.message,
              "รหัสสินค้านี้มีในระบบแล้ว กรุณาเรียกรหัสสินค้าใหม่"
            ),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar("สร้างสินค้าไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const deleteItem =
  (docId, enqueueSnackbar, navigate, t) => async (dispatch) => {
    try {
      const allDoc = await InventoryService.checkDeleteItem(docId);
      const allEmpty = Object.values(allDoc).every(
        (value) => value.length === 0
      );
      if (allEmpty) {
        try {
          await InventoryService.deleteItem(docId);
          enqueueSnackbar(`ลบสินค้าสำเร็จ`, {
            variant: "success",
          });
          navigate(`/inventory/items/`);
        } catch (error) {
          enqueueSnackbar(`ลบสินค้าผิดพลาด`, {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar(
          `ไม่สามารถลบสินค้าได้เนื่องจากสินค้านี้ถูกใช้ในระบบแล้ว`,
          {
            variant: "error",
          }
        );
      }
    } catch (err) {
      enqueueSnackbar("ลบสินค้าไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
