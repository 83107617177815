import { Grid, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import AgGrid from "../../../components/Table/AgGrid";
import TotalBox from "../../../components/UI/TotalBox";
import {
  getItemsCurrentStockLocation,
  getItemsCurrentStockSerialNumber,
} from "../../../features/Inventory/Report/report-actions";
import { filterParamsOptions } from "../../../utils/filterparams";
import InventoryService from "../../../services/Inventory";
import { getAllLocation } from "../../../features/Setting/Location/location-actions";
import { formatNumber } from "../../../utils/dataTransformer";

const OnHandItemWarehouse = () => {
  const onHandItemByWarehouseGridRef = useRef();
  const onHandItemByLocationGridRef = useRef();
  const { t } = useTranslation();
  const { item } = useSelector((state) => state.itemMaster);
  const dispatch = useDispatch();
  const [newData, setNewData] = useState([]);
  const { allLocation } = useSelector((state) => state.location);
  const { id } = useParams();

  // const columnDefsWarehouse = useMemo(() => [], []);

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterItemCurrentStock = {
        ...request.filterModel,
        item_document_id: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
      };
      dispatch(
        getItemsCurrentStockLocation(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterItemCurrentStock,
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const datasourceSN = {
    getRows(params) {
      const request = params.request;
      const filterItemCurrentStock = {
        ...request.filterModel,
        item_document_id: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
      };
      dispatch(
        getItemsCurrentStockSerialNumber(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterItemCurrentStock,
            sortModel: request.sortModel,
          },
          params
        )
      );
    },
  };

  const changePayloadData = useCallback((allData) => {
    let itemData = [];
    let checkArray = [];
    allData.forEach((item) => {
      if (!checkArray.includes(item.item_document_id)) {
        checkArray.push(item.item_document_id);
        itemData.push({
          item_document_id: item.item_document_id,
        });
      }
      let findItem = itemData.find(
        (x) => x.item_document_id === item.item_document_id
      );
      let myValue = item["current_quantity"];
      if (typeof myValue === "number") myValue = parseFloat(myValue);
      findItem[item["warehouse_thai_name"]] = myValue;
    });
    setNewData(itemData);
  }, []);

  const findInput = useMemo(() => {
    return {
      findManyInput: {},
    };
  }, []);

  useEffect(() => {
    dispatch(getAllLocation(findInput));
  }, [dispatch, findInput]);

  const getData = useCallback(async () => {
    const filterItemCurrentStock = {
      item_document_id: {
        filterType: "text",
        type: "equals",
        filter: id,
      },
    };
    const { results } = await InventoryService.getAllICS({
      startRow: 0,
      endRow: 9999999,
      filterModel: filterItemCurrentStock,
    });
    changePayloadData(results);
  }, [changePayloadData, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  const onGridReadySN = (params) => {
    params.api.setServerSideDatasource(datasourceSN);
  };

  const columnDefsWarehouse =
    allLocation &&
    allLocation.map((location) => {
      return {
        field: location.thai_name,
        headerName: location.thai_name,
        filter: false,
        floatingFilter: false,
      };
    });

  const columnDefsLocation = [
    {
      field: "warehouse_document_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "warehouse_thai_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
    },
    {
      field: "bin_location_document_id",
      headerName: t("inventory.itemCurrentStockLocation.binLocation"),
      filter: "agTextColumnFilter",
    },
    // {
    //   field: "bin_location_name",
    //   headerName: t("inventory.itemCurrentStockLocation.binLocation"),
    //   filter: "agTextColumnFilter",
    // },
    {
      field: "current_quantity",
      headerName: t("inventory.itemCurrentStock.currentQuantity"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_value",
      headerName: t("inventory.itemCurrentStock.currentValue"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      hide: true,
    },
  ];

  const columnDefsLocationSN = [
    {
      field: "warehouse_document_id",
      headerName: t("inventory.warehouseId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "warehouse_thai_name",
      headerName: t("inventory.warehouse"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "serial_number",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "bin_location_document_id",
      headerName: t("inventory.itemCurrentStockLocation.binLocation"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "current_quantity",
      headerName: t("inventory.itemCurrentStock.currentQuantity"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "batch_number",
      headerName: "Barcode",
      width: 350,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
  ];

  const firstCardList = [
    {
      title: t("inventory.quantities.currentAvailableQty"),
      field: "total",
      value: formatNumber(item.current_available_qty),
    },
    {
      title: t("inventory.quantities.currentStockQty"),
      field: "current_stock_qty",
      value: formatNumber(item.current_stock_qty),
    },
  ];

  const secoundCardList = [
    {
      title: t("inventory.quantities.currentOrderedPurchaseQty"),
      field: "current_ordered_purchase_qty",
      value: formatNumber(item.current_ordered_purchase_qty),
      borderColor: "#328F35",
    },
    {
      title: t("inventory.quantities.currentOrderedManufactureQty"),
      field: "current_ordered_manufacture_qty",
      value: formatNumber(item.current_ordered_manufacture_qty),
      borderColor: "#328F35",
    },
    {
      title: t("inventory.quantities.currentCommittedSalesQty"),
      field: "current_committed_sales_qty",
      value: formatNumber(item.current_committed_sales_qty),
      borderColor: "#328F35",
    },
    {
      title: t("inventory.quantities.currentCommittedManufactureQty"),
      field: "current_committed_manufacture_qty",
      value: formatNumber(item.current_committed_manufacture_qty),
      borderColor: "#328F35",
    },
  ];

  const renderCard = (list) =>
    list.map((menu) => (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={menu.title}>
        <TotalBox
          title={menu.title}
          total={menu.value}
          unit={item.base_uom.name}
          borderColor={menu.borderColor}
        />
      </Grid>
    ));

  return (
    <>
      <Grid container spacing={1}>
        {renderCard(firstCardList)}
      </Grid>
      <Grid container spacing={1}>
        {renderCard(secoundCardList)}
      </Grid>
      <CustomizedBox>
        <Typography fontWeight={"bold"} mb={3}>
          {t("inventory.items.onHandItemWarehouseTab")}
        </Typography>
        <AgGrid
          ref={onHandItemByWarehouseGridRef}
          columnDefs={columnDefsWarehouse}
          rowData={newData}
          // onGridReady={onGridReadyST}
          height={450}
          disabledSidebar
        />
      </CustomizedBox>
      <CustomizedBox>
        <Typography fontWeight={"bold"} mb={3}>
          {t("inventory.items.onHandItemWarehouse")}
        </Typography>
        <AgGrid
          ref={onHandItemByLocationGridRef}
          columnDefs={columnDefsLocation}
          onGridReady={onGridReady}
          height={450}
          disabledSidebar
        />
      </CustomizedBox>
      <CustomizedBox>
        <Typography fontWeight={"bold"} mb={3}>
          {t("inventory.items.onHandItemWarehouseSN")}
        </Typography>
        <AgGrid
          ref={onHandItemByLocationGridRef}
          columnDefs={columnDefsLocationSN}
          onGridReady={onGridReadySN}
          height={450}
          disabledSidebar
        />
      </CustomizedBox>
    </>
  );
};

export default OnHandItemWarehouse;
