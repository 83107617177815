import * as AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

export const uploadFileToS3 = async (file, folder, userID) => {
  // aws s3 config

  AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const fileName = folder + new Date().toISOString() + userID + uuidv4();
  const photoKey = folder + "/" + fileName;

  const upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: process.env.REACT_APP_AWS_FILE_UPLOAD_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ContentType: file.type,
    },
  });

  const promise = await upload.promise();
  return promise;
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
