import { Avatar, Box } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserWithDT } from "../../../features/User/Account/account-actions";
import LogisticService from "../../../services/Logistic";
import { dateComparator } from "../../../utils/filterparams";
import CustomizedDate from "../../Custom/CustomizedDate";
import CustomizedLetterAvatar from "../../Custom/CustomizedLetterAvatar";
import CustomizedStatus from "../../Custom/CustomizedStatus";
import CheckboxModalTable from "../CheckboxModalTable";

const GoodsDeliverTable = ({
  fields,
  setValue,
  showSelectEmployees,
  setShowSelectEmployees,
  employeeIds,
  setEmployeeIds,
  employeeIdsSnapshot,
  setEmployeeIdsSnapshot,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectEmployeesGridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(moment());
  const { isLoading } = useSelector((state) => state.account);

  const columnDefs = [
    {
      field: "document_id",
      headerName: t("user.account.employeeId"),
      filter: "agTextColumnFilter",
      width: 300,
    },
    {
      field: "img_url",
      headerName: t("user.account.picture"),
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data.img_url) {
          return <Avatar alt="img_url" src={params.data.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.data.first_name + " " + params.data.last_name}
            />
          );
        }
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "total_delivery_trip",
      headerName: "จำนวนรอบรถต่อวัน",
      filter: "agNumberColumnFilter",
    },
    {
      field: "first_name",
      headerName: t("user.account.firstname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "last_name",
      headerName: t("user.account.lastname"),
      filter: "agTextColumnFilter",
    },
    {
      field: "department",
      headerName: t("user.account.department"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: [
          "บริหาร",
          "คลังสินค้า",
          "รายรับ",
          "จัดซื้อ",
          "บัญชี",
          "ขนส่ง",
          "ผลิต",
          "ดูแลระบบ",
        ],
      },
    },
    {
      field: "position",
      headerName: t("user.account.position"),
      filter: "agTextColumnFilter",
    },
    {
      field: "status",
      headerName: t("user.account.status"),
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return <CustomizedStatus status={params.value} />;
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "issue_date",
      headerName: "วันที่ออกเอกสาร",
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: dateComparator,
      },
      hide: true,
    },
  ];

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            field: "document_id",
            headerName: t("logistic.documentId"),
            filter: false,
            sort: false,
            flex: 1,
          },
          {
            field: "issue_date",
            headerName: t("sales.issueDate"),
            filter: "agDateColumnFilter",
            flex: 1,
          },
          {
            field: "render_status",
            headerName: t("sales.status"),
            sortable: false,
            flex: 1,
            filter: false,
            sort: false,
            cellRenderer: (params) => (
              <CustomizedStatus status={params.value} />
            ),
            cellStyle: {
              display: "flex",
              justifycontent: "center",
              alignItems: "center",
            },
          },
          {
            field: "internal_remark",
            headerName: t("logistic.internalRemark"),
            filter: false,
            sort: false,
            flex: 1,
          },
        ],
        suppressContextMenu: true,
      },
      getDetailRowData: async (params) => {
        const { document_id } = params.data;
        try {
          const { results } =
            await LogisticService.getAllDeliveryTripsByEmployee(
              document_id,
              date
            );
          params.successCallback(results);
        } catch (err) {
          console.error(err);
        }
      },
    };
  }, [date, t]);

  const finishEmployeesSelect = (data) => {
    // filter out employees not selected in current modal session
    let filteredEmployees = fields.filter((employee) =>
      employeeIds.includes(employee.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredEmployeesId = filteredEmployees.map(
      (employee) => employee.document_id
    );

    data.forEach((employee) => {
      if (!filteredEmployeesId.includes(employee.document_id)) {
        filteredEmployees.push(employee);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredEmployees = filteredEmployees.sort((a, b) => {
      return (
        employeeIds.indexOf(a.document_id) - employeeIds.indexOf(b.document_id)
      );
    });
    setValue("goods_deliver_list", filteredEmployees);
    setShowSelectEmployees(false);
  };

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const formatFilter = { ...filterModel };
      delete formatFilter.issue_date;

      let formatDate;

      if (filterModel.issue_date) {
        formatDate = new Date(moment(filterModel.issue_date.dateFrom));
      }

      dispatch(
        getAllUserWithDT(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilter,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar,
          filterModel.issue_date ? formatDate : new Date()
        )
      );
    },
  };

  const onFilterChanged = useCallback((params) => {
    const instance = params.api.getFilterInstance("department");
    instance.setModel({ filterType: "set", values: ["ขนส่ง"] });
    params.api.onFilterChanged();
  }, []);

  const onGridReady = (params) => {
    onFilterChanged(params);
    params.api.setServerSideDatasource(datasource);
  };

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const dateOption = [
    {
      name: "issue_date",
      value: date,
      onChange: (value) => {
        setDate(value);
        const instance =
          selectEmployeesGridRef.current.api.getFilterInstance("issue_date");

        instance.setModel({
          type: "equals",
          dateFrom: moment(value).format("YYYY-MM-DD HH:mm:ss"),
          dateTo: null,
        });

        selectEmployeesGridRef.current.api.onFilterChanged();
      },
    },
  ];

  return (
    <CheckboxModalTable
      modalTitle="ผู้จัดส่ง"
      btnTitle={t("button.add")}
      gridRef={selectEmployeesGridRef}
      height={450}
      columnDefs={columnDefs}
      rowSelection="multiple"
      onFinishEditing={finishEmployeesSelect}
      modalIsOpen={showSelectEmployees}
      closeModal={() => setShowSelectEmployees(false)}
      onGridReady={onGridReady}
      selectedIds={employeeIds}
      setSelectedIds={setEmployeeIds}
      idsSnapshot={employeeIdsSnapshot}
      setIdsSnapshot={setEmployeeIdsSnapshot}
      masterDetail
      detailCellRendererParams={detailCellRendererParams}
      getRowId={getRowId}
      isLoading={isLoading.allUsersModal}
      disabledSidebar
    >
      <Box sx={{ mb: 2 }}>
        <CustomizedDate dateOption={dateOption} />
      </Box>
    </CheckboxModalTable>
  );
};

export default GoodsDeliverTable;
