import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment } from "react";
import TraceEntryList from "./TraceEntryList";
import CloseIcon from "@mui/icons-material/Close";
import { useWatch } from "react-hook-form";
import { formatNumber } from "../../../../utils/dataTransformer";

const headers = [
  "ลำดับ",
  "วันที่และเวลา",
  "รหัสสินค้า - ชื่อสินค้า",
  "จำนวน",
  "หน่วย",
  "SN",
  "สถานที่ต้นทาง",
  "สถานที่ปลายทาง",
  "ผู้สแกน",
  "Barcode",
  "",
];

const GoodsTransferTable = ({
  control,
  fields,
  remove,
  disabled,
  setValue,
}) => {
  const TotalPostedQuantity = ({ control, index }) => {
    const watchQuantity = useWatch({
      control,
      name: `goods_transfer_list[${index}].stock_entry_list.trace_entry_list`,
    });
    const totalQuantity =
      watchQuantity?.reduce(
        (prev, curr) => prev + parseFloat(curr?.posted_quantity),
        0
      ) || 0;
    return (
      <TableCell align="center">
        {formatNumber(Math.abs(totalQuantity))}
      </TableCell>
    );
  };

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, width: "100%", overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>
                <Box width="max-content">{header}</Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {renderBoxMaxContent(
                    row.stock_entry_list?.item?.document_id +
                      " - " +
                      row.stock_entry_list?.item?.name
                  )}
                </TableCell>
                <TotalPostedQuantity control={control} index={index} />
                <TableCell align="center">
                  {row.stock_entry_list?.uom?.name}
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {disabled ? null : (
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={false}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
              <TraceEntryList
                control={control}
                nestIndex={index}
                disabled={disabled}
                setValue={setValue}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsTransferTable;
