import SalesService from "../../../services/Sales";
import { salesOrderActions } from "./sales-order-slice";
import {
  salesExportFormatter,
  salesQueryFormatter,
} from "../../../utils/salesPayloadFormatter";
import GlobalService from "../../../services/Global";
import LogisticServlice from "../../../services/Logistic";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import moment from "moment";
import InventoryService from "../../../services/Inventory";
import PurchaseService from "../../../services/Purchase";
import ManufactureService from "../../../services/Manufacture";
import { unixToDateWithFormat } from "../../../utils/date-converter";

export const getAllSalesOrders =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrders"));
    try {
      const allSalesOrders = await SalesService.getAllSalesOrders(input);
      params.successCallback(allSalesOrders.results, allSalesOrders.count);
      dispatch(salesOrderActions.loadedAllSalesOrders(allSalesOrders.results));
    } catch (err) {
      console.error(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

// export const getAllSalesOrdersExport =
//   (input, enqueueSnackbar) => async (dispatch) => {
//     dispatch(salesOrderActions.onLoading("allSalesOrders"));
//     try {
//       const { results } = await SalesService.getAllSalesOrders(input);
//       const formatAllSalesOrders = results.map((salesOrder) => {
//         let item_list = [];
//         salesOrder.item_list.forEach((item) => {
//           const pre_vat_amount =
//             item.qty * item.price_per_unit - item.discount_amount;
//           const withholding_tax =
//             item.withholding_tax === -1
//               ? "ยังไม่ระบุ"
//               : item.withholding_tax === 0
//               ? "ไม่มี"
//               : (item.withholding_tax * 100).toString();
//           const formatItemList = {
//             ...item,
//             pre_vat_amount,
//             withholding_tax,
//           };
//           item_list.push(formatItemList);
//         });
//         return {
//           ...salesOrder,
//           item_list,
//           issue_date: unixToDateWithFormat(salesOrder.issue_date),
//           due_date: unixToDateWithFormat(salesOrder.due_date),
//           delivery_date: unixToDateWithFormat(salesOrder.delivery_date),
//           created_date: unixToDateWithFormat(salesOrder.created_date),
//           updated_date: unixToDateWithFormat(salesOrder.updated_date),
//           render_status: filterStatusValueFormatter(salesOrder.render_status),
//         };
//       });
//       dispatch(salesOrderActions.loadedAllSalesOrders(formatAllSalesOrders));
//     } catch (err) {
//       console.error(err);
//       dispatch(
//         salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
//       );
//       enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
//         variant: "error",
//       });
//     }
//   };

export const getAllSalesOrdersExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrders"));
    try {
      const { results } = await SalesService.getAllSalesOrders(input);

      // for (const [index, value] of results.entries()) {
      //   const documentRelations = await GlobalService.getDocumentRelations(
      //     value.document_id
      //   );
      //   const formatDocumentRelation = await documentRelations.map(
      //     (document) => document.reference_document_id
      //   );
      //   results[index].reference_document_id = await formatDocumentRelation;
      // }

      let salesOrders = salesExportFormatter(results, "sales_order");

      dispatch(salesOrderActions.loadedAllSalesOrdersExports(salesOrders));
    } catch (err) {
      console.error(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesOrdersExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("allSalesOrders"));
    try {
      const { results } = await SalesService.getAllSalesOrdersReport(input);

      // for (const [index, value] of results.entries()) {
      //   const documentRelations = await GlobalService.getDocumentRelations(
      //     value.document_id
      //   );
      //   const formatDocumentRelation = await documentRelations.map(
      //     (document) => document.reference_document_id
      //   );
      //   results[index].reference_document_id = await formatDocumentRelation;
      // }

      let salesOrders = salesExportFormatter(results, "sales_order");

      dispatch(salesOrderActions.loadedAllSalesOrdersExports(salesOrders));
    } catch (err) {
      console.log(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "allSalesOrders" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewSalesOrder =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const result = await SalesService.createSalesOrder(payload);
      const createActivityLog = createActivityLogPayload(
        payload,
        "sales_order",
        "สร้างใบสั่งขาย"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_id) {
        const createRefActivityLog = createActivityLogPayload(
          {
            document_id: payload.reference_document_id,
            creator_document_id: user.document_id,
          },
          "quotation",
          "สร้างใบสั่งขาย"
        );
        const finishedActivityLog = createActivityLogStatusPayload(
          {
            document_id: payload.reference_document_id,
            creator_document_id: user.document_id,
          },
          "quotation",
          "accepted",
          "finished"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: finishedActivityLog,
        });
      }

      if (state && state?.isCopied) {
        const copiedActivityLog = createActivityLogCopiedPayload(
          {
            document_id: state.document_id,
            creator_document_id: user.document_id,
          },
          "sales_order"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "sales_order",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          {
            document_id: payload.document_id,
            creator_document_id: user.document_id,
          },
          "sales_order",
          null,
          "wait_approve"
        );
        await SalesService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          {
            document_id: payload.document_id,
            creator_document_id: user.document_id,
          },
          "sales_order",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/sales/order/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบสั่งขายไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getSalesOrderById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const salesOrder = await SalesService.getSalesOrderById(uniqueInput);
      const formatSalesOrder = await salesQueryFormatter(
        salesOrder,
        "sales_order"
      );

      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_order");

      const approvalProgress = await SalesService.getApprovalProgress({
        reference_document_type: "sales_order",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        salesOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        salesOrderActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(salesOrderActions.loadedSalesOrder(formatSalesOrder));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesInvoiceTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const salesInvoiceTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredSalesInvoice = referenceDocument.filter(
        (document) => document.document_type === "sales_invoice"
      );
      for (const [index, value] of filteredSalesInvoice.entries()) {
        const salesInvoice = await SalesService.getSalesInvoiceById({
          document_id: value.document_id,
        });
        salesInvoiceTab[index] = salesInvoice;
      }
      dispatch(salesOrderActions.loadedSalesInvoiceTab(salesInvoiceTab));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getPurchaseRequestTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const purchaseRequestTab = [];

      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredPurchaseRequest = referenceDocument.filter(
        (document) => document.document_type === "purchase_request"
      );
      for (const [index, value] of filteredPurchaseRequest.entries()) {
        const purchaseOrder = await PurchaseService.getPurchaseRequestById({
          document_id: value.document_id,
        });
        purchaseRequestTab[index] = purchaseOrder;
      }
      dispatch(salesOrderActions.loadedPurchaseRequestTab(purchaseRequestTab));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getManufactureOrderTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const manufactureOrderTab = [];
      //TODO: TBC
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredManufactureOrder = referenceDocument.filter(
        (document) => document.document_type === "manufacture_order"
      );
      for (const [index, value] of filteredManufactureOrder.entries()) {
        const manufactureOrder =
          await ManufactureService.getManufactureOrderById(value.document_id);
        manufactureOrderTab[index] = manufactureOrder;
      }
      dispatch(
        salesOrderActions.loadedManufactureOrderTab(manufactureOrderTab)
      );
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getDeliveryOrderTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      let deliveryOrderTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredDeliveryOrder = referenceDocument.filter(
        (document) => document.document_type === "delivery_order"
      );
      for (const [index, value] of filteredDeliveryOrder.entries()) {
        const deliveryOrder = await LogisticServlice.getDeliveryOrderById({
          document_id: value.document_id,
        });
        deliveryOrderTab[index] = deliveryOrder;
      }
      dispatch(salesOrderActions.loadedDeliveryOrderTab(deliveryOrderTab));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodIssueTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const { results } = await InventoryService.getAllGIAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "contains",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsIssue) => ({
        ...goodsIssue,
        created_date: unixToDateWithFormat(goodsIssue.created_date),
        document_date: unixToDateWithFormat(goodsIssue.document_date),
      }));

      dispatch(salesOrderActions.loadedGoodIssueTab(formatResults));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getSalesReturnTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const salesReturnTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);
      const filteredSalesReturn = referenceDocument.filter(
        (document) => document.document_type === "sales_return"
      );
      for (const [index, value] of filteredSalesReturn.entries()) {
        const salesReturn = await SalesService.getSalesReturnById({
          document_id: value.document_id,
        });
        salesReturnTab[index] = salesReturn;
      }
      dispatch(salesOrderActions.loadedSalesReturnTab(salesReturnTab));
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateSalesOrder =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const salesOrder = await SalesService.updateSalesOrder(payload);
      const formatSalesOrder = await salesQueryFormatter(
        salesOrder,
        "sales_order"
      );
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "sales_order",
        };
        if (status === "notApproved") {
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "sales_order",
            "not_approved",
            "wait_approve"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "sales_order",
            step: 1,
          });
          await SalesService.postNextStatus(documentInput);
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
          dispatch(
            salesOrderActions.loadedSalesOrder({
              ...formatSalesOrder,
              render_status: "waitApprove",
            })
          );
        } else {
          const updatedSalesOrder = await SalesService.postNextStatus(
            documentInput
          );

          if (
            updatedSalesOrder?.approval_progress_list
              ?.map((approval) => approval?.approval_status)
              .includes("PENDING")
          ) {
            dispatch(
              salesOrderActions.updateApprovalStatus({
                approval_list: updatedSalesOrder.approval_progress_list,
                user,
              })
            );
            const sendToApproveActivityLog = createActivityLogStatusPayload(
              activityLogPayload,
              "sales_order",
              "draft",
              "wait_approve"
            );
            dispatch(
              salesOrderActions.loadedSalesOrder({
                ...formatSalesOrder,
                render_status: "waitApprove",
              })
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: sendToApproveActivityLog,
            });
            enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
              variant: "success",
            });
          } else {
            if (status === "approved") {
              const createActivityLog = createActivityLogPayload(
                activityLogPayload,
                "sales_order",
                "บันทึกใบแจ้งหนี้"
              );
              await ActivityLogsService.createActivityLogs({
                createActivityLogInput: createActivityLog,
              });

              const sendToApproveActivityLog = createActivityLogStatusPayload(
                activityLogPayload,
                "sales_order",
                "approved",
                "finished"
              );
              await ActivityLogsService.createActivityLogs({
                createActivityLogInput: sendToApproveActivityLog,
              });
              enqueueSnackbar("บันทึกใบแจ้งหนี้สำเร็จ", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("อัพเดทใบสั่งขายสำเร็จ", {
                variant: "success",
              });
            }
            dispatch(
              salesOrderActions.loadedSalesOrder({
                ...formatSalesOrder,
                render_status: updatedSalesOrder.status,
              })
            );
          }
        }
      } else {
        if (status === "notApproved") {
          const setDocumentStep = await GlobalService.setDocumentStep({
            document_id: payload.document_id,
            document_type: "sales_order",
            step: 1,
          });

          const sendToDraftActivityLog = createActivityLogStatusPayload(
            activityLogPayload,
            "sales_order",
            "not_approved",
            "draft"
          );

          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToDraftActivityLog,
          });

          dispatch(
            salesOrderActions.loadedSalesOrder({
              ...formatSalesOrder,
              render_status: setDocumentStep.status,
            })
          );
          enqueueSnackbar("บันทึกร่างสำเร็จ", {
            variant: "success",
          });
        } else {
          const editActivityLog = createActivityLogEditPayload(
            activityLogPayload,
            "sales_order"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: editActivityLog,
          });
          const { addedEmployeeList, deletedEmployeeList } =
            findDifferenceEmployee(payload, existing);
          if (addedEmployeeList && addedEmployeeList.length > 0) {
            // add related employee
            const addedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "add_related_employee",
              addedEmployeeList,
              "sales_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: addedEmployeeListLog,
            });
          }
          if (deletedEmployeeList && deletedEmployeeList.length > 0) {
            // delete related employee
            const deletedEmployeeListLog = createActivityLogEmployeePayload(
              activityLogPayload,
              "delete_related_employee",
              deletedEmployeeList,
              "sales_order"
            );
            await ActivityLogsService.createActivityLogs({
              createActivityLogInput: deletedEmployeeListLog,
            });
          }
          switch (status) {
            case "draft":
              dispatch(salesOrderActions.loadedSalesOrder(formatSalesOrder));
              enqueueSnackbar("บันทึกร่างสำเร็จ", {
                variant: "success",
              });
              break;
            default:
              dispatch(
                salesOrderActions.loadedSalesOrder({
                  ...formatSalesOrder,
                  render_status: status,
                })
              );
              enqueueSnackbar("แก้ไขใบสั่งขายสำเร็จ", {
                variant: "success",
              });
          }
        }
      }
      if (moment(formatSalesOrder.due_date).unix() > moment().unix()) {
        await GlobalService.updateIsLate();
        dispatch(salesOrderActions.updateSalesOrderIsLate(false));
      }
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบสั่งขายไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบสั่งขายไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const saveSalesInvoice =
  (payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const documentInput = {
        document_id: payload.document_id,
        document_type: "sales_order",
      };
      const updatedSalesOrder = await SalesService.postNextStatus(
        documentInput
      );

      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const createActivityLog = createActivityLogPayload(
        activityLogPayload,
        "sales_order",
        "บันทึกใบแจ้งหนี้"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      const sendToApproveActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "sales_order",
        "approved",
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: sendToApproveActivityLog,
      });

      enqueueSnackbar("บันทึกใบแจ้งหนี้สำเร็จ", {
        variant: "success",
      });

      dispatch(
        salesOrderActions.updateSalesOrderStatus(updatedSalesOrder.status)
      );
    } catch (err) {
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
    }
  };

export const approveSalesOrder =
  (
    documentInput,
    payload,
    user,
    enqueueSnackbar,
    itemList,
    setValue,
    gridRef
  ) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };

      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_order");

      const { approval_progress_list } = await SalesService.postApproveStatus(
        documentInput
      );

      dispatch(
        salesOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          salesOrderActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        dispatch(salesOrderActions.updateSalesOrderStatus("waitApprove"));
        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "sales_order"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "sales_order"
        );

        dispatch(
          salesOrderActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(salesOrderActions.updateSalesOrderStatus("approved"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogPayload,
          "sales_order",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      for (const [index, value] of itemList.entries()) {
        await InventoryService.updateItemQuantity(value.item_document_id, {
          current_committed_sales_qty: {
            increment: value.qty_uom,
          },
        });
        const item = await InventoryService.getItem({
          document_id: value.item_document_id,
        });

        itemList[index] = {
          ...value,
          uom_group: item?.uom_group,
          current_ordered_purchase_qty: item?.current_ordered_purchase_qty,
          current_ordered_manufacture_qty:
            item?.current_ordered_manufacture_qty,
          current_committed_sales_qty: item?.current_committed_sales_qty,
          current_committed_manufacture_qty:
            item?.current_committed_manufacture_qty,
          current_stock_qty: item?.current_stock_qty,
          current_available_qty: item?.current_available_qty,
          purchase_standard_price: item?.purchase_standard_price,
        };
      }
      setValue("item_list", await itemList);
      gridRef.current.api.redrawRows();
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineSalesOrder =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "sales_order",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } = await SalesService.postDeclineStatus(
        documentInput
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_order");

      dispatch(
        salesOrderActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        salesOrderActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );
      dispatch(salesOrderActions.updateSalesOrderStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelSalesOrder =
  (
    documentInput,
    payload,
    user,
    enqueueSnackbar,
    status,
    itemList,
    setValue,
    gridRef
  ) =>
  async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrder"));
    try {
      const activityLogPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogPayload,
        "sales_order",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      if (status === "approved" || status === "finished") {
        for (const [index, value] of itemList.entries()) {
          await InventoryService.updateItemQuantity(value.item_document_id, {
            current_committed_sales_qty: {
              decrement: value.qty_uom,
            },
          });
          const item = await InventoryService.getItem({
            document_id: value.item_document_id,
          });

          itemList[index] = {
            ...value,
            uom_group: item?.uom_group,
            current_ordered_purchase_qty: item?.current_ordered_purchase_qty,
            current_ordered_manufacture_qty:
              item?.current_ordered_manufacture_qty,
            current_committed_sales_qty: item?.current_committed_sales_qty,
            current_committed_manufacture_qty:
              item?.current_committed_manufacture_qty,
            current_stock_qty: item?.current_stock_qty,
            current_available_qty: item?.current_available_qty,
            purchase_standard_price: item?.purchase_standard_price,
          };
        }
        setValue("item_list", await itemList);
        gridRef.current.api.redrawRows();
      }
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(salesOrderActions.updateSalesOrderStatus("cancelled"));
    } catch (err) {
      console.error("error", err);
      dispatch(
        salesOrderActions.rejectedActions({ ...err, name: "salesOrder" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const getNewSalesOrderReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesOrderActions.onLoading("salesOrderReports"));
    try {
      const salesOrderReport = await SalesService.getAllNewSalesOrderReport(
        input
      );
      params.successCallback(salesOrderReport.results, salesOrderReport.count);
      dispatch(salesOrderActions.onLoaded("salesOrderReports"));
    } catch (err) {
      console.error(err);
      dispatch(
        salesOrderActions.rejectedActions({
          ...err,
          name: "salesOrderReports",
        })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };
