import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import DeliveryTripTable from "../../../components/Table/DocumentTable/DeliveryTripTable";
import { getAllDeliveryTripsExportReport } from "../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import { useSnackbar } from "notistack";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import moment from "moment";
import { useForm } from "react-hook-form";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import { dateToUnix } from "../../../utils/date-converter";
import { exportCSVParams } from "../../../utils/filterparams";
import { deliveryTripActions } from "../../../features/Logistic/DeliveryTrip/delivery-trip-slice";

const DeliveryTripReport = () => {
  const { allDeliveryTripsExport, isLoading } = useSelector(
    (state) => state.deliveryTrip
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.report.index"),
      to: "/logistic/report",
    },
    {
      name: "รายงาน" + t("logistic.deliveryTrip.index"),
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    },
  });

  const onBtnExport = useCallback(async () => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");
    const params = exportCSVParams(
      t("logistic.deliveryTrip.index"),
      startDate,
      endDate
    );
    gridRef.current.api.exportDataAsCsv(params);
    enqueueSnackbar("นำออกใบส่งของสำเร็จ", {
      variant: "success",
    });
  }, [enqueueSnackbar, getValues, t]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
    reset({
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(6, "day")),
      dateTo: new Date(moment().endOf("day")),
    });
    dispatch(
      getAllDeliveryTripsExportReport(
        {
          startRow: 0,
          endRow: Math.pow(10, 10),
          filterModel: {
            issue_date: {
              filter: parseInt(
                dateToUnix(new Date(moment().startOf("day").subtract(6, "day")))
              ),
              filterTo: parseInt(dateToUnix(new Date(moment().endOf("day")))),
              filterType: "number",
              type: "inRange",
            },
          },
          sortModel: [],
        },
        enqueueSnackbar
      )
    );
  };

  const getFilteredDeliveryTrip = useCallback(() => {
    let startDate = getValues("date");
    let endDate = getValues("dateTo");

    dispatch(
      getAllDeliveryTripsExportReport(
        {
          startRow: 0,
          endRow: Math.pow(10, 10),
          filterModel: {
            issue_date: {
              filter: parseInt(dateToUnix(startDate)),
              filterTo: parseInt(dateToUnix(endDate)),
              filterType: "number",
              type: "inRange",
            },
          },
          sortModel: [],
        },
        enqueueSnackbar
      )
    );
  }, [dispatch, enqueueSnackbar, getValues]);

  useEffect(() => {
    return () => dispatch(deliveryTripActions.resetAllDeliveryTripsExport());
  }, [dispatch]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          รายงาน{t("logistic.deliveryTrip.index")}
        </Typography>
        <CustomizedButton
          title="นำออกข้อมูล"
          variant="contained"
          onClick={onBtnExport}
        />
      </Box>
      <CustomizedBox>
        <Typography ml={1} mb={2} fontWeight="bold">
          ตัวกรองแสดงผล
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <NewReportDateFilter
                t={t}
                control={control}
                setValue={setValue}
                getValues={getValues}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={getFilteredDeliveryTrip}
                disabled={isLoading.allDeliveryTrips}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} alignSelf="center">
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.allDeliveryTrips}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </CustomizedBox>
      {allDeliveryTripsExport && allDeliveryTripsExport.length > 0 && (
        <DeliveryTripTable
          gridRef={gridRef}
          rowData={allDeliveryTripsExport}
          isClient
          isReport
        />
      )}
    </>
  );
};

export default DeliveryTripReport;
