// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import AbortController from "abort-controller";
import { GraphQLClient } from "graphql-request";
import Cookies from "universal-cookie";
import { CURRENT_VERSION } from "../config/variables";

const cookies = new Cookies();

// const location = useLocation();

// let prevPath = "";
// let currentPath = "";

let endpoint;
let endpoint_report;
let endpoint_queue;
if (process.env.REACT_APP_ENVIRONMENT.trim() === "development") {
  endpoint = "https://erp-demo-api.npr.digital/graphql";
  endpoint_report = "https://erp-demo-api.npr.digital/graphql";
  console.log("running on development");
} else if (process.env.REACT_APP_ENVIRONMENT.trim() === "production") {
  endpoint = "https://erp-demo-api.npr.digital/graphql";
  endpoint_report = "https://erp-demo-api.npr.digital/graphql";
  endpoint_queue = "https://erp-demo-api.npr.digital/graphql";
  console.log("running on production");
}

// const controller = new AbortController();

export const graphQLClient = new GraphQLClient(endpoint, {
  // signal: controller.signal,
  credentials: "include",
  mode: "cors",
});
export const graphQLClientReport = new GraphQLClient(endpoint_report, {
  // signal: controller.signal,
  credentials: "include",
  mode: "cors",
});
export const graphQLClientQueue = new GraphQLClient(endpoint_queue, {
  credentials: "include",
  mode: "cors",
});
// console.log("graphQLClient", graphQLClient);

// const timeoutId = setTimeout(() => {
//   console.log("TIMEOUT CALLED");
//   controller.abort();
//   console.log("timeout");
//   console.log("graphQLClient2", graphQLClient);
// }, 10000);

// const convertGraphQLQueryToJson = (query) => {
//   const ast = parse(query);
//   const queryAsJson = {};

//   ast.definitions.forEach((definition) => {
//     if (definition.kind === "OperationDefinition") {
//       queryAsJson.operationType = definition.operation;
//       queryAsJson.name = definition.name?.value;
//       queryAsJson.selectionSet = definition.selectionSet.selections.map(
//         (selection) => {
//           return {
//             name: selection.name.value,
//             fields: selection.selectionSet?.selections.map(
//               (field) => field.name.value
//             ),
//           };
//         }
//       );
//     }
//     if (definition.kind === "VariableDefinition") {
//       queryAsJson.variables = definition.variableDefinitions.map((variable) => {
//         return {
//           name: variable.variable.name.value,
//           type: variable.type.name?.value || "ComplexType",
//         };
//       });
//     }
//   });

//   return queryAsJson;
// };

const graphqlMiddleware = (client) => async (query, variables) => {
  try {
    const data = await client.request(query, variables);
    return data;
  } catch (error) {
    throw error;
  }
};

export const graphQLClientWithHeader = () => {
  let token = cookies.get("access_token");
  graphQLClient.setHeader("authorization", `Bearer ${token}`);
  graphQLClientReport.setHeader("fe-version", CURRENT_VERSION);
  return {
    ...graphQLClient,
    request: graphqlMiddleware(graphQLClient),
  };
};

export const graphQLClientReportWithHeader = () => {
  let token = cookies.get("access_token");
  graphQLClientReport.setHeader("authorization", `Bearer ${token}`);
  graphQLClientReport.setHeader("fe-version", CURRENT_VERSION);
  return {
    ...graphQLClientReport,
    request: graphqlMiddleware(graphQLClientReport),
  };
};

export const graphQLClientQueueWithHeader = () => {
  let token = cookies.get("access_token");
  graphQLClientQueue.setHeader("authorization", `Bearer ${token}`);
  graphQLClientReport.setHeader("fe-version", CURRENT_VERSION);
  return {
    ...graphQLClientQueue,
    request: graphqlMiddleware(graphQLClientQueue),
  };
};
