import { useRef, useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import AgGrid from "../../../components/Table/AgGrid";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import { contactColumnDefs } from "../../../components/Table/ColumnDefs/Contact";
import ContactService from "../../../services/Contact";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import { exportAsExcel } from "../../../utils/exporter";
import { unixToDateWithFormat } from "../../../utils/date-converter";
import GlobalService from "../../../services/Global";

const ContactReport = () => {
  const gridRef = useRef();
  const filterRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const tab = pathname.split("/")[3];
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});
  const [contactsFilterOptions, setContactsFilterOptions] = useState(null);

  const pageTitle = () => {
    switch (tab) {
      case "vendor":
        return t("contact.contact_type.vendor");
      case "customer":
        return t("contact.contact_type.customer");
      default:
        return t("contact.contact_type.all");
    }
  };

  const breadcrumbs = [
    {
      name: t("contact.index"),
      to: "/contact",
    },
    {
      name: t("report"),
      to: "/contact/report",
    },
    {
      name: "รายงาน" + pageTitle(),
    },
  ];

  filterRef.current = filter;

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    // console.log("results", results);
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    // setTagList(mapTagValue);
    return mapTagValue;
  }, []);

  useEffect(() => {
    switch (tab) {
      case "vendor":
        setFilter({
          is_vendor: { filterType: "boolean", type: "equals", filter: "true" },
        });
        break;
      case "customer":
        setFilter({
          is_customer: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        });
        break;
      default:
        setFilter({});
    }
    if (gridRef.current.api) {
      gridRef.current.api.onFilterChanged();
    }
  }, [gridRef, tab]);

  const exportContactsHandler = async () => {
    try {
      const contactsToExport = await ContactService.getAllContactsAgGridReport({
        ...contactsFilterOptions,
        startRow: 0,
        endRow: 99999,
      });
      const formattedContact = contactsToExport.results
        .map((contact) => {
          delete contact.id;
          delete contact.created_by;
          delete contact.attachment_list;
          delete contact.sales_list;
          delete contact.tag_list;
          return {
            ...contact,
            created_date: unixToDateWithFormat(contact.created_date),
          };
        })
        .filter((contact) => {
          switch (tab) {
            case "customer":
              return contact.is_customer;
            case "vendor":
              return contact.is_vendor;
            default:
              return true;
          }
        });
      const nameExported = () => {
        switch (tab) {
          case "customer":
            return "ลูกค้า";
          case "vendor":
            return "ผู้ขาย";
          default:
            return "ผู้ติดต่อทั้งหมด";
        }
      };
      exportAsExcel(formattedContact, nameExported(), null, t, "contact");
      enqueueSnackbar("นำออกผู้ติดต่อสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("ไม่สามารถนำออกผู้ติดต่อ", {
        variant: "error",
      });
    }
  };

  const datasource = {
    async getRows(params) {
      const request = params.request;
      const agGridOptions = {
        startRow: request.startRow,
        endRow: request.endRow,
        filterModel: {
          ...request.filterModel,
          ...filterRef.current,
          document_id: {
            ...request.filterModel.document_id,
            mode: "insensitive",
          },
          contact_group: request.filterModel.contact_group
            ? {
                filterType: "array",
                type: "hasSome",
                values: request.filterModel.contact_group.values || [],
              }
            : undefined,
          tag_list: request.filterModel.tag_list
            ? {
                filterType: "objectArray",
                type: "some",
                filter: {
                  name: {
                    filterType: "set",
                    values: request.filterModel.tag_list.values,
                  },
                },
              }
            : {},
        },
        sortModel: request.sortModel,
      };
      try {
        const allContacts = await ContactService.getAllContactsAgGridReport(
          agGridOptions,
          params
        );
        setContactsFilterOptions(agGridOptions);
        params.successCallback(allContacts.results, allContacts.count);
      } catch (err) {
        console.error(err);
        params.failCallback();
      }
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const columnDefs = contactColumnDefs(t, getAllTag, true);

  const onFirstDataRendered = () => {
    const instance = gridRef.current.api.getFilterInstance("contact_status");
    instance.setModel({ filterType: "set", values: ["active"] });
    gridRef.current.api.onFilterChanged();
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <CustomizedStatus bgcolor="#C8EDDC">
        <Typography
          variant="button"
          sx={{
            color: "#246527",
          }}
        >
          ใช้งาน
        </Typography>
      </CustomizedStatus>
      <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{pageTitle()}</Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title="นำออกผู้ติดต่อ"
          variant="contained"
          onClick={exportContactsHandler}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          height={649}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
        />
      </Box>
    </>
  );
};

export default ContactReport;
