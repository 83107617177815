import { useSnackbar } from "notistack";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeliveryOrdersModal } from "../../features/Logistic/DeliveryOrder/delivery-order-actions";
import { filterDateWithUnix } from "../../utils/filterparams";
import CheckboxModalTable from "./CheckboxModalTable";
import { deliveryOrderColumnDefs } from "./ColumnDefs/deliveryOrderColumnDefs";
import InventoryService from "../../services/Inventory";
import { useWatch } from "react-hook-form";

const CheckedDeliveryOrderTable = ({
  control,
  setValue,
  setHeaderValue,
  openModal,
  setOpenModal,
  deliveryOrderIds,
  setDeliveryOrderIds,
  deliveryOrderIdsSnapshot,
  setDeliveryOrderIdsSnapshot,
}) => {
  const selectDeliveryOrderGridRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getRowId = useCallback((params) => {
    return params.data.document_id;
  }, []);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.deliveryOrder);

  const delivery_order_list = useWatch({
    control,
    name: "delivery_order_list",
  });
  // const item_list = useWatch({ control, name: "item_list" });

  const datasource = {
    getRows(params) {
      const request = params.request;
      const { filterModel } = request;
      const {
        document_id,
        contact,
        contact_document_id,
        issue_date,
        delivery_date,
        created_by,
        employee_list,
        province,
        address,
        sub_district,
        district,
        postal_code,
        render_status,
      } = filterModel;

      const customerFilterList = [];

      if (province) {
        customerFilterList.push({
          path: ["billing_address", "province"],
          type: province.type,
          filter: province.filter,
        });
      }
      if (address) {
        customerFilterList.push({
          path: ["billing_address", "address"],
          type: address.type,
          filter: address.filter,
        });
      }
      if (sub_district) {
        customerFilterList.push({
          path: ["billing_address", "sub_district"],
          type: sub_district.type,
          filter: sub_district.filter,
        });
      }
      if (district) {
        customerFilterList.push({
          path: ["billing_address", "district"],
          type: district.type,
          filter: district.filter,
        });
      }
      if (postal_code) {
        customerFilterList.push({
          path: ["billing_address", "postal_code"],
          type: postal_code.type,
          filter: postal_code.filter,
        });
      }

      const formatFilterModel = {
        document_id: document_id && {
          ...document_id,
          mode: "insensitive",
        },
        contact: (contact || contact_document_id) && {
          filterType: "object",
          filter: {
            document_id: contact_document_id && {
              ...contact_document_id,
              mode: "insensitive",
            },
            contact_name_1: contact && {
              ...contact,
              mode: "insensitive",
            },
          },
        },
        issue_date: issue_date && filterDateWithUnix(issue_date),
        delivery_date: delivery_date && filterDateWithUnix(delivery_date),
        employee_list: employee_list && {
          filterType: "objectArray",
          type: "some",
          filter: {
            first_name: { ...employee_list, mode: "insensitive" },
          },
        },
        created_by: created_by && {
          filterType: "object",
          filter: {
            first_name: {
              ...created_by,
              mode: "insensitive",
            },
          },
        },
        customer: (province ||
          address ||
          sub_district ||
          district ||
          postal_code) && {
          filterType: "json",
          filterList: customerFilterList,
        },
        render_status: render_status,
      };

      dispatch(
        getAllDeliveryOrdersModal(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: formatFilterModel,
            sortModel: request.sortModel,
          },
          params,
          enqueueSnackbar
        )
      );
    },
  };

  const onGridReady = (params) => {
    const instance = params.api.getFilterInstance("render_status");
    instance.setModel({
      filterType: "set",
      values: ["waitDeliver", "notCompleted", "completed"],
    });
    params.api.onFilterChanged();
    params.api.setServerSideDatasource(datasource);
  };

  const finishDeliveryOrdersSelect = async (data) => {
    // filter out employees not selected in current modal session
    let filteredDeliveryOrder = delivery_order_list.filter((deliveryOrder) =>
      deliveryOrderIds.includes(deliveryOrder.document_id)
    );

    // get current employees id to prevent duplication when appending newly selected employees
    const filteredDeliveryOrderId = filteredDeliveryOrder.map(
      (deliveryOrder) => deliveryOrder.document_id
    );

    data.forEach((deliveryOrder) => {
      if (!filteredDeliveryOrderId.includes(deliveryOrder.document_id)) {
        filteredDeliveryOrder.push(deliveryOrder);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredDeliveryOrder = filteredDeliveryOrder.sort((a, b) => {
      return (
        deliveryOrderIds.indexOf(a.document_id) -
        deliveryOrderIds.indexOf(b.document_id)
      );
    });

    const reference_document_id_list = filteredDeliveryOrder.map(
      (document) => ({
        reference_document_type: "delivery_order",
        reference_document_id: document.document_id,
      })
    );

    let deliveryItemList = [];
    let allItemsDocumentId = [];

    const formatDeliveryOrderList = filteredDeliveryOrder.map(
      (deliveryOrder) => {
        const formatItemList = deliveryOrder.item_list.map((item) => {
          const volumeToM2WithQty = (item.volume * item.qty_delivery) / 1000000;
          const weightWithQty = item.weight * item.qty_delivery;

          const formatItem = {
            uid: item.uid,
            item_document_id: item.item_document_id,
            item_name: item.item_name,
            item_description: item.item_description,
            qty: item.qty_delivery,
            uom: item.delivery_uom.name,
            uom_id: parseInt(item.delivery_uom_id),
            qty_uom: item.qty_uom,
            do_reference_document_id: deliveryOrder.document_id,
            so_reference_document_id: deliveryOrder.reference_document_id,
            item_remark: item.item_remark,
            customer_name: deliveryOrder.contact.contact_name_1,
            volume: item.volume,
            weight: item.weight,
          };

          deliveryItemList.push(formatItem);
          allItemsDocumentId.push(item.item_document_id);
          return {
            ...item,
            volume_with_qty: volumeToM2WithQty,
            weight_with_qty: weightWithQty,
          };
        });

        return {
          ...deliveryOrder,
          item_list: formatItemList,
        };
      }
    );

    const items = await InventoryService.getAllItems({
      findManyInput: {
        where: {
          document_id: {
            in: allItemsDocumentId,
          },
        },
      },
    });

    let formatItemList = [];

    deliveryItemList.forEach((item) => {
      const foundItemIndex = items.findIndex(
        (realItem) => realItem.document_id === item.item_document_id
      );
      formatItemList.push({
        ...item,
        uom_group: items[foundItemIndex]?.uom_group,
        current_ordered_purchase_qty:
          items[foundItemIndex]?.current_ordered_purchase_qty,
        current_ordered_manufacture_qty:
          items[foundItemIndex]?.current_ordered_manufacture_qty,
        current_committed_sales_qty:
          items[foundItemIndex]?.current_committed_sales_qty,
        current_committed_manufacture_qty:
          items[foundItemIndex]?.current_committed_manufacture_qty,
        current_stock_qty: items[foundItemIndex]?.current_stock_qty,
        current_available_qty: items[foundItemIndex]?.current_available_qty,
        is_active: items[foundItemIndex]?.is_active,
      });
    });

    setValue("delivery_order_list", formatDeliveryOrderList);
    setValue("item_list", formatItemList);
    setHeaderValue("reference_document_id", reference_document_id_list);
    setOpenModal(false);
  };

  return (
    <CheckboxModalTable
      isLoading={isLoading.allDeliveryOrdersModal}
      modalTitle="นำเข้าใบส่งของ"
      btnTitle={t("button.add")}
      gridRef={selectDeliveryOrderGridRef}
      height={450}
      columnDefs={deliveryOrderColumnDefs(t, null, false, false, true)}
      rowSelection="multiple"
      onFinishEditing={finishDeliveryOrdersSelect}
      modalIsOpen={openModal}
      closeModal={() => setOpenModal(false)}
      onGridReady={onGridReady}
      selectedIds={deliveryOrderIds}
      setSelectedIds={setDeliveryOrderIds}
      idsSnapshot={deliveryOrderIdsSnapshot}
      setIdsSnapshot={setDeliveryOrderIdsSnapshot}
      getRowId={getRowId}
      disabledSidebar
    />
  );
};

export default CheckedDeliveryOrderTable;
