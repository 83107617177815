import {
  //   Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { formatNumber } from "../../../utils/dataTransformer";

const DeliveryTripTablePdf = ({ itemList }) => {
  const header = [
    {
      label: "No.",
      thaiLabel: "รายการ",
      width: 55,
    },
    {
      label: "Name",
      thaiLabel: "ชื่อสินค้า",
      width: 130,
    },
    {
      label: "Qty",
      thaiLabel: "จำนวน",
      width: 70,
    },
    {
      label: "Customer",
      thaiLabel: "ลูกค้า",
      width: 120,
    },
    {
      label: "DO Ref",
      thaiLabel: "ใบส่งของ",
      width: 100,
    },
    {
      label: "SO Ref",
      thaiLabel: "ใบสั่งขาย",
      width: 100,
    },
    {
      label: "Product Remark",
      thaiLabel: "หมายเหตุสินค้า",
      width: 100,
    },
  ];
  return (
    <TableContainer>
      <Table sx={{ overflow: "scroll" }} aria-label="simple table">
        <TableHead
          sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <TableRow>
            {header.map((header, index) => (
              <TableCell
                align="center"
                key={index}
                width={header.width}
                sx={{
                  px: 0.25,
                  py: 1,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {header.thaiLabel}
                </Typography>
                <Typography fontSize={12} fontWeight={600}>
                  {header.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {itemList.map((item, index) => (
            <TableRow key={item.uid} sx={{ breakInside: "avoid" }}>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{index + 1}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12} fontWeight={600}>
                  {item.item_name}
                </Typography>
                {/* <Divider sx={{ my: 1 }} /> */}
                <Typography fontSize={12} whiteSpace="pre-line">
                  {item.item_description}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{formatNumber(item.qty)}</Typography>
                <Typography fontSize={10} variant="subtitle1">
                  {item.uom}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.customer_name}</Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {item.do_reference_document_id}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>
                  {item.so_reference_document_id}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  py: 1,
                  px: 0.25,
                }}
              >
                <Typography fontSize={12}>{item.item_remark || "-"}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeliveryTripTablePdf;
