import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { validationConversion } from "./validation";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../../../Custom/CustomizedButton";
import { useSelector } from "react-redux";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const ConversionForm = forwardRef(
  (
    {
      control,
      errors,
      setValueForm,
      getValues,
      watchBaseUom,
      watchConversion,
      disabled,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { allUoms } = useSelector((state) => state.uom);

    const { fields, append, replace } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "uom_conversion_list", // unique name for your Field Array
    });

    const allUomConversion = watchConversion?.map(
      (uom) => uom.target_uom?.name
    );

    useEffect(() => {
      const newValue = getValues("uom_conversion_list");
      replace(newValue);
    }, [getValues, replace]);

    const handleAppendData = () => {
      const filterUom = allUoms
        ?.map((uom) => {
          return {
            ...uom,
            id: uom.document_id,
            name: uom.name,
          };
        })
        .filter((uom) => uom.id !== watchBaseUom.name)
        .filter((uom) => !allUomConversion.includes(uom.name));
      append({
        base_uom_rate: 1,
        target_uom_rate: 1,
        base_uom_document_id: watchBaseUom.name,
        target_uom: {
          document_id: filterUom[0].document_id,
          name: filterUom[0].name,
        },
      });
    };

    const mapValueUom = (value) => {
      const mapValue = allUoms.find((uom) => uom.document_id === value);
      if (mapValue) return mapValue.name || "";
      else return value ?? "";
    };

    const headers = [
      {
        label: t("setting.inventory.conversion.quantity"),
        width: 230,
      },
      {
        label: t("setting.inventory.conversion.baseUom"),
        width: 230,
      },
      {
        label: t("setting.inventory.conversion.quantityConversion"),
        width: 230,
      },
      {
        label: t("setting.inventory.conversion.conversionUom"),
        width: 230,
      },
      {
        label: "",
        width: 90,
      },
    ];

    return (
      <>
        {!disabled && (
          <CustomizedButton
            testId="item-add-conversion-btn"
            color="secondary"
            onClick={handleAppendData}
            title={t("button.add")}
            variant="outlined"
            sx={{ my: 1, mr: 2 }}
            disabled={!watchBaseUom}
          />
        )}
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                <TableRow>
                  {headers.map((header) => (
                    <TableCell width={header.width} align="center">
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      <Controller
                        name={`uom_conversion_list[${index}].base_uom_rate`}
                        control={control}
                        render={({ field }) => (
                          <CustomizedTextField
                            testId={`item-conversion-base-rate-text-field-${index}`}
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={Boolean(
                              errors.uom_conversion_list?.[index]?.base_uom_rate
                            )}
                            helperText={
                              errors.uom_conversion_list?.[index]?.base_uom_rate
                                ?.message
                            }
                            disabled={disabled}
                            defaultValue=""
                            {...field}
                            onChange={(e) => {
                              if (e.target.value !== null)
                                setValueForm(
                                  `uom_conversion_list[${index}].base_uom_rate`,
                                  parseFloat(e.target.value)
                                );
                              else
                                setValueForm(
                                  `uom_conversion_list[${index}].base_uom_rate`,
                                  e.target.value
                                );
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <CustomizedComboBox
                        disabled
                        options={
                          allUoms?.map((uom) => {
                            return {
                              id: uom.document_id,
                              value: uom.document_id,
                              label: uom.name,
                            };
                          }) || []
                        }
                        value={watchBaseUom.name}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Controller
                        control={control}
                        name={`uom_conversion_list[${index}].target_uom_rate`}
                        render={({ field }) => (
                          <CustomizedTextField
                            testId={`item-conversion-target-rate-text-field-${index}`}
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            error={Boolean(
                              errors.uom_conversion_list?.[index]
                                ?.target_uom_rate
                            )}
                            helperText={
                              errors.uom_conversion_list?.[index]
                                ?.target_uom_rate?.message
                            }
                            disabled={disabled}
                            {...field}
                            onChange={(e) => {
                              if (e.target.value !== null)
                                setValueForm(
                                  `uom_conversion_list[${index}].target_uom_rate`,
                                  parseFloat(e.target.value)
                                );
                              else
                                setValueForm(
                                  `uom_conversion_list[${index}].target_uom_rate`,
                                  e.target.value
                                );
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Controller
                        name={`uom_conversion_list[${index}].target_uom.document_id`}
                        error={errors}
                        control={control}
                        render={({ field }) => (
                          <CustomizedComboBox
                            testId={`item-conversion-target-uom-field-${index}`}
                            options={allUoms
                              ?.map((uom) => {
                                return {
                                  id: uom.document_id,
                                  value: uom.document_id,
                                  label: uom.name,
                                  name: uom.name,
                                };
                              })
                              .filter((uom) => uom.id !== watchBaseUom.name)
                              .filter(
                                (uom) => !allUomConversion.includes(uom.name)
                              )}
                            error={Boolean(
                              errors.uom_conversion_list?.[index]?.target_uom
                                ?.document_id
                            )}
                            helperText={
                              errors.uom_conversion_list?.[index]?.target_uom
                                ?.document_id?.message
                            }
                            {...field}
                            disabled={disabled}
                            value={mapValueUom(field.value)}
                            onChange={(_, newValue) => {
                              field.onChange(newValue ? newValue.id : "");
                              setValueForm(
                                `uom_conversion_list[${index}].target_uom.name`,
                                newValue.name
                              );
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        data-test-id={`item-conversion-delete-btn-${index}`}
                        onClick={() => {
                          const newValue = fields.filter(
                            (_, indexF) => indexF !== index
                          );
                          replace(newValue);
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  }
);

export default ConversionForm;
