import { v4 as uuidv4 } from "uuid";
import { dateToUnix } from "./date-converter";
import { uploadFileToS3 } from "./s3";
import { positionType } from "./userInfo";

export const mapObjectToEnum = (object) => {
  let permissions = [];
  for (const [key1, value1] of Object.entries(object)) {
    for (const [key2, value2] of Object.entries(value1)) {
      for (const [key3, value3] of Object.entries(value2)) {
        if (typeof value3 === "string" && value3 !== "CLOSE") {
          permissions.push(`${key1}__${key2}__${value3}`.toUpperCase());
        } else if (value3 === true) {
          permissions.push(`${key1}__${key2}__${key3}`.toUpperCase());
        }
      }
    }
  }
  return permissions;
};

export const filteredRow = (initialrows, priviledgeObject, resource) => {
  if (initialrows && priviledgeObject && resource) {
    // let deDuplicatedFilteredRows = [];
    // for (const [, priviledgeList] of Object.entries(priviledgeObject)) {
    //   const filteredPriviledgesBySource = priviledgeList.filter(
    //     (priviledge) => priviledge.split("__")[0] === resource
    //   );
    //   const extractedFunctions = filteredPriviledgesBySource.map(
    //     (priviledge) => priviledge.split("__")[1]
    //   );
    //   if (extractedFunctions !== []) {
    //     initialrows
    //       .filter((row) => extractedFunctions.includes(row.functionName))
    //       .forEach((row) => {
    //         if (
    //           deDuplicatedFilteredRows.some(
    //             (item) => item.functionName === row.functionName
    //           ) === false
    //         ) {
    //           deDuplicatedFilteredRows.push(row);
    //         }
    //       });
    //   }
    // }
    // return deDuplicatedFilteredRows;
  }
  return initialrows;
};

export const formatPriviledgePayload = (priviledge) => {
  let formattedPriviledge = {};
  const splittedPriviledge = priviledge.split("__");

  formattedPriviledge.RESOURCE = splittedPriviledge[0];
  formattedPriviledge.FUNCTION = splittedPriviledge[1];
  formattedPriviledge.PRIVILEDGE = splittedPriviledge[2];

  return formattedPriviledge;
};

export const mapRolesToObject = (allRoles, selectedRoleNames) => {
  if (allRoles && selectedRoleNames) {
    let priviledgeList = {};
    const filteredRoles = allRoles.filter((role) =>
      selectedRoleNames.includes(role.name)
    );

    for (const role of filteredRoles) {
      priviledgeList[role.name] = role.permission_list;
    }
    return priviledgeList;
  }
  return {};
};

export const extractRoleOptions = (allRoles) =>
  allRoles.map(({ document_id, name }) => ({
    id: document_id,
    label: name,
    value: document_id,
  }));

export const mapActivityToIconName = (activityType, attachment_list) => {
  if (activityType === "comment") {
    if (attachment_list?.length !== 0 && attachment_list?.length !== 0)
      return "mixedFiles";
  }
  return activityType;
};

export const filterPositionByDepartment = (department, setPosition) => {
  switch (department) {
    case "management":
      setPosition([
        {
          id: uuidv4(),
          label: "กรรมการผู้จัดการ",
          value: "กรรมการผู้จัดการ",
        },
        {
          id: uuidv4(),
          label: "ผู้บริหาร",
          value: "ผู้บริหาร",
        },
      ]);
      break;
    case "inventory":
      setPosition([
        {
          id: uuidv4(),
          label: "พนักงานคลังสโตร์",
          value: "พนักงานคลังสโตร์",
        },
        {
          id: uuidv4(),
          label: "ธุรการคลัง",
          value: "ธุรการคลัง",
        },
      ]);
      break;
    case "sales":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายขาย",
          value: "ผู้จัดการฝ่ายขาย",
        },
        {
          id: uuidv4(),
          label: "เซลล์",
          value: "เซลล์",
        },
        {
          id: uuidv4(),
          label: "เซลล์แอดมิน",
          value: "เซลล์แอดมิน",
        },
      ]);
      break;
    case "purchase":
      setPosition([
        {
          id: uuidv4(),
          label: "หัวหน้าจัดซื้อ",
          value: "หัวหน้าจัดซื้อ",
        },
        {
          id: uuidv4(),
          label: "จัดซื้อ",
          value: "จัดซื้อ",
        },
      ]);
      break;
    case "account":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายบัญชี",
          value: "ผู้จัดการฝ่ายบัญชี",
        },
        {
          id: uuidv4(),
          label: "หัวหน้าฝ่ายสินเชื่อ",
          value: "หัวหน้าฝ่ายสินเชื่อ",
        },
        {
          id: uuidv4(),
          label: "บัญชีลูกหนี้",
          value: "บัญชีลูกหนี้",
        },
        {
          id: uuidv4(),
          label: "บัญชีคลัง",
          value: "บัญชีคลัง",
        },
        {
          id: uuidv4(),
          label: "บัญชี",
          value: "บัญชี",
        },
      ]);
      break;
    case "logistic":
      setPosition([
        {
          id: uuidv4(),
          label: "หัวหน้าจัดส่ง",
          value: "หัวหน้าจัดส่ง",
        },
      ]);
      break;
    case "manufacture":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้จัดการฝ่ายผลิต",
          value: "ผู้จัดการฝ่ายผลิต",
        },
        {
          id: uuidv4(),
          label: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
          value: "ผู้จัดการผลิตฝ่ายวัตถุดิบ",
        },
        {
          id: uuidv4(),
          label: "พนักงานฝ่ายผลิต",
          value: "พนักงานฝ่ายผลิต",
        },
      ]);
      break;
    case "admin":
      setPosition([
        {
          id: uuidv4(),
          label: "ผู้ดูแลระบบ",
          value: "ผู้ดูแลระบบ",
        },
      ]);
      break;
    default:
      setPosition(positionType);
      break;
  }
};

export const departmentEngToThai = (department) => {
  switch (department) {
    case "management":
      return "บริหาร";
    case "inventory":
      return "คลังสินค้า";
    case "sales":
      return "รายรับ";
    case "purchase":
      return "จัดซื้อ";
    case "account":
      return "บัญชี";
    case "logistic":
      return "ขนส่ง";
    case "manufacture":
      return "ผลิต";
    case "admin":
      return "ดูแลระบบ";
    default:
      return department || "";
  }
};

export const departmentThaiToEnum = (department) => {
  switch (department) {
    case "บริหาร":
      return "management";
    case "คลังสินค้า":
      return "inventory";
    case "รายรับ":
      return "sales";
    case "จัดซื้อ":
      return "purchase";
    case "บัญชี":
      return "account";
    case "ขนส่ง":
      return "logistic";
    case "ผลิต":
      return "manufacture";
    case "ดูแลระบบ":
      return "admin";
    default:
      return department;
  }
};

export const formatVatOption = (value) => {
  if (value === "NO_VAT") return "ไม่มี";
  else if (value === "VAT_0") return "0 %";
  else if (value === "VAT_7") return "7 %";
  else if (value === "0") return "0 %";
  else if (value === "7") return "7 %";
  else return value;
};

export const formatVatOptionForCRUD = (value) => {
  if (value === "ไม่มี") return "ไม่มี";
  else if (value === "0 %") return "0";
  else if (value === "7 %") return "7";
  else return value;
};

export const formatUpdateItemPayload = async (data, allUoms, user, create) => {
  const serializedData = Object.assign({}, data);
  const formatList = [
    "base_uom",
    "purchase_uom",
    "deliver_uom",
    "sales_uom",
    "weight_uom",
    "width_uom",
    "length_uom",
    "thickness_uom",
    "height_uom",
    "volume_uom",
    "package_weight_uom",
    "package_height_uom",
    "package_length_uom",
    "package_width_uom",
  ];

  // loop formating various Uoms to return only id
  for (const [key, value] of Object.entries(serializedData)) {
    if (formatList.includes(key)) {
      serializedData[key] = allUoms.find((uom) => {
        return uom?.name === value?.name;
      })?.document_id;
    }
  }

  //loop for upload pic
  for (const [index, value] of serializedData.attachment_list.entries()) {
    if (value && !value.url && typeof value === "object") {
      const { Location } = await uploadFileToS3(
        value,
        "user",
        user.document_id
      );
      serializedData.attachment_list[index].url = Location;
    }
  }

  serializedData.attachment_list = serializedData.attachment_list.map(
    (file) => {
      return {
        name: file.name,
        url: file.url,
      };
    }
  );

  const uomCM = allUoms.find(
    (uom) => uom.document_id === "เซนติเมตร"
  ).document_id;
  const uomKG = allUoms.find(
    (uom) => uom.document_id === "กิโลกรัม"
  ).document_id;

  serializedData.tag_list = serializedData.tag_list.map((tag) => {
    return tag.name;
  });
  // ================= dimention uom=================
  serializedData.volume =
    (parseFloat(serializedData.width) / 100) *
    (parseFloat(serializedData.length) / 100) *
    (parseFloat(serializedData.height) / 100);
  serializedData.volume_uom_document_id = "ลูกบาศก์เมตร";
  serializedData.length_uom_document_id = serializedData.length_uom;
  serializedData.width_uom_document_id = serializedData.width_uom;
  serializedData.height_uom_document_id = serializedData.height_uom;
  serializedData.weight_uom_document_id = serializedData.weight_uom;
  serializedData.thickness_uom_document_id = serializedData.thickness_uom;
  serializedData.package_length_uom_document_id = uomCM;
  serializedData.package_width_uom_document_id = uomCM;
  serializedData.package_height_uom_document_id = uomCM;
  serializedData.package_weight_uom_document_id = uomKG;
  //===================================================

  // ================= value to float =================
  serializedData.thickness = parseFloat(serializedData.thickness);

  serializedData.sales_standard_price = parseFloat(
    serializedData.sales_standard_price
  );
  serializedData.purchase_standard_price = parseFloat(
    serializedData.purchase_standard_price
  );
  serializedData.purchase_minimum_qty = parseFloat(
    serializedData.purchase_minimum_qty
  );
  serializedData.sales_maximum_discount = parseFloat(
    serializedData.sales_maximum_discount
  );

  //===================================================

  // const input = {
  //   name: serializedData.document_id,
  //   description: "",
  //   document_id: serializedData.document_id,
  //   base_uom_document_id: serializedData.base_uom,
  //   uom_conversion_list: [],
  // };
  // dispatch(createUomGroup({ createInput: input }));

  // ================= warehouse uom =================
  const newUomGroup = {
    name: serializedData.document_id,
    document_id: serializedData.document_id,
    uom_conversion_list:
      serializedData.uom_conversion_list &&
      serializedData.uom_conversion_list.length > 0
        ? serializedData.uom_conversion_list.map(
            ({ base_uom_rate, target_uom, target_uom_rate }) => {
              let target_uom_document_id = null;
              if (target_uom) target_uom_document_id = target_uom.document_id;
              return {
                base_uom_rate,
                target_uom_document_id,
                target_uom_rate,
              };
            }
          )
        : [],
  };
  serializedData.uom_group_document_id =
    serializedData.uom_group?.document_id ?? serializedData.document_id;
  serializedData.base_uom_document_id = serializedData.base_uom;
  serializedData.sales_uom_document_id = serializedData.sales_uom;
  serializedData.purchase_uom_document_id = serializedData.purchase_uom;
  serializedData.deliver_uom_document_id = serializedData.deliver_uom;
  // ===================================================
  if (
    !serializedData.is_active ||
    serializedData.is_active === "false" ||
    serializedData.is_active === "true"
  )
    serializedData.is_active = serializedData.is_active === "true" && true;

  serializedData.sales_vat_type = formatVatOptionForCRUD(data.sales_vat_type);
  serializedData.purchase_vat_type = formatVatOptionForCRUD(
    data.purchase_vat_type
  );
  serializedData.main_bom_name = serializedData.main_bom?.name;
  if (
    serializedData.main_bom_name === "" ||
    serializedData.main_bom_name === undefined
  )
    serializedData.main_bom_name = null;
  // serializedData.mrp_default_method = "no_setting";
  serializedData.manufacture_minimum_qty = parseFloat(
    serializedData.manufacture_minimum_qty
  );
  // ================= attribute_list  =================
  serializedData.attribute_list = serializedData.attribute_list.map(
    (attribute) => {
      return {
        attribute_name: attribute.name,
        attribute_value: attribute.attribute_value,
      };
    }
  );
  // ===================================================

  //delete not ready field (have to change)
  delete serializedData.id;
  delete serializedData.img_url;
  delete serializedData.vendor_item_id;
  delete serializedData.item_group_sub_level_1;
  if (!serializedData.item_group_sub_level_2_document_id)
    delete serializedData.item_group_sub_level_2_document_id;
  if (!serializedData.item_group_sub_level_3_document_id)
    delete serializedData.item_group_sub_level_3_document_id;
  delete serializedData.sales_uom;
  delete serializedData.base_uom;
  delete serializedData.purchase_uom;
  delete serializedData.deliver_uom;
  delete serializedData.volume_uom;
  delete serializedData.length_uom;
  delete serializedData.width_uom;
  delete serializedData.height_uom;
  delete serializedData.weight_uom;
  delete serializedData.package_length_uom;
  delete serializedData.package_width_uom;
  delete serializedData.package_height_uom;
  delete serializedData.package_weight_uom;
  delete serializedData.thickness_uom;
  delete serializedData.created_date;
  delete serializedData.created_by;
  // delete serializedData.item_group_sub_level;
  delete serializedData.item_group_sub_level_2;
  delete serializedData.item_group_sub_level_3;
  delete serializedData.item_group;
  // delete serializedData.base_uom_document_id;
  // delete serializedData.uom_group;
  delete serializedData.uom_group_document_id;
  delete serializedData.uom_conversion_list;
  // =================================================== will be in BE but not this time
  // delete serializedData.mrp_default_method;
  // delete serializedData.manufacture_type;
  // delete serializedData.manufacture_minimum_qty;
  // delete serializedData.main_bom_name;
  delete serializedData.main_bom;
  // ===================================================
  if (serializedData.tag_list.length === 0) delete serializedData.tag_list;
  if (create && serializedData.attribute_list.length === 0)
    delete serializedData.attribute_list;
  // delete serializedData.attachment_list;
  return { ...serializedData, uom_group: newUomGroup };
};

export const formatGRPayload = async (data, create) => {
  const serializedData = await Object.assign({}, data);

  serializedData.document_date =
    serializedData.document_date !== ""
      ? dateToUnix(serializedData.document_date)
      : dateToUnix(new Date());

  // const serialNumber = `${source_warehouse}#${documentId}#${padSNNumber}`;
  // serializedData.created_date = dateToUnix(new Date());
  if (create) {
    serializedData.goods_receive_list = serializedData.goods_receive_list.map(
      (list) => {
        const defaultStockEntry = {
          source_warehouse_document_id:
            serializedData.source_warehouse_document_id,
          posted_quantity: parseFloat(list.posted_value),
          posted_value: list.posted_value,
          posted_date: serializedData.document_date,
          uom_document_id: list.stock_entry_list.uom.document_id,
          item_document_id: list.stock_entry_list.item.document_id,
          creator_document_id: list.stock_entry_list.created_by.document_id,
        };
        return {
          posted_quantity: list.stock_entry_list.trace_entry_list.reduce(
            (prev, curr) => parseFloat(prev) + parseFloat(curr.posted_quantity),
            0
          ),
          initial_quantity: parseFloat(list.initial_quantity),
          posted_value: list.posted_value,
          stock_entry_list: {
            ...defaultStockEntry,
            trace_entry_list: list.stock_entry_list.trace_entry_list.map(
              (teList) => {
                const serialNumber = `${serializedData.source_warehouse_document_id}#${serializedData.document_id}#${teList.padSNNumber}`;
                const BatchNumber = `${teList.item.document_id}#${serialNumber}#${teList.posted_quantity}`;
                const defaultValue = {
                  posted_date: dateToUnix(new Date()),
                  posted_value: teList.posted_value ?? 0,
                  posted_quantity: parseFloat(teList.posted_quantity),
                  serial_number: teList.serial_number ?? serialNumber,
                  batch_number: teList.batch_number ?? BatchNumber,
                  item_uid: list.stock_entry_list.item_uid
                    ? list.stock_entry_list.item_uid
                    : teList.item_uid,
                  item_document_id: list.stock_entry_list.item.document_id,
                  previous_quantity: 0,
                  uom_document_id: list.stock_entry_list.uom
                    ? list.stock_entry_list.uom.document_id
                    : list.stock_entry_list.item.base_uom.document_id,
                  creator_document_id:
                    list.stock_entry_list.created_by.document_id,
                  is_scanned: teList.is_scanned ?? false,
                  scanned_date:
                    typeof teList.scanned_date === "string"
                      ? parseInt(teList.scanned_date)
                      : parseInt(dateToUnix(teList.scanned_date)),
                  scanned_by_document_id: teList.scanned_by
                    ? teList.scanned_by.document_id
                    : null,
                };
                return defaultValue;
              }
            ),
          },
        };
      }
    );
  } else if (!create) {
    serializedData.goods_receive_list = serializedData.goods_receive_list.map(
      (list) => {
        const defaultStockEntry = {
          source_warehouse_document_id:
            serializedData.source_warehouse_document_id,
          posted_quantity: list.stock_entry_list.trace_entry_list.reduce(
            (prev, curr) => {
              if (curr.is_scanned)
                return parseFloat(prev) + parseFloat(curr.posted_quantity);
              else return parseFloat(prev);
            },
            0
          ),
          posted_value: list.posted_value,
          posted_date: serializedData.document_date,
          uom_document_id: list.stock_entry_list.uom.document_id,
          item_document_id: list.stock_entry_list.item.document_id,
          creator_document_id: list.stock_entry_list.created_by.document_id,
        };

        //delete data for call api
        delete list.stock_entry_list.document_id;

        const { id, already_posted_quantity, ...otherList } = list;

        return {
          ...otherList,
          stock_entry_list: {
            ...defaultStockEntry,
            trace_entry_list: list.stock_entry_list.trace_entry_list.map(
              (teList) => {
                return {
                  source_bin_location_document_id:
                    teList.source_bin_location_document_id,
                  destination_bin_location_document_id:
                    teList.destination_bin_location_document_id ?? null,
                  is_scanned: teList.is_scanned,
                  posted_date: dateToUnix(new Date()),
                  scanned_date:
                    typeof teList.scanned_date === "string"
                      ? parseInt(teList.scanned_date)
                      : parseInt(dateToUnix(teList.scanned_date)),
                  posted_quantity: parseFloat(teList.posted_quantity),
                  previous_quantity: teList.previous_quantity || 0,
                  item_uid: teList.item_uid,
                  posted_value: teList.posted_value,
                  serial_number: teList.serial_number,
                  batch_number: teList.batch_number,
                  item_document_id: list.stock_entry_list.item.document_id,
                  uom_document_id: list.stock_entry_list.uom.document_id,
                  creator_document_id:
                    list.stock_entry_list.created_by.document_id,
                  scanned_by_document_id: teList.scanned_by
                    ? teList.scanned_by.document_id
                    : null,
                };
              }
            ),
          },
        };
      }
    );
  }

  if (!create) {
    delete serializedData.id;
    delete serializedData.status;
    delete serializedData.created_by_document_id;
    delete serializedData.created_by;
  }
  //delete unnecessary date
  delete serializedData.source_warehouse;
  delete serializedData.created_date;

  return serializedData;
};

export const formatGRReturnPayload = async (data) => {
  const serializedData = await Object.assign({}, data);

  serializedData.lot = serializedData.lot || "";
  serializedData.document_date =
    serializedData.document_date !== ""
      ? dateToUnix(serializedData.document_date)
      : dateToUnix(new Date());

  // const serialNumber = `${source_warehouse}#${documentId}#${padSNNumber}`;
  // serializedData.created_date = dateToUnix(new Date());

  serializedData.goods_receive_list = serializedData.goods_receive_list.map(
    (list) => {
      const defaultStockEntry = {
        source_warehouse_document_id:
          serializedData.source_warehouse_document_id,
        posted_quantity: list.stock_entry_list.trace_entry_list.reduce(
          (prev, curr) => parseFloat(prev) + parseFloat(curr.posted_quantity),
          0
        ),
        posted_value: list.posted_value,
        posted_date: serializedData.document_date,
        uom_document_id: list.stock_entry_list.uom.document_id,
        item_document_id: list.stock_entry_list.item.document_id,
        creator_document_id: list.stock_entry_list.created_by.document_id,
      };
      return {
        posted_quantity: list.stock_entry_list.trace_entry_list.reduce(
          (prev, curr) => parseFloat(prev) + parseFloat(curr.posted_quantity),
          0
        ),
        initial_quantity: parseFloat(list.initial_quantity),
        posted_value: list.posted_value,
        stock_entry_list: {
          ...defaultStockEntry,
          trace_entry_list: list.stock_entry_list.trace_entry_list.map(
            (teList) => {
              const defaultValue = {
                posted_date: dateToUnix(new Date()),
                posted_value: 0,
                posted_quantity: parseFloat(teList.posted_quantity),
                serial_number: teList.serial_number,
                batch_number: teList.batch_number,
                item_document_id: list.stock_entry_list.item.document_id,
                source_bin_location_document_id:
                  teList.source_bin_location_document_id,
                // destination_bin_location_document_id:
                //   teList.destination_bin_location.document_id,
                scanned_date:
                  typeof teList.scanned_date === "string"
                    ? parseInt(teList.scanned_date)
                    : parseInt(dateToUnix(teList.scanned_date)),
                scanned_by_document_id: teList.scanned_by
                  ? teList.scanned_by.document_id
                  : null,
                item_uid: list.stock_entry_list.item_uid,
                is_scanned: teList.is_scanned,
                previous_quantity: teList.previous_quantity || 0,
                uom_document_id: list.stock_entry_list.uom
                  ? list.stock_entry_list.uom.document_id
                  : list.stock_entry_list.item.base_uom.document_id,
                creator_document_id:
                  list.stock_entry_list.created_by.document_id,
              };
              return defaultValue;
            }
          ),
        },
      };
    }
  );

  //delete unnecessary date
  delete serializedData.source_warehouse;
  delete serializedData.created_date;

  return serializedData;
};

export const formatGIPayload = async (data) => {
  const serializedData = await Object.assign({}, data);

  if (serializedData.document_date !== "") {
    serializedData.document_date = dateToUnix(serializedData.document_date);
  } else {
    serializedData.document_date = dateToUnix(new Date());
  }

  // serializedData.created_date = dateToUnix(new Date()); else if (!create) {
  serializedData.goods_issue_list = serializedData.goods_issue_list.map(
    (list) => {
      const total_posted_quantity =
        list.stock_entry_list.trace_entry_list.reduce((prev, curr) => {
          if (curr.is_scanned)
            return parseFloat(prev) + parseFloat(curr.posted_quantity);
          else return parseFloat(prev);
        }, 0);

      const defaultStockEntry = {
        source_warehouse_document_id:
          serializedData.source_warehouse_document_id,
        posted_quantity: total_posted_quantity,
        posted_value: list.posted_value,
        posted_date: serializedData.document_date,
        uom_document_id: list.stock_entry_list.uom.document_id,
        item_document_id: list.stock_entry_list.item.document_id,
        creator_document_id: list.stock_entry_list.created_by.document_id,
      };

      return {
        posted_quantity: total_posted_quantity,
        initial_quantity: parseFloat(list.initial_quantity),
        posted_value: list.posted_value,
        stock_entry_list: {
          ...defaultStockEntry,
          trace_entry_list: list.stock_entry_list.trace_entry_list.map(
            (teList) => {
              const defaultValue = {
                posted_date: dateToUnix(new Date()),
                posted_value: 0,
                posted_quantity: parseFloat(teList.posted_quantity),
                serial_number: teList.serial_number,
                batch_number: teList.batch_number,
                item_document_id: list.stock_entry_list.item.document_id,
                uom_document_id: list.stock_entry_list.uom.document_id,
                item_uid:
                  list.stock_entry_list.item_uid ||
                  list.stock_entry_list.item.uid,
                creator_document_id:
                  list.stock_entry_list.created_by.document_id,
                is_scanned: teList.is_scanned,
                previous_quantity: teList.previous_quantity || 0,
                scanned_date: parseInt(dateToUnix(teList.scanned_date)),
                scanned_by_document_id: teList.scanned_by
                  ? teList.scanned_by.document_id
                  : null,
              };
              return {
                ...defaultValue,
                source_bin_location_document_id:
                  teList.source_bin_location_document_id,
              };
            }
          ),
        },
      };
    }
  );

  //delete unnecessary date
  delete serializedData.source_warehouse;
  delete serializedData.created_date;
  delete serializedData.lot;

  return serializedData;
};

export const formatGTPayload = async (data) => {
  const serializedData = await Object.assign({}, data);

  if (serializedData.document_date !== "") {
    serializedData.document_date = dateToUnix(serializedData.document_date);
  } else {
    serializedData.document_date = dateToUnix(new Date());
  }

  // serializedData.created_date = dateToUnix(new Date()); else if (!create) {
  serializedData.goods_transfer_list = serializedData.goods_transfer_list.map(
    (list) => {
      const total_posted_quantity =
        list.stock_entry_list.trace_entry_list.reduce((prev, curr) => {
          if (curr.is_scanned)
            return parseFloat(prev) + parseFloat(curr.posted_quantity);
          else return parseFloat(prev);
        }, 0);

      const defaultStockEntry = {
        source_warehouse_document_id:
          serializedData.source_warehouse_document_id,
        destination_warehouse_document_id:
          serializedData.destination_warehouse_document_id,
        posted_quantity: total_posted_quantity,
        posted_value: list.posted_value,
        posted_date: serializedData.document_date,
        uom_document_id: list.stock_entry_list.uom.document_id,
        item_document_id: list.stock_entry_list.item.document_id,
        creator_document_id: list.stock_entry_list.created_by.document_id,
      };

      return {
        posted_quantity: total_posted_quantity,
        initial_quantity: parseFloat(list.initial_quantity),
        posted_value: list.posted_value,
        stock_entry_list: {
          ...defaultStockEntry,
          trace_entry_list: list.stock_entry_list.trace_entry_list.map(
            (teList) => {
              const defaultValue = {
                posted_date: dateToUnix(new Date()),
                posted_value: 0,
                posted_quantity: parseFloat(teList.posted_quantity),
                serial_number: teList.serial_number,
                batch_number: teList.batch_number,
                item_document_id: list.stock_entry_list.item.document_id,
                uom_document_id: list.stock_entry_list.uom.document_id,
                creator_document_id:
                  list.stock_entry_list.created_by.document_id,
                is_scanned: teList.is_scanned,
                scanned_date: parseInt(dateToUnix(teList.scanned_date)),
                previous_quantity: teList.current_quantity,
                scanned_by_document_id: teList.scanned_by
                  ? teList.scanned_by.document_id
                  : null,
              };
              return {
                ...defaultValue,
                source_bin_location_document_id:
                  teList.source_bin_location_document_id,
                destination_bin_location_document_id:
                  teList.destination_bin_location.document_id,
              };
            }
          ),
        },
      };
    }
  );

  //delete unnecessary date
  delete serializedData.source_warehouse;
  delete serializedData.created_date;
  delete serializedData.lot;

  return serializedData;
};

export const formatGAPayload = async (data) => {
  const serializedData = await Object.assign({}, data);

  if (serializedData.document_date !== "") {
    serializedData.document_date = dateToUnix(serializedData.document_date);
  } else {
    serializedData.document_date = dateToUnix(new Date());
  }

  // serializedData.created_date = dateToUnix(new Date()); else if (!create) {
  serializedData.goods_adjustment_list =
    serializedData.goods_adjustment_list.map((list) => {
      const total_posted_quantity =
        list.stock_entry_list.trace_entry_list.reduce((prev, curr) => {
          if (curr.is_scanned)
            return parseFloat(prev) + parseFloat(curr.posted_quantity);
          else return parseFloat(prev);
        }, 0);
      const defaultStockEntry = {
        source_warehouse_document_id:
          serializedData.source_warehouse_document_id,
        posted_quantity: total_posted_quantity,
        posted_value: list.posted_value,
        posted_date: serializedData.document_date,
        uom_document_id: list.stock_entry_list.uom.document_id,
        item_document_id: list.stock_entry_list.item.document_id,
        creator_document_id: list.stock_entry_list.created_by.document_id,
        entry_type: total_posted_quantity > 0 ? "add" : "deduct",
      };

      return {
        posted_quantity: total_posted_quantity,
        initial_quantity: parseFloat(list.initial_quantity),
        posted_value: list.posted_value,
        stock_entry_list: {
          ...defaultStockEntry,
          trace_entry_list: list.stock_entry_list.trace_entry_list.map(
            (teList) => {
              const defaultValue = {
                posted_date: dateToUnix(new Date()),
                posted_value: 0,
                posted_quantity: parseFloat(teList.posted_quantity),
                serial_number: teList.serial_number,
                batch_number: teList.batch_number,
                item_document_id: list.stock_entry_list.item.document_id,
                uom_document_id: list.stock_entry_list.uom.document_id,
                creator_document_id:
                  list.stock_entry_list.created_by.document_id,
                is_scanned: teList.is_scanned,
                previous_quantity: teList.current_quantity,
                scanned_date: parseInt(dateToUnix(teList.scanned_date)),
                scanned_by_document_id: teList.scanned_by
                  ? teList.scanned_by.document_id
                  : null,
              };
              return {
                ...defaultValue,
                source_bin_location_document_id:
                  teList.source_bin_location_document_id,
              };
            }
          ),
        },
      };
    });

  //delete unnecessary date
  delete serializedData.source_warehouse;
  delete serializedData.created_date;
  delete serializedData.lot;

  return serializedData;
};

export const filterUom = (allUoms, selectedUom) => {
  return allUoms
    .filter((uom) => selectedUom.includes(uom.name))
    .map((uom) => ({ ...uom, label: uom.name }));
};

export const extractMentionData = (message) => {
  const idRegex = /[^(]+(?=\))/g;
  return message.match(idRegex);
};

export const formatComment = (message) => {
  const idRegex = /[^(]+(?=\))/g;
  const nameRegex = /[^[]+(?=\])/g;
  let mentionList = [];

  const nameList = message.match(nameRegex);
  const idList = message.match(idRegex);

  if (nameList && idList) {
    nameList.forEach((name, index) => {
      mentionList.push({ name: name, id: idList[index] });
    });

    //replace each mention with anchor tag
    mentionList.forEach((mention) => {
      message = message.replace(
        `@[${mention.name}](${mention.id})`,
        ` <a href="/user/account/${mention.id}" target="_blank" class="mention">
          @${mention.name}
        </a>`
      );
    });
  }
  return message;
};

export const entryTypeToThai = (input) => {
  if (input === "transfer") return "โอนย้าย";
  else if (input === "receive") return "นำเข้า";
  else if (input === "issue") return "นำออก";
  else if (input === "adjustment") return "ปรับปรุง";
  else if (input === "deduct") return "ลด";
  else if (input === "add") return "เพิ่ม";
  else return "";
};

export const formatNumberWithValidate = (value) => {
  return typeof value === "number"
    ? formatNumber(value)
    : formatNumber(parseInt(value));
};

export const formatNumber = (number) => {
  return (Math.round((number + Number.EPSILON) * 100) / 100)?.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
};

export const formatPriceNumber = (number) => {
  return (
    Math.round((number + Number.EPSILON) * 10000) / 10000
  )?.toLocaleString(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
};

export const roundDecimal = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const calcSummary = (
  vatType,
  itemList,
  shippingCost,
  additionalDiscount,
  type,
  withholdingTaxType
) => {
  let summary = {
    sub_total: 0,
    pre_vat_amount: 0,
    vat_exempted_amount: 0,
    vat_0_amount: 0,
    vat_7_amount: 0,
    vat_amount: 0,
    net_amount: 0,
    withholding_tax_amount: 0,
    total_amount: 0,
  };

  const total_pre_vat_amount = itemList.reduce(
    (acc, item) => acc + item.pre_vat_amount,
    0
  );

  if (type === "di") {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) => acc + (item?.pre_vat_amount || 0),
      0
    );
  } else if (type === "rt") {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) => acc + (item?.payment_amount || 0),
      0
    );
  } else if (type === "sr" || type === "rs") {
    summary.pre_vat_amount = itemList.reduce(
      (acc, item) =>
        acc +
        ((item?.qty_return || 0) * (item?.price_per_unit || 0) -
          (item?.discount_amount || 0)),
      0
    );
  } else {
    summary.pre_vat_amount = total_pre_vat_amount;
  }

  const sub_total = itemList.reduce((acc, item) => {
    const item_additional_discount =
      parseFloat(additionalDiscount) *
        (item.pre_vat_amount / total_pre_vat_amount) || 0;

    return acc + (item.pre_vat_amount - item_additional_discount);
  }, 0);

  summary.sub_total = sub_total;

  summary.vat_exempted_amount = itemList
    .filter((item) => item?.vat_type === "ไม่มี")
    .reduce((acc, item) => {
      const item_additional_discount =
        parseFloat(additionalDiscount) *
          (item.pre_vat_amount / total_pre_vat_amount) || 0;
      return acc + (item.pre_vat_amount - item_additional_discount);
    }, 0);

  summary.vat_0_amount = itemList
    .filter((item) => item?.vat_type === "0")
    .reduce((acc, item) => {
      const item_additional_discount =
        parseFloat(additionalDiscount) *
          (item.pre_vat_amount / total_pre_vat_amount) || 0;
      return acc + (item.pre_vat_amount - item_additional_discount);
    }, 0);

  if (vatType === "excluded_vat") {
    summary.vat_7_amount = itemList
      .filter((item) => item?.vat_type === "7")
      .reduce((acc, item) => {
        const item_additional_discount =
          parseFloat(additionalDiscount) *
            (item.pre_vat_amount / total_pre_vat_amount) || 0;
        return acc + (item.pre_vat_amount - item_additional_discount);
      }, 0);
    summary.vat_amount = summary.vat_7_amount * 0.07;
  } else {
    summary.vat_7_amount = itemList
      .filter((item) => item?.vat_type === "7")
      .reduce((acc, item) => {
        const item_additional_discount =
          parseFloat(additionalDiscount) *
            (item.pre_vat_amount / total_pre_vat_amount) || 0;
        return acc + (item.pre_vat_amount - item_additional_discount) / 1.07;
      }, 0);
    summary.vat_amount = summary.vat_7_amount * 0.07;
  }

  if (type === "di") {
    summary.net_amount = summary.pre_vat_amount + summary.vat_amount;
  } else {
    summary.net_amount =
      summary.vat_exempted_amount +
      summary.vat_0_amount +
      summary.vat_7_amount +
      summary.vat_amount;
  }

  if (type !== "rt") {
    if (vatType === "excluded_vat") {
      summary.withholding_tax_amount =
        itemList
          .filter(
            (item) =>
              !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                item?.withholding_tax?.type
              )
          )
          .reduce((acc, item) => {
            const item_additional_discount =
              parseFloat(additionalDiscount) *
                (item.pre_vat_amount / total_pre_vat_amount) || 0;
            return (
              acc +
              ((item?.pre_vat_amount - item_additional_discount) *
                item?.withholding_tax?.type) /
                100
            );
          }, 0) || 0;
    } else {
      summary.withholding_tax_amount =
        itemList
          .filter(
            (item) =>
              !["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(
                item?.withholding_tax?.type
              )
          )
          .reduce((acc, item) => {
            const item_additional_discount =
              parseFloat(additionalDiscount) *
                (item.pre_vat_amount / total_pre_vat_amount) || 0;
            if (item.vat_type === "7") {
              return (
                acc +
                (((item?.pre_vat_amount - item_additional_discount) / 1.07) *
                  item?.withholding_tax?.type) /
                  100
              );
            }
            return (
              acc +
              ((item?.pre_vat_amount - item_additional_discount) *
                item?.withholding_tax?.type) /
                100
            );
          }, 0) || 0;
    }
  } else {
    if (
      ["ยังไม่ระบุ", "ไม่มี", "", undefined, null].includes(withholdingTaxType)
    ) {
      summary.withholding_tax_amount = 0;
    } else {
      summary.withholding_tax_amount = itemList.reduce((acc, item) => {
        const paymentAmount = item?.payment_amount || 0;
        return (
          acc + (paymentAmount * withholdingTaxType?.replace("%", "")) / 100
        );
      }, 0);
    }
  }

  if (type === "di") {
    summary.total_amount = summary.net_amount;
  } else {
    if (type === "rs") {
      summary.total_amount =
        summary.net_amount - summary.withholding_tax_amount;
    } else {
      summary.total_amount =
        (shippingCost ? parseFloat(shippingCost) : 0) +
        summary.net_amount -
        summary.withholding_tax_amount;
    }
  }

  //loop formating result in comma form
  for (const [key, value] of Object.entries(summary)) {
    summary[key] = formatNumber(value);
  }

  return summary;
};

export const localeStringToFloat = (string) => {
  return parseFloat(string.replace(/,/g, ""));
};

export const getDocumentName = (t, document) => {
  switch (document) {
    case "quotation":
      return t("sales.quotation.index");
    case "order":
    case "sales_order":
      return t("sales.order.index");
    case "deposit-invoice":
    case "deposit_invoice":
      return t("sales.depositInvoice.index");
    case "invoice":
    case "sales_invoice":
      return t("sales.invoice.index");
    case "payment-receipt":
    case "payment_receipt":
      return t("sales.paymentReceipt.index");
    case "return":
    case "sales_return":
      return t("sales.return.index");
    case "credit-note":
    case "credit_note":
      return t("sales.creditNote.index");
    case "delivery-order":
    case "delivery_order":
      return t("logistic.deliveryOrder.index");
    case "delivery-trip":
    case "delivery_trip":
      return t("logistic.deliveryTrip.index");
    case "goods_receive":
      return t("inventory.receive.index");
    case "goods_issue":
      return t("inventory.issue.index");
    case "goods_transfer":
      return t("inventory.transfer.index");
    case "goods_adjustment":
      return t("inventory.adjustment.index");
    default:
      return "";
  }
};

export const errorMessageHandler = (error, message) => {
  const formatError = JSON.stringify(error);
  if (formatError.includes("Unique constraint failed")) {
    return (
      message || "เลขที่เอกสารนี้มีอยู่ในระบบแล้ว กรุณาระบุเลขที่เอกสารใหม่"
    );
  } else if (formatError.includes("contact is used in")) {
    return "ไม่สามารถลบได้ เนื่องจากผู้ติดต่อถูกใช้งานในเอกสารอยู่";
  } else if (
    formatError.includes(
      "Cannot delete bom with related to unfinished manufacture order"
    )
  ) {
    return "ไม่สามารถลบได้ เนื่องจากสูตรถูกใช้งานอยู่";
  } else if (formatError.includes("No bom")) {
    return "ไม่พบสูตรนี้ในระบบ";
  } else if (formatError.includes("Work Center is in used")) {
    return "ไม่สามารถลบศูนย์ผลิตนี้ได้ เนื่องจากได้มีการนำไปใช้ในเอกสารแล้ว";
  } else if (formatError.includes("Inactive user")) {
    return "ผู้ใช้งานนี้ถูกปิดการใช้งาน";
  } else {
    return;
  }
};

export const formatFloat = (input, points) => {
  return parseFloat(parseFloat(input).toFixed(points || 3));
};

export const formatStringArray = (arr) => {
  if (typeof arr === "string") {
    return [arr];
  }
  return Array.isArray(arr) ? arr?.map((item) => item?.toString().trim()) : [];
};

export const sortArrayOfObjectByValue = (arr, sortOrder, sortKey) => {
  if (
    !arr ||
    arr?.length === 0 ||
    !sortOrder ||
    sortOrder?.length === 0 ||
    !sortKey
  ) {
    return arr;
  }
  const sorted = [...arr];
  sorted.sort((a, b) => {
    return sortOrder.indexOf(a[sortKey]) - sortOrder.indexOf(b[sortKey]);
  });
  return sorted;
};
