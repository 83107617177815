import SalesService from "../../../services/Sales";
import { salesReturnActions } from "./sales-return-slice";
import {
  salesExportFormatter,
  salesQueryFormatter,
} from "../../../utils/salesPayloadFormatter";
import GlobalService from "../../../services/Global";
import ActivityLogsService from "../../../services/ActivityLogs";
import {
  createActivityLogApprovePayload,
  createActivityLogCopiedPayload,
  createActivityLogEditPayload,
  createActivityLogEmployeePayload,
  createActivityLogPayload,
  createActivityLogStatusPayload,
  findDifferenceEmployee,
} from "../../../utils/activityLogsPayloadFormatter";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import InventoryService from "../../../services/Inventory";
import { unixToDateWithFormat } from "../../../utils/date-converter";

export const getAllSalesReturns =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("allSalesReturns"));
    try {
      const allSalesReturns = await SalesService.getAllSalesReturns(input);
      params.successCallback(allSalesReturns.results, allSalesReturns.count);
      dispatch(
        salesReturnActions.loadedAllSalesReturns(allSalesReturns.results)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "allSalesReturns" })
      );
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

// export const getAllSalesReturnsExport =
//   (input, enqueueSnackbar) => async (dispatch) => {
//     dispatch(salesReturnActions.onLoading("allSalesReturns"));
//     try {
//       const { results } = await SalesService.getAllSalesReturns(input);
//       const formatAllSalesReturns = results.map((salesReturn) => {
//         let item_list = [];
//         salesReturn.item_list.forEach((item) => {
//           const pre_vat_amount =
//             item.qty * item.price_per_unit - item.discount_amount;
//           const withholding_tax =
//             item.withholding_tax === -1
//               ? "ยังไม่ระบุ"
//               : item.withholding_tax === 0
//               ? "ไม่มี"
//               : (item.withholding_tax * 100).toString();
//           const formatItemList = {
//             ...item,
//             pre_vat_amount,
//             withholding_tax,
//           };
//           item_list.push(formatItemList);
//         });
//         return {
//           ...salesReturn,
//           item_list,
//           issue_date: unixToDateWithFormat(salesReturn.issue_date),
//           created_date: unixToDateWithFormat(salesReturn.created_date),
//           updated_date: unixToDateWithFormat(salesReturn.updated_date),
//           render_status: filterStatusValueFormatter(salesReturn.render_status),
//         };
//       });
//       dispatch(salesReturnActions.loadedAllSalesReturns(formatAllSalesReturns));
//     } catch (err) {
//       console.error(err);
//       dispatch(
//         salesReturnActions.rejectedActions({ ...err, name: "allSalesReturns" })
//       );
//       enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
//         variant: "error",
//       });
//     }
//   };

export const getAllSalesReturnsExport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("allSalesReturns"));
    try {
      const { results } = await SalesService.getAllSalesReturns(input);

      for (const [index, value] of results.entries()) {
        const documentRelations = await GlobalService.getDocumentRelations(
          value.document_id
        );
        const formatDocumentRelation = await documentRelations.map(
          (document) => document.reference_document_id
        );
        results[index].reference_document_id = await formatDocumentRelation;
      }

      let salesReturns = salesExportFormatter(results, "sales_return");
      dispatch(salesReturnActions.loadedAllSalesReturnsExport(salesReturns));
    } catch (err) {
      console.error(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "allSalesReturns" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllSalesReturnsExportReport =
  (input, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("allSalesReturns"));
    try {
      const { results } = await SalesService.getAllSalesReturnsReport(input);

      for (const [index, value] of results.entries()) {
        const documentRelations = await GlobalService.getDocumentRelations(
          value.document_id
        );
        const formatDocumentRelation = await documentRelations.map(
          (document) => document.reference_document_id
        );
        results[index].reference_document_id = await formatDocumentRelation;
      }

      let salesReturns = salesExportFormatter(results, "sales_return");
      dispatch(salesReturnActions.loadedAllSalesReturnsExport(salesReturns));
    } catch (err) {
      console.log(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "allSalesReturns" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const createNewSalesReturn =
  (payload, isSendApprove, state, user, enqueueSnackbar, navigate) =>
  async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const result = await SalesService.createSalesReturn(payload);
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const createActivityLog = createActivityLogPayload(
        activityLogsPayload,
        "sales_return",
        "สร้างใบรับคืน"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });

      if (payload.reference_document_id) {
        const createRefActivityLog = createActivityLogPayload(
          {
            ...activityLogsPayload,
            document_id: payload.reference_document_id,
          },
          "sales_order",
          "สร้างใบรับคืน"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createRefActivityLog,
        });
      }

      if (state && state?.isCopied) {
        const activityLogsPayload = {
          document_id: state.document_id,
          creator_document_id: user.document_id,
        };
        const copiedActivityLog = createActivityLogCopiedPayload(
          activityLogsPayload,
          "sales_return"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: copiedActivityLog,
        });
      }

      if (isSendApprove) {
        const documentInput = {
          document_type: "sales_return",
          document_id: result.document_id,
        };
        const sendToApproveActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "sales_return",
          null,
          "wait_approve"
        );
        await SalesService.postNextStatus(documentInput);
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: sendToApproveActivityLog,
        });
        enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
          variant: "success",
        });
      } else {
        const saveDraftActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "sales_return",
          null,
          "draft"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: saveDraftActivityLog,
        });
        enqueueSnackbar("บันทึกร่างสำเร็จ", {
          variant: "success",
        });
      }
      navigate(`/sales/return/${encodeURIComponent(result.document_id)}`);
    } catch (err) {
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      err.response.errors.forEach((error) => {
        if (errorMessageHandler(error.message)) {
          enqueueSnackbar(errorMessageHandler(error.message), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("สร้างใบรับคืนไม่สำเร็จ", {
            variant: "error",
          });
        }
      });
    }
  };

export const getSalesReturnById =
  (uniqueInput, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const salesReturn = await SalesService.getSalesReturnById(uniqueInput);
      const formatSalesReturn = await salesQueryFormatter(
        salesReturn,
        "sales_return"
      );
      if (
        formatSalesReturn.reference_document_id &&
        formatSalesReturn.reference_document_id.length > 0
      ) {
        const salesOrder = await SalesService.getSalesOrderById({
          document_id:
            formatSalesReturn.reference_document_id[0].reference_document_id,
        });
        const formatSalesOrder = await salesQueryFormatter(
          salesOrder,
          "sales_order"
        );
        dispatch(
          salesReturnActions.loadedSalesOrderItem(formatSalesOrder.item_list)
        );
      }
      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_return");

      const approvalProgress = await SalesService.getApprovalProgress({
        reference_document_type: "sales_return",
        reference_document_id: uniqueInput.document_id,
      });

      dispatch(
        salesReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress,
        })
      );

      dispatch(
        salesReturnActions.updateApprovalStatus({
          approval_list: approvalProgress,
          user,
        })
      );

      dispatch(salesReturnActions.loadedSalesReturn(formatSalesReturn));
    } catch (err) {
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getCreditNoteTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const creditNoteTab = [];
      const referenceDocument =
        await GlobalService.getReferenceDocumentRelations(documentId);

      const filteredCreditNote = referenceDocument.filter(
        (document) => document.document_type === "credit_note"
      );
      for (const [index, value] of filteredCreditNote.entries()) {
        const creditNote = await SalesService.getCreditNoteById({
          document_id: value.document_id,
        });
        creditNoteTab[index] = creditNote;
      }
      dispatch(salesReturnActions.loadedCreditNoteTab(creditNoteTab));
    } catch (err) {
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodReceiveTab =
  (documentId, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const { results } = await InventoryService.getAllGRAggrid({
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          reference_document_id: {
            filter: documentId,
            filterType: "text",
            type: "contains",
          },
        },
        sortModel: [],
      });

      const formatResults = results.map((goodsReceive) => ({
        ...goodsReceive,
        created_date: unixToDateWithFormat(goodsReceive.created_date),
        document_date: unixToDateWithFormat(goodsReceive.document_date),
      }));

      dispatch(salesReturnActions.loadedGoodReceiveTab(formatResults));
    } catch (err) {
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const updateSalesReturn =
  (payload, status, isChangedStatus, existing, user, enqueueSnackbar) =>
  async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const salesReturn = await SalesService.updateSalesReturn(payload);
      const formatSalesReturn = await salesQueryFormatter(
        salesReturn,
        "sales_return"
      );
      if (isChangedStatus) {
        const documentInput = {
          document_id: payload.document_id,
          document_type: "sales_return",
        };
        const updatedSalesReturn = await SalesService.postNextStatus(
          documentInput
        );
        if (
          updatedSalesReturn?.approval_progress_list
            ?.map((approval) => approval?.approval_status)
            .includes("PENDING")
        ) {
          dispatch(
            salesReturnActions.updateApprovalStatus({
              approval_list: updatedSalesReturn.approval_progress_list,
              user,
            })
          );
          const sendToApproveActivityLog = createActivityLogStatusPayload(
            activityLogsPayload,
            "sales_return",
            "draft",
            "wait_approve"
          );
          dispatch(
            salesReturnActions.loadedSalesReturn({
              ...formatSalesReturn,
              render_status: "waitApprove",
            })
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: sendToApproveActivityLog,
          });
          enqueueSnackbar("ส่งอนุมัติสำเร็จ", {
            variant: "success",
          });
        } else {
          dispatch(
            salesReturnActions.loadedSalesReturn({
              ...formatSalesReturn,
              render_status: updatedSalesReturn.status,
            })
          );
          enqueueSnackbar("อัพเดทใบรับคืนสำเร็จ", {
            variant: "success",
          });
        }
      } else {
        const editActivityLog = createActivityLogEditPayload(
          activityLogsPayload,
          "sales_return"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: editActivityLog,
        });
        const { addedEmployeeList, deletedEmployeeList } =
          findDifferenceEmployee(payload, existing);
        if (addedEmployeeList && addedEmployeeList.length > 0) {
          // add related employee
          const addedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogsPayload,
            "add_related_employee",
            addedEmployeeList,
            "sales_return"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: addedEmployeeListLog,
          });
        }
        if (deletedEmployeeList && deletedEmployeeList.length > 0) {
          // delete related employee
          const deletedEmployeeListLog = createActivityLogEmployeePayload(
            activityLogsPayload,
            "delete_related_employee",
            deletedEmployeeList,
            "sales_return"
          );
          await ActivityLogsService.createActivityLogs({
            createActivityLogInput: deletedEmployeeListLog,
          });
        }

        switch (status) {
          case "draft":
            dispatch(salesReturnActions.loadedSalesReturn(formatSalesReturn));
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
            break;
          default:
            dispatch(
              salesReturnActions.loadedSalesReturn({
                ...formatSalesReturn,
                render_status: status,
              })
            );
            enqueueSnackbar("แก้ไขใบรับคืนสำเร็จ", {
              variant: "success",
            });
        }
      }
    } catch (err) {
      console.error(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      switch (status) {
        case "draft":
          if (isChangedStatus) {
            err.response.errors.forEach((error) => {
              if (errorMessageHandler(error.message)) {
                enqueueSnackbar(errorMessageHandler(error.message), {
                  variant: "error",
                });
              } else {
                enqueueSnackbar("แก้ไขใบรับคืนไม่สำเร็จ", {
                  variant: "error",
                });
              }
            });
          } else {
            enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
              variant: "error",
            });
          }
          break;
        default:
          enqueueSnackbar("แก้ไขใบรับคืนไม่สำเร็จ", {
            variant: "error",
          });
      }
    }
  };

export const approveSalesReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_return");

      const { approval_progress_list } = await SalesService.postApproveStatus(
        documentInput
      );

      dispatch(
        salesReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );
      const sortedApprovalProgress = approval_progress_list.sort(
        (a, b) => a.created_date - b.created_date
      );

      if (
        sortedApprovalProgress
          ?.map((approval) => approval?.approval_status)
          .includes("PENDING")
      ) {
        dispatch(
          salesReturnActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );

        //TODO: Recheck logic
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );

        dispatch(salesReturnActions.updateSalesReturnStatus("waitApprove"));
        // TODO: Approval step logs
        const approvedActivityLog = createActivityLogApprovePayload(
          activityLogsPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "sales_return"
        );

        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      } else {
        const lastApprovedIndex = sortedApprovalProgress.findLastIndex(
          (approval) => approval.approval_status === "APPROVED"
        );
        const approvedNextActivityLog = createActivityLogApprovePayload(
          activityLogsPayload,
          `ลำดับที่ ${sortedApprovalProgress[lastApprovedIndex].step_number} อนุมัติแล้ว`,
          "sales_return"
        );

        dispatch(
          salesReturnActions.updateApprovalStatus({
            approval_list: approval_progress_list,
            user,
          })
        );
        dispatch(salesReturnActions.updateSalesReturnStatus("approved"));
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedNextActivityLog,
        });
        const approvedActivityLog = createActivityLogStatusPayload(
          activityLogsPayload,
          "sales_return",
          "wait_approve",
          "approved"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: approvedActivityLog,
        });
      }
      enqueueSnackbar("อนุมัติสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const declineSalesReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const notApprovedActivityLog = createActivityLogStatusPayload(
        activityLogsPayload,
        "sales_return",
        "wait_approve",
        "not_approved"
      );
      const { approval_progress_list } = await SalesService.postDeclineStatus(
        documentInput
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: notApprovedActivityLog,
      });
      enqueueSnackbar("ไม่อนุมัติสำเร็จ", {
        variant: "success",
      });
      const approvalTemplates =
        await SalesService.getApprovalTemplatesByDocumentType("sales_return");

      dispatch(
        salesReturnActions.loadedApprovalList({
          approvalTemplates,
          approvalProgress: approval_progress_list,
        })
      );

      dispatch(
        salesReturnActions.updateApprovalStatus({
          approval_list: approval_progress_list,
          user,
        })
      );
      dispatch(salesReturnActions.updateSalesReturnStatus("notApproved"));
    } catch (err) {
      console.error(err);
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("ไม่อนุมัติไม่สำเร็จ", {
        variant: "error",
      });
    }
  };

export const cancelSalesReturn =
  (documentInput, payload, user, enqueueSnackbar) => async (dispatch) => {
    dispatch(salesReturnActions.onLoading("salesReturn"));
    try {
      const activityLogsPayload = {
        document_id: payload.document_id,
        creator_document_id: user.document_id,
      };
      const cancelActivityLog = createActivityLogStatusPayload(
        activityLogsPayload,
        "sales_return",
        null,
        "cancelled"
      );
      await SalesService.cancelDocument(documentInput);
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: cancelActivityLog,
      });
      enqueueSnackbar("ยกเลิกสำเร็จ", {
        variant: "success",
      });
      dispatch(salesReturnActions.updateSalesReturnStatus("cancelled"));
    } catch (err) {
      dispatch(
        salesReturnActions.rejectedActions({ ...err, name: "salesReturn" })
      );
      enqueueSnackbar("ยกเลิกไม่สำเร็จ", {
        variant: "error",
      });
    }
  };
