import moment from "moment";
import { useSnackbar } from "notistack";
import { Avatar, Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import AgGrid from "../../../components/Table/AgGrid";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import {
  dateToUnix,
  unixToDateWithFormat,
} from "../../../utils/date-converter";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import NewReportDateFilter from "../../../components/UI/NewReportDateFilter";
import CustomizedStatus from "../../../components/Custom/CustomizedStatus";
import GlobalService from "../../../services/Global";
import CustomizedChips from "../../../components/Custom/CustomizedChips";
import {
  exportCSVParams,
  filterParamsOptions,
} from "../../../utils/filterparams";
import { getItemNoEntryDateRangeReport } from "../../../features/Inventory/Report/report-actions";
import { inventoryReportActions } from "../../../features/Inventory/Report/report-slice";
import { formatNumber } from "../../../utils/dataTransformer";

import SettingService from "../../../services/Setting";

const ItemNoEntry = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { itemNoEntry, isLoading } = useSelector(
    (state) => state.inventoryReport
  );

  const breadcrumbs = [
    {
      name: t("inventory.index"),
      to: "/inventory",
    },
    {
      name: t("inventory.report"),
      to: "/inventory/report",
    },
    {
      name: t("inventory.noEntry.index"),
    },
  ];

  const getAllTag = useCallback(async () => {
    const { results } = await GlobalService.getTagList({
      startRow: 0,
      endRow: 9999,
    });
    const mapTagValue = results.map((tag) => {
      return {
        ...tag,
        value: tag.name,
        label: tag.name,
      };
    });
    // setTagList(mapTagValue);
    return mapTagValue;
  }, []);

  const getAllItemGroupLevel1 = useCallback(async () => {
    const data = await SettingService.getAllCategory();
    return data;
  }, []);

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: false,
    },
    {
      field: "document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "description",
      headerName: t("inventory.items.itemDescription"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "current_ordered_purchase_qty",
      headerName: t("inventory.quantities.currentOrderedPurchaseQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_committed_sales_qty",
      headerName: t("inventory.quantities.currentCommittedSalesQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_ordered_manufacture_qty",
      headerName: t("inventory.quantities.currentOrderedManufactureQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_stock_qty",
      headerName: t("inventory.quantities.currentStockQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "current_available_qty",
      headerName: t("inventory.quantities.currentAvailableQty"),
      filter: false,
      valueFormatter: (params) => formatNumber(params.value),
    },
    {
      field: "item_group_sub_level_1_name",
      headerName: t("inventory.items.itemGroup") + " " + 1,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const formatValues = values.map((group) => group.name);
          params.success(formatValues);
        },
      },
    },
    {
      field: "item_group_sub_level_2_name",
      headerName: t("inventory.items.itemGroup") + " " + 2,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const newValue = [];
          values &&
            values.length > 0 &&
            values.forEach((group) =>
              group.sub_level_2_list.forEach((group2) => newValue.push(group2))
            );
          const formatValues = newValue.map((group) => group.name);
          params.success(formatValues);
        },
      },
    },
    {
      field: "item_group_sub_level_3_name",
      headerName: t("inventory.items.itemGroup") + " " + 3,
      filter: "agSetColumnFilter",
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllItemGroupLevel1();
          const newValue = [];
          values &&
            values.length > 0 &&
            values.forEach((group) =>
              group.sub_level_2_list.forEach((group2) =>
                group2.sub_level_3_list.forEach((group3) =>
                  newValue.push(group3)
                )
              )
            );
          const formatValues = newValue.map((group) => group.name);
          params.success(formatValues);
        },
      },
    },
    {
      field: "tag_list",
      headerName: t("inventory.items.groupTagList"),
      filter: "agSetColumnFilter",
      sortable: false,
      filterParams: {
        values: async (params) => {
          // fetch values from server
          const values = await getAllTag();
          const formatValues = values
            .filter((tag) => tag.entity === "item")
            .map((tag) => tag.name);
          params.success(formatValues);
        },
      },
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: "inline-flex", mr: 1, gap: 1 }}>
            {params.value.map((tag) => (
              <CustomizedChips key={tag} value={`${tag}`} />
            ))}
          </Box>
        );
      },
    },
    {
      field: "is_active",
      headerName: t("inventory.status"),
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["ใช้งาน", "ไม่ใช้งาน"],
      },
      cellRenderer: (params) => {
        if (params.value === "ใช้งาน") {
          return <CustomizedStatus status="active" />;
        }
        return <CustomizedStatus status="inactive" />;
      },
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "created_by_full_name",
      headerName: t("inventory.createdBy"),
      filter: "agSetColumnFilter",
      hide: true,
      cellRenderer: (params) => {
        if (params.data.created_by.img_url) {
          return <Avatar alt="img_url" src={params.data.created_by.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={
                params.data.created_by.first_name +
                " " +
                params.data.created_by.last_name
              }
            />
          );
        }
      },
    },
  ];

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      dateType: "lastWeek",
      date: new Date(moment().startOf("day").subtract(7, "day")),
      dateTo: new Date(),
      refType: "ทั้งหมด",
      type: "ทั้งหมด",
    },
  });

  const exportHandler = async () => {
    setIsLoadingExport(true);
    try {
      let startDate = getValues("date");
      let endDate = getValues("dateTo");

      const params = exportCSVParams(
        t("inventory.noEntry.index"),
        startDate,
        endDate
      );
      gridRef.current.api.exportDataAsCsv(params);
      enqueueSnackbar("นำออกรายงานสำเร็จ", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("นำออกรายงานไม่สำเร็จ", {
        variant: "error",
      });
    } finally {
      setIsLoadingExport(false);
    }
  };

  const watchRefType = useWatch({ control, name: "refType" });

  const refTypeOption = [
    {
      label: "ทั้งหมด",
      value: "ทั้งหมด",
    },
    {
      label: "นำเข้า",
      value: "receive",
    },
    {
      label: "นำออก",
      value: "issue",
    },
    {
      label: "โอนย้าย",
      value: "transfer",
    },
    {
      label: "ปรับปรุง",
      value: "adjustment",
    },
  ];

  const typeOption = useMemo(
    () => [
      {
        label: "ทั้งหมด",
        value: "ทั้งหมด",
        ref: ["ทั้งหมด", "receive", "issue", "transfer", "adjustment"],
      },
      {
        label: "ซื้อ",
        value: "ซื้อ",
        ref: ["ทั้งหมด", "receive"],
      },
      {
        label: "ผลิต",
        value: "ผลิต",
        ref: ["ทั้งหมด", "receive"],
      },
      {
        label: "แปรรูป",
        value: "แปรรูป",
        ref: ["ทั้งหมด", "receive"],
      },
      {
        label: "รับคืนจากลูกค้า",
        value: "รับคืนจากลูกค้า",
        ref: ["ทั้งหมด", "receive"],
      },
      {
        label: "รับคืนจากผลิต",
        value: "รับคืนจากผลิต",
        ref: ["ทั้งหมด", "receive"],
      },
      {
        label: "ขาย",
        value: "ขาย",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "ขายออนไลน์",
        value: "ขายออนไลน์",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "ผลิตวัตถุดิบ",
        value: "ผลิตวัตถุดิบ",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "ผลิตแปรรูป",
        value: "ผลิตแปรรูป",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "ผลิตแปรรูป - PT",
        value: "ผลิตแปรรูป - PT",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "ส่งคืน",
        value: "ส่งคืน",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "สินค้าเสีย",
        value: "สินค้าเสีย",
        ref: ["ทั้งหมด", "issue"],
      },
      {
        label: "อื่นๆ",
        value: "อื่นๆ",
        ref: ["ทั้งหมด", "receive", "issue"],
      },
    ],
    []
  );

  const getAllItems = useCallback(async () => {
    const getDateValue = getValues("date");
    const getDateToValue = getValues("dateTo");
    const getRefTypeValue = getValues("refType");
    const getTypeValue = getValues("type");
    const input = {
      start_posted_date: dateToUnix(getDateValue),
      end_posted_date: dateToUnix(getDateToValue),
    };
    const otherValue = {
      getRefTypeValue,
      getTypeValue,
    };
    dispatch(getItemNoEntryDateRangeReport(input, otherValue));
  }, [dispatch, getValues]);

  useEffect(() => {
    if (
      watchRefType &&
      !typeOption
        .find((option) => option.value === getValues("type"))
        .ref.includes(watchRefType) > 0
    )
      return setValue("type", "ทั้งหมด");
  }, [getValues, setValue, typeOption, watchRefType]);

  const onFilterReset = () => {
    if (gridRef) {
      gridRef.current.api.setFilterModel({});
    }
    getAllItems(true);
    reset();
  };

  const formatValueForAgGrid = () => {
    if (itemNoEntry.length !== 0) {
      const formatValue = itemNoEntry.map((item, index) => {
        return {
          ...item,
          created_date: unixToDateWithFormat(item.created_date),
          item_group_sub_level_1_name: item.item_group_sub_level_1?.name,
          item_group_sub_level_2_name: item.item_group_sub_level_2?.name,
          item_group_sub_level_3_name: item.item_group_sub_level_3?.name,
          created_by_full_name: item.created_by
            ? item.created_by?.first_name + " " + item.created_by?.last_name
            : "",
          tag_list: item.tag_list.map((tag) => tag.name),
          is_active: item.is_active ? "ใช้งาน" : "ไม่ใช้งาน",
        };
      });
      return formatValue;
    } else return [];
  };

  useEffect(() => {
    return () => dispatch(inventoryReportActions.resetItemNoEntry());
  }, [dispatch]);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">{t("inventory.noEntry.index")}</Typography>
        <CustomizedButton
          sx={{ mr: 2 }}
          title={t("inventory.exportReport")}
          variant="contained"
          onClick={exportHandler}
          disabled={isLoadingExport}
        />
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <NewReportDateFilter
              t={t}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              control={control}
              name={"refType"}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  options={refTypeOption}
                  label={t("inventory.stockEntry.entryDocumentType")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Controller
              control={control}
              name={"type"}
              render={({ field }) => (
                <CustomizedSelect
                  {...field}
                  options={typeOption.filter(
                    (option) => option.ref.includes(watchRefType) > 0
                  )}
                  label={t("inventory.stockEntry.documentType")}
                  disabled={
                    watchRefType !== "ทั้งหมด" &&
                    watchRefType !== "receive" &&
                    watchRefType !== "issue"
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={2.5} alignSelf="center">
            <Box display="flex" gap={2}>
              <CustomizedButton
                title={t("button.submitFilter")}
                variant="contained"
                onClick={() => getAllItems(false)}
                disabled={isLoading.itemNoEntry}
                fullWidth
              />
              <CustomizedButton
                title={t("button.resetFilter")}
                variant="outlined"
                onClick={onFilterReset}
                disabled={isLoading.itemNoEntry}
                fullWidth
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {itemNoEntry && itemNoEntry.length > 0 && (
        <AgGrid
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={formatValueForAgGrid()}
          height={649}
        />
      )}
    </>
  );
};

export default ItemNoEntry;
