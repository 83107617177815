import { Avatar, Typography, Link } from "@mui/material";
import styled from "@emotion/styled";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import { NumericFormat } from "react-number-format";
import CustomizedLetterAvatar from "../../../components/Custom/CustomizedLetterAvatar";
import AgGrid from "../../../components/Table/AgGrid";
import {
  // getAllStockEntries,
  getAllTraceEntry,
} from "../../../features/Inventory/Report/report-actions";
import { entryTypeToThai } from "../../../utils/dataTransformer";
import { filterParamsOptions } from "../../../utils/filterparams";
import { unixToDateWithFormat } from "../../../utils/date-converter";

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  cursor: "pointer",
}));

const Transaction = () => {
  const gridRefTransaction = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const datasource = {
    getRows(params) {
      const request = params.request;
      const filterItemDocId = {
        ...request.filterModel,
        item_document_id: {
          filterType: "text",
          type: "equals",
          filter: id,
        },
        is_active: {
          filterType: "boolean",
          type: "equals",
          filter: "true",
        },
        is_scanned: {
          filterType: "boolean",
          type: "equals",
          filter: "true",
        },
      };
      const sortbyId = [
        ...request.sortModel,
        { colId: "created_date", sort: "asc" },
      ];
      dispatch(
        getAllTraceEntry(
          {
            startRow: request.startRow,
            endRow: request.endRow,
            filterModel: filterItemDocId,
            sortModel: sortbyId,
          },
          params
        )
      );
    },
  };

  const onGridReady = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
    params.api.setServerSideDatasource(datasource);
  };

  const navigateToGoodPage = (type, document_id) => {
    window.open(
      `/inventory/${type}/${encodeURIComponent(document_id)}`,
      "_blank"
    );
  };

  const columnDefs = [
    {
      field: "created_date",
      headerName: t("inventory.stockEntry.createdDate"),
      filter: false,
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "stock_entry",
      headerName: t("inventory.list.sourceWarehousDocId"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value)
          if (params.value.source_warehouse)
            return params.value.source_warehouse.document_id;
        return "";
      },
    },
    {
      field: "stock_entry",
      headerName: t("inventory.list.sourceWarehouse"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value)
          if (params.value.source_warehouse)
            return params.value.source_warehouse.thai_name;
        return "";
      },
    },
    {
      field: "source_bin_location",
      headerName: t("inventory.list.sourceBinLocation"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value) return params.value.document_id;
      },
    },
    {
      field: "stock_entry",
      headerName: t("inventory.list.destinationWarehouseDocId"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value)
          if (params.value.destination_warehouse)
            return params.value.destination_warehouse.document_id;
        return "";
      },
    },
    {
      field: "stock_entry",
      headerName: t("inventory.list.destinationWarehouse"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value)
          if (params.value.destination_warehouse)
            return params.value.destination_warehouse.thai_name;
        return "";
      },
    },
    {
      field: "destination_bin_location",
      headerName: t("inventory.list.destinationBinLocation"),
      filter: "agTextColumnFilter",
      valueFormatter: (params) => {
        if (params.value) return params.value.document_id;
      },
    },
    {
      field: "item.document_id",
      headerName: t("inventory.items.itemId"),
      filter: "agTextColumnFilter",
    },
    {
      field: "item.name",
      headerName: t("inventory.items.itemName"),
      filter: "agTextColumnFilter",
    },
    {
      field: "batch_number",
      headerName: t("inventory.list.lotNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "serial_number",
      headerName: t("inventory.serialNumber"),
      filter: "agTextColumnFilter",
    },
    {
      field: "posted_quantity",
      headerName: t("inventory.list.quantityDifference"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      cellRenderer: (params) => {
        return (
          <NumericFormat
            value={params.value}
            thousandSeparator=","
            displayType="text"
          />
        );
      },
    },
    {
      field: "uom.name",
      headerName: t("inventory.unit"),
      filter: "agTextColumnFilter",
    },
    {
      field: "stock_entry",
      headerName: t("inventory.stockEntry.entryDocumentType"),
      filter: "agSetColumnFilter",
      valueFormatter: (params) => {
        if (params.value)
          return entryTypeToThai(params.value.reference_document_type);
        return "";
      },
    },
    {
      field: "stock_entry",
      headerName: t("inventory.stockEntry.referenceDocument"),
      filter: "agSetColumnFilter",
      valueFormatter: (params) => {
        if (params.value) return params.value.reference_document_id;
        return "";
      },
      cellRenderer: (params) => {
        const { reference_document_type, reference_document_id } = params.value;
        if (!reference_document_type || !reference_document_id) {
          return "-";
        }
        return (
          <StyledLink
            onClick={() =>
              navigateToGoodPage(reference_document_type, reference_document_id)
            }
          >
            {reference_document_id}
          </StyledLink>
        );
      },
    },
    {
      field: "created_by",
      headerName: t("inventory.stockEntry.createdBy"),
      filter: "agSetColumnFilter",
      cellRenderer: (params) => {
        if (params.value.img_url) {
          return <Avatar alt="img_url" src={params.value.img_url} />;
        } else {
          return (
            <CustomizedLetterAvatar
              name={params.value.first_name + " " + params.value.last_name}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <CustomizedBox>
        <Typography fontWeight={"bold"} mb={3}>
          {t("inventory.items.transaction")}
        </Typography>
        <AgGrid
          ref={gridRefTransaction}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          disabledSidebar
          height={450}
        />
      </CustomizedBox>
    </>
  );
};

export default Transaction;
