import React, { useRef, useState } from "react";
import CustomizedButton from "../../../Custom/CustomizedButton";
import AddIcon from "@mui/icons-material/Add";
import ModalUI from "../../../UI/ModalUI";
import { itemMasterActions } from "../../../../features/Inventory/ItemMaster/itemMaster-slice";
import { useSelector } from "react-redux";
import ItemTable from "../../ItemTable";
import {
  Box,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  // Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import CustomizedTextField from "../../../Custom/CustomizedTextField";
// import CustomizedSelect from "../../Custom/CustomizedSelect";
// import GlobalService from "../../../services/Global";
// import { useSnackbar } from "notistack";
import { dateToUnix } from "../../../../utils/date-converter";
import { useAuth } from "../../../../hooks/use-auth";
import { v4 as uuidv4 } from "uuid";
import { formatNumber } from "../../../../utils/dataTransformer";
import TraceEntryList from "./TraceEntryList";
import TraceEntryScanner from "./TraceEntryScanner";

const headerStepOne = [
  "ลำดับ",
  "รหัสสินค้า - ชื่อสินค้า",
  "SN",
  "จำนวนจากเอกสาร",
  "จำนวนนำเข้าแล้ว",
  "จำนวนรอนำเข้า",
  "จำนวนที่จะนำเข้า",
  "หน่วย",
  "",
];

const headerStepTwo = [
  "ลำดับ",
  "วันที่และเวลา",
  "รหัสสินค้า - ชื่อสินค้า",
  "จำนวน",
  "หน่วย",
  "SN",
  "สถานที่",
  "สแกน",
  "",
  "ผู้สแกน",
  "Barcode",
];

const GoodsReceiveTable = ({
  rowData,
  control,
  getValues,
  setValue,
  getRowId,
  onGridReady,
  documentStage,
  watchType,
  watchWarehouse,
  disabled,
  isUpdate,
  filterIsScan,
  isSmallScreen,
  snCount,
  totalError,
  setTotalError,
}) => {
  const {
    control: controlSN,
    getValues: getValuesSN,
    reset: resetSN,
  } = useForm({
    defaultValues: { quantity: 1, number: 1 },
  });

  const itemTableGridRef = useRef();
  const { item } = useSelector((state) => state.itemMaster);
  const [openModal, setOpenModal] = useState(false);
  const [openModalSN, setOpenModalSN] = useState(false);
  const [rowValue, setRowValue] = useState({});
  // const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const setValueTraceEntryHandler = (index, subIndex, name, value) => {
    if (name)
      return setValue(
        `goods_receive_list[${index}].stock_entry_list.trace_entry_list[${subIndex}].${name}`,
        value
      );
    else
      return setValue(
        `goods_receive_list[${index}].stock_entry_list.trace_entry_list`,
        value
      );
  };

  const getValueTraceEntryHandler = (index, subIndex, name) => {
    if (name)
      return getValues(
        `goods_receive_list[${index}].stock_entry_list.trace_entry_list[${subIndex}].${name}`
      );
    else
      return getValues(
        `goods_receive_list[${index}].stock_entry_list.trace_entry_list`
      );
  };

  const handleCloseItemDetailModal = () => {
    setOpenModal(false);
    itemMasterActions.resetItem();
  };

  const handleCloseSNModal = () => {
    setOpenModalSN(false);
    resetSN();
  };

  const handleOpenSNModal = (row, index) => {
    setRowValue({ row, index });
    setOpenModalSN(true);
  };

  const handleSelectItems = () => {
    const selectedItems = itemTableGridRef.current.api.getSelectedRows();
    const allItem = getValues("goods_receive_list");
    selectedItems.forEach((item) => {
      if (
        allItem.findIndex(
          (oldItem) =>
            oldItem.stock_entry_list.item.document_id === item.document_id
        ) < 0
      ) {
        const oldValue = getValues("goods_receive_list");
        const formatStockEntry = [
          ...oldValue,
          {
            initial_quantity: 0,
            posted_quantity: 0,
            posted_value: 0,
            stock_entry_list: {
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: item,
              item_uid: uuidv4(),
              posted_date: dateToUnix(new Date()),
              posted_quantity: 0,
              posted_value: 0,
              reference_document_id: "",
              reference_document_type: "receive",
              source_warehouse_document_id: getValues(
                "source_warehouse_document_id"
              ),
              trace_entry_list: [],
              created_by: user,
              uom: item.base_uom,
            },
          },
        ];
        setValue(`goods_receive_list`, formatStockEntry);
      }
    });
    setOpenModal(false);
  };

  const addItemToRow = async () => {
    const index = rowValue.index;
    const { stock_entry_list } = rowValue.row;
    const { item } = stock_entry_list;
    const { quantity, number } = getValuesSN();
    const oldValue = getValues(
      `goods_receive_list[${index}].stock_entry_list.trace_entry_list`
    );
    for (var i = 0; i < number; i++) {
      const padSNNumber = String(snCount.current).padStart(5, "0");
      // const BatchNumber = `${item.document_id}#${source_warehouse}#${documentId}#${padSNNumber}#${quantity}`;
      oldValue.push({
        batch_number: null,
        destination_bin_location: null,
        document_id: "",
        is_active: false,
        is_scanned: false,
        item_uid: item.item_uid,
        item: { document_id: item.document_id, name: item.name },
        posted_quantity: quantity,
        padSNNumber: padSNNumber,
        posted_value: 0,
        posted_date: null,
        serial_number: null,
        created_by: user,
        source_bin_location_document_id: "",
        tracability: "serial",
        uom: item.uom ? item.uom : item.base_uom,
      });
      snCount.current = snCount.current + 1;
    }
    setValueTraceEntryHandler(index, null, null, oldValue);
    handleCloseSNModal();
  };

  const deleteItem = ({ index, subIndex, type }) => {
    if (index && !subIndex) {
      const oldValue = getValues("goods_receive_list");
      const newValue = oldValue.filter(
        (_, indexValue) => indexValue !== index - 1
      );
      setValue(`goods_receive_list`, newValue);
    } else {
      const oldValue = getValues(
        `goods_receive_list[${index - 1}].stock_entry_list.trace_entry_list`
      );
      const newValue = oldValue.filter(
        (_, indexValue) => indexValue !== subIndex - 1
      );
      setValueTraceEntryHandler(index - 1, null, null, newValue);
    }
  };

  const onChangeBarcodeHandler = (index, subIndex, e) => {
    const inputValue = e;
    const serialNumber = renderSerialNumber(
      getValueTraceEntryHandler(index, subIndex, "padSNNumber")
    );
    const itemInfo = getValueTraceEntryHandler(index, subIndex, "item");
    setValueTraceEntryHandler(
      index,
      subIndex,
      "batch_number",
      `${itemInfo.document_id}#${serialNumber}#${inputValue}`
    );
    setValueTraceEntryHandler(
      index,
      subIndex,
      "posted_quantity",
      parseFloat(inputValue)
    );
    if (isUpdate) {
      setValueTraceEntryHandler(index, subIndex, "is_scanned", false);
    }
  };

  const renderSerialNumber = (count) => {
    const source_warehouse = getValues("source_warehouse_document_id");
    const documentId = getValues("document_id");
    const serialNumber = `${source_warehouse}#${documentId}#${count}`;
    return serialNumber;
  };

  const resetScanHandler = (index, subIndex) => {
    setValueTraceEntryHandler(index, subIndex, "is_scanned", false);
    setValueTraceEntryHandler(
      index,
      subIndex,
      "source_bin_location_document_id",
      ""
    );
    setValueTraceEntryHandler(index, subIndex, "posted_date", null);
    setValueTraceEntryHandler(index, subIndex, "creator_document_id", "");
  };

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  const checkConditionRender = (isScan) => {
    if (filterIsScan === "แสดงทั้งหมด") return true;
    else if (filterIsScan === "รอสแกน" && !isScan) return true;
    else if (filterIsScan === "สแกนแล้ว" && isScan) return true;
    else return false;
  };

  return (
    <>
      <ModalUI
        open={openModal}
        handleClose={handleCloseItemDetailModal}
        navigateTo={`/inventory/items/${item.document_id}`}
        title="รายละเอียดสินค้า"
        fullWidth
      >
        <ItemTable
          gridRef={itemTableGridRef}
          height={450}
          onGridReady={onGridReady}
          rowSelection="multiple"
          enableCheckbox
          getRowId={getRowId}
        />
        <CustomizedButton
          title="เพิ่ม"
          variant="contained"
          onClick={handleSelectItems}
          sx={{ mt: 2 }}
        />
      </ModalUI>
      <ModalUI
        open={openModalSN}
        handleClose={handleCloseSNModal}
        title="เพิ่ม SN"
        width={680}
      >
        <Box my={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={controlSN}
                name={"number"}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    type="number"
                    label={"จำนวน SN"}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={controlSN}
                name={"quantity"}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    type="number"
                    label={"จำนวนที่จะนำเข้าต่อ 1 SN"}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <CustomizedButton
          title="เพิ่ม"
          variant="contained"
          onClick={addItemToRow}
          sx={{ mt: 2 }}
        />
      </ModalUI>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {documentStage === 0
                ? headerStepOne.map((header, index) => (
                    <TableCell align="center" key={header + index}>
                      <Box width="max-content">{header}</Box>
                    </TableCell>
                  ))
                : null}
              {documentStage !== 0
                ? headerStepTwo.map((header, index) => {
                    if (documentStage === 2 && index === 7) return null;
                    return (
                      <TableCell align="center" key={header + index}>
                        <Box width="max-content">{header}</Box>
                      </TableCell>
                    );
                  })
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {documentStage === 0 &&
              rowData &&
              rowData.map((row, index) => (
                <React.Fragment key={row.stock_entry_list.item.name + index}>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ minWidth: "max-content" }}>
                        {row.stock_entry_list?.item.document_id +
                          " - " +
                          row.stock_entry_list?.item.name}
                      </Box>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      {watchType === "อื่นๆ"
                        ? "-"
                        : formatNumber(row.initial_quantity)}
                    </TableCell>
                    <TableCell align="center">
                      {watchType === "อื่นๆ"
                        ? "-"
                        : row.already_posted_quantity
                        ? formatNumber(row.already_posted_quantity)
                        : 0}
                    </TableCell>
                    <TableCell align="center">
                      {watchType === "อื่นๆ"
                        ? "-"
                        : formatNumber(
                            row.initial_quantity - row.already_posted_quantity
                          ) ?? 0}
                    </TableCell>
                    <TableCell align="center">
                      <Box>
                        {row.stock_entry_list?.trace_entry_list
                          ? formatNumber(
                              row.stock_entry_list?.trace_entry_list?.reduce(
                                (prev, curr) =>
                                  parseFloat(prev) +
                                  parseFloat(curr.posted_quantity),
                                0
                              ) ?? 0
                            )
                          : "-"}
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {row.stock_entry_list.uom.name}
                    </TableCell>
                    <TableCell align="center">
                      <Box display={"flex"} justifyContent={"end"}>
                        <IconButton
                          disabled={!watchWarehouse || disabled}
                          onClick={() => handleOpenSNModal(row, index)}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                        {watchType === "อื่นๆ" ? (
                          <IconButton
                            disabled={disabled}
                            onClick={() => deleteItem({ index: index + 1 })}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TraceEntryList
                    control={control}
                    deleteItem={deleteItem}
                    disabled={disabled}
                    index={index}
                    onChangeBarcodeHandler={onChangeBarcodeHandler}
                    renderBoxMaxContent={renderBoxMaxContent}
                    renderSerialNumber={renderSerialNumber}
                    row={row}
                    totalError={totalError}
                    setTotalError={setTotalError}
                  />
                </React.Fragment>
              ))}
            {documentStage !== 0 &&
              rowData &&
              rowData.map((row, index) => (
                <React.Fragment key={row.stock_entry_list.item?.name + index}>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      {renderBoxMaxContent(
                        row.stock_entry_list?.item?.document_id +
                          " - " +
                          row.stock_entry_list?.item?.name
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderBoxMaxContent(
                        formatNumber(
                          row.stock_entry_list?.trace_entry_list?.reduce(
                            (prev, curr) =>
                              parseFloat(prev) +
                              parseFloat(curr.posted_quantity),
                            0
                          ) ?? 0
                        )
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderBoxMaxContent(row.stock_entry_list?.uom?.name)}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    {documentStage === 1 ? (
                      <TableCell align="center"></TableCell>
                    ) : null}
                  </TableRow>
                  <TraceEntryScanner
                    checkConditionRender={checkConditionRender}
                    disabled={disabled}
                    documentStage={documentStage}
                    index={index}
                    renderBoxMaxContent={renderBoxMaxContent}
                    resetScanHandler={resetScanHandler}
                    row={row}
                  />
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {documentStage === 0 && watchType === "อื่นๆ" && (
        <CustomizedButton
          sx={{ marginTop: 2 }}
          title="เพิ่มสินค้า"
          startIcon={<AddIcon />}
          variant="outlined"
          color="secondary"
          disabled={!watchWarehouse || disabled}
          onClick={() => setOpenModal(true)}
        />
      )}
    </>
  );
};

export default GoodsReceiveTable;
