import { Box, Typography } from "@mui/material";
import React, { useRef, useEffect, useState, useMemo } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import SalesHeaderForm from "../../../components/Form/Sales/Header/index";
import {
  deliveryTripSchema,
  deliveryTripValidation,
} from "../../../components/Form/Sales/Header/schema";
import {
  logisticPrimaryButtonHandler,
  logisticSecondaryButtonHandler,
} from "../../../utils/salesButtonHandler";
import DeliveryPlan from "../../../components/Form/Logistic/DeliveryPlan";
import {
  deliveryPlanSchema,
  // deliveryPlanValidation,
} from "../../../components/Form/Logistic/DeliveryPlan/schema";
import DeliveryOrderTable from "../../../components/Table/DocumentTable/DeliveryOrderTable";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
// import DeliveryOrderGroupTable from "../../../components/Table/DocumentTable/DeliveryOrderGroupTable";
import InternalRemark from "../../../components/Form/Logistic/InternalRemark";
import { deliveryTripActions } from "../../../features/Logistic/DeliveryTrip/delivery-trip-slice";
import {
  formatDeliveryTripFromOrder,
  setDeliveryTripValue,
} from "../../../utils/logisticPayloadFormatter";
// import CheckedDeliveryOrderTable from "../../../components/Table/CheckedDeliveryOrderTable";
import { useAuth, usePermission } from "../../../hooks/use-auth";
import { dateToUnix } from "../../../utils/date-converter";
import {
  createNewDeliveryTrip,
  getDeliveryTripById,
  updateDeliveryTrip,
} from "../../../features/Logistic/DeliveryTrip/delivery-trip-actions";
import ModalUI from "../../../components/UI/ModalUI";
import { roundDecimal } from "../../../utils/dataTransformer";
import DeliveryTripItemListForm from "../../../components/Form/Sales/AddItemListForm/AddDeliveryTripItemList";
import { deliveryTripItemListValidation } from "../../../components/Form/Logistic/deliveryTripSchema";
import GlobalService from "../../../services/Global";
// import * as Yup from "yup";

const DeliveryTripContainer = () => {
  const deliveryOrderGridRef = useRef();
  const [viewOnly, setViewOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [primaryButtonTitle, setPrimaryButtonTitle] = useState("ยืนยัน");
  const [secondaryButtonTitle, setSecondaryButtonTitle] =
    useState("บันทึกร่าง");
  const [showButton, setShowButton] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { createPermission, editPermission } = usePermission();
  const { deliveryTrip, isLoading } = useSelector(
    (state) => state.deliveryTrip
  );

  const [disabled, setDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { state } = useLocation();
  const headerRef = useRef();
  const deliveryPlanRef = useRef();
  // const gridRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [deliveryOrderIds, setDeliveryOrderIds] = useState([]);
  const [deliveryOrderIdsSnapshot, setDeliveryOrderIdsSnapshot] = useState([]);
  const { user } = useAuth();
  const [step, setStep] = useState(null);

  const mainSchema = useMemo(
    () => ({
      item_list: [],
      delivery_order_list: [],
      internal_remark: "",
      render_status: null,
      created_by: null,
      creator_document_id: null,
    }),
    []
  );

  const {
    control,
    setValue,
    getValues,
    getFieldState: getMainFieldState,
    trigger: validateMain,
    formState: { errors },
  } = useForm({
    defaultValues: mainSchema,
    resolver: yupResolver(deliveryTripItemListValidation),
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "delivery_order_list",
  });

  const watchStatus = useWatch({ control, name: "render_status" });

  const flattedItemList = [];

  fields.forEach((field) => {
    const formatField =
      field?.item_list?.map((item) => ({
        document_id: field.document_id,
        ...item,
      })) ?? [];
    flattedItemList.push(...formatField);
  });

  const {
    control: headerControl,
    setValue: setHeaderValue,
    getValues: getHeaderValues,
    trigger: validateHeader,
    formState: { errors: headerErrors },
    getFieldState: getHeaderFieldState,
  } = useForm({
    defaultValues: { ...deliveryTripSchema },
    resolver: yupResolver(deliveryTripValidation),
  });

  const {
    control: deliveryPlanControl,
    formState: { errors: deliveryPlanErrors },
    getValues: getDeliveryPlanValues,
    setValue: setDeliveryPlanValue,
    trigger: validateDeliveryPlan,
    getFieldState: getDeliveryPlanFieldState,
  } = useForm({
    defaultValues: { ...deliveryPlanSchema },
  });

  const [itemQtySnapshot, setItemQtySnapshot] = useState([]);

  const breadcrumbs = [
    {
      name: t("logistic.index"),
      to: "/logistic",
    },
    {
      name: t("logistic.deliveryTrip.index"),
      to: "/logistic/delivery-trip",
    },
    {
      name: id ?? t("logistic.deliveryTrip.create"),
    },
  ];

  useEffect(() => {
    switch (watchStatus) {
      case "waitDeliver":
      case "delivering":
      case "completed":
      case "notCompleted":
      case "cancelled":
        setViewOnly(true);
        break;
      default:
        setViewOnly(false);
    }
  }, [watchStatus]);

  useEffect(() => {
    if (state) {
      if (state.isCopied) {
        dispatch(deliveryTripActions.loadedDeliveryTrip(state));
      } else {
        const formatState = formatDeliveryTripFromOrder(state);
        dispatch(deliveryTripActions.loadedDeliveryTrip(formatState));
      }
    } else if (id) {
      dispatch(
        getDeliveryTripById({
          document_id: id,
        })
      );
    }
    return () => {
      dispatch(deliveryTripActions.resetDeliveryTrip());
    };
  }, [dispatch, enqueueSnackbar, id, state]);

  useEffect(() => {
    if (state) {
      if (state.isCopied) {
        setDeliveryTripValue(
          deliveryTripSchema,
          deliveryPlanSchema,
          mainSchema,
          setHeaderValue,
          setDeliveryPlanValue,
          setValue,
          state,
          true
        );
        const deliveryOrderIds = state.delivery_order_list.map(
          (deliveryOrder) => deliveryOrder.document_id
        );
        setDeliveryOrderIds([...deliveryOrderIds]);
        setDeliveryOrderIdsSnapshot([...deliveryOrderIds]);
      } else {
        const formatState = formatDeliveryTripFromOrder(state);
        setDeliveryTripValue(
          deliveryTripSchema,
          deliveryPlanSchema,
          mainSchema,
          setHeaderValue,
          setDeliveryPlanValue,
          setValue,
          formatState,
          true
        );
        const deliveryOrderIds = formatState.delivery_order_list.map(
          (deliveryOrder) => deliveryOrder.document_id
        );
        setDeliveryOrderIds([...deliveryOrderIds]);
        setDeliveryOrderIdsSnapshot([...deliveryOrderIds]);
      }
    } else if (id) {
      setDeliveryTripValue(
        deliveryTripSchema,
        deliveryPlanSchema,
        mainSchema,
        setHeaderValue,
        setDeliveryPlanValue,
        setValue,
        deliveryTrip,
        false
      );
      const deliveryOrderIds = deliveryTrip.delivery_order_list.map(
        (deliveryOrder) => deliveryOrder.document_id
      );
      setDeliveryOrderIds([...deliveryOrderIds]);
      setDeliveryOrderIdsSnapshot([...deliveryOrderIds]);
    }
  }, [
    deliveryTrip,
    id,
    mainSchema,
    setDeliveryPlanValue,
    setHeaderValue,
    setValue,
    state,
  ]);

  //TODO consider validating individual field for specific error message
  const submitForm = async () => {
    const headerIsValid = await validateHeader();
    const deliveryPlanIsValid = await validateDeliveryPlan();
    const mainIsValid = await validateMain();

    const documentIdError = getHeaderFieldState("document_id").error;

    const vehicleError = getDeliveryPlanFieldState("vehicle").error;
    const goodsDeliverError =
      getDeliveryPlanFieldState("goods_deliver_list").error;

    const deliveryOrderListError = getMainFieldState(
      "delivery_order_list"
    ).error;

    const itemListError = getMainFieldState("item_list").error;

    if (itemListError) {
      if (Array.isArray(itemListError)) {
        itemListError.forEach((item) => {
          if (item) {
            const { item_name, qty } = item;
            if (qty) {
              enqueueSnackbar(qty.message, {
                variant: "error",
              });
            }
            if (item_name) {
              enqueueSnackbar(item_name.message, {
                variant: "error",
              });
            }
          }
        });
      } else {
        enqueueSnackbar(itemListError.message, {
          variant: "error",
        });
      }
    }

    if (Boolean(documentIdError)) {
      enqueueSnackbar(documentIdError.message, {
        variant: "error",
      });
    }
    if (Boolean(vehicleError)) {
      enqueueSnackbar(vehicleError.message, {
        variant: "error",
      });
    }
    if (Boolean(goodsDeliverError)) {
      enqueueSnackbar(goodsDeliverError.message, {
        variant: "error",
      });
    }
    if (Boolean(deliveryOrderListError)) {
      enqueueSnackbar(deliveryOrderListError.message, {
        variant: "error",
      });
    }
    if (!headerIsValid) {
      headerRef.current.scrollIntoView();
      return;
    }
    if (!deliveryPlanIsValid) {
      deliveryPlanRef.current.scrollIntoView();
      return;
    }
    if (headerIsValid && deliveryPlanIsValid && mainIsValid) {
      const { delivery_order_list, internal_remark, item_list } = getValues();
      const headerValues = getHeaderValues();
      const {
        id,
        reference_document_id,
        document_id,
        external_ref_id,
        issue_date,
        delivery_date,
      } = headerValues;

      const transformedEmployeeList =
        headerValues?.employee_list?.map(
          ({
            id,
            status,
            jwt_modifier,
            exp,
            iat,
            created_date,
            last_login_date,
            role_list,
            ...employee
          }) => employee
        ) || [];
      const deliveryPlan = getDeliveryPlanValues();
      const {
        delivery_type,
        vehicle,
        goods_deliver_list,
        trip,
        in_date,
        out_date,
      } = deliveryPlan;

      const total_volume =
        delivery_order_list.length > 0
          ? delivery_order_list.reduce((totalDelivery, delivery) => {
              const sum = delivery.item_list.reduce((total, item) => {
                return total + roundDecimal(item.volume);
              }, 0);
              return totalDelivery + sum;
            }, 0)
          : 0;

      const total_weight =
        delivery_order_list.length > 0
          ? delivery_order_list.reduce((totalDelivery, delivery) => {
              const sum = delivery.item_list.reduce((total, item) => {
                return total + roundDecimal(item.weight);
              }, 0);
              return totalDelivery + sum;
            }, 0)
          : 0;

      const formatItemList = item_list.map(
        ({
          uom_group,
          current_ordered_purchase_qty,
          current_ordered_manufacture_qty,
          current_committed_sales_qty,
          current_committed_manufacture_qty,
          current_stock_qty,
          current_available_qty,
          is_active,
          ...other
        }) => ({ ...other, dt_reference_document_id: document_id })
      );
      const payload = {
        id,
        creator_document_id: id ? undefined : user.document_id,
        delivery_date: dateToUnix(delivery_date),
        delivery_type,
        do_document_id_list:
          delivery_order_list.length > 0
            ? delivery_order_list.map((delivery) => delivery.document_id)
            : [],
        document_id,
        employee_list: transformedEmployeeList,
        vehicle_id: parseInt(vehicle?.id) ?? null,
        trip: parseInt(trip),
        reference_document_id:
          reference_document_id.length > 0
            ? reference_document_id.map((ref) => ref.reference_document_id)
            : null,
        out_date: out_date ? dateToUnix(out_date) : null,
        issue_date: issue_date ? dateToUnix(issue_date) : null,
        in_date: in_date ? dateToUnix(in_date) : null,
        goods_deliver_document_id_list:
          goods_deliver_list?.map((goods) => goods.document_id) ?? [],
        external_ref_id,
        internal_remark,
        total_volume: Math.round((total_volume + Number.EPSILON) * 100) / 100,
        total_weight: Math.round((total_weight + Number.EPSILON) * 100) / 100,
        item_list: formatItemList,
      };

      let isExceed = false;
      if (!watchStatus || watchStatus === "draft" || isEdit) {
        if (reference_document_id && reference_document_id.length > 0) {
          const itemList = getValues("item_list");
          const promises = reference_document_id.map(async (document) => {
            const deliveryOrderItemList =
              await GlobalService.getDocumentRelationQty({
                document_id: document.reference_document_id,
                document_type: "delivery_order",
                reference_document_type: "delivery_trip",
              });

            deliveryOrderItemList.forEach((item) => {
              const findIndex = itemList.findIndex(
                (dtItem) =>
                  dtItem.uid === item.uid &&
                  dtItem.do_reference_document_id ===
                    document.reference_document_id
              );

              if (findIndex !== -1) {
                if (isEdit) {
                  //Find the actual index compare with item snapshot if if the index have adjusted
                  const findSnapshotIndex = itemQtySnapshot.findIndex(
                    (dtItem) =>
                      dtItem.uid === item.uid &&
                      dtItem.do_reference_document_id ===
                        document.reference_document_id
                  );
                  if (findSnapshotIndex !== -1) {
                    //Lastest qty from current document
                    const currentMaximum =
                      item.qty_delivery -
                      (item.accumulate_delivery -
                        itemQtySnapshot[findSnapshotIndex].qty);

                    if (itemList[findIndex].qty > currentMaximum) {
                      isExceed = true;
                    }
                  }
                } else {
                  if (
                    itemList[findIndex].qty >
                    item.qty_delivery - item.accumulate_delivery
                  ) {
                    isExceed = true;
                  }
                }
              }
            });
          });
          await Promise.all(promises);
        }
      }

      if (isExceed) {
        enqueueSnackbar("มีรายการสินค้าเกินจำนวน กรุณาระบุใหม่อีกครั้ง", {
          variant: "error",
        });
        return;
      } else {
        return payload;
      }
    }
  };

  const saveDraftHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      if (!id) {
        delete payload.id;
        dispatch(
          createNewDeliveryTrip(
            payload,
            false,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updateDeliveryTrip(
            payload,
            user,
            watchStatus,
            false,
            deliveryTrip,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const sendDeliverHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      const sendDeliverPayload = {
        ...payload,
        update_item_list: true,
      };
      if (!id) {
        delete payload.id;
        dispatch(
          createNewDeliveryTrip(
            payload,
            true,
            state,
            user,
            enqueueSnackbar,
            navigate
          )
        );
      } else {
        dispatch(
          updateDeliveryTrip(
            sendDeliverPayload,
            user,
            watchStatus,
            true,
            deliveryTrip,
            enqueueSnackbar
          )
        );
      }
    }
  };

  const editSubmitHandler = async () => {
    // setShowButton(false);
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      dispatch(
        updateDeliveryTrip(
          payload,
          user,
          watchStatus,
          false,
          deliveryTrip,
          enqueueSnackbar
        )
      );
      setIsEdit(false);
      setViewOnly(true);
    }
  };

  const editCancelHandler = () => {
    navigate("/logistic/delivery-trip");
  };

  useEffect(() => {
    if (isLoading.deliveryTrip) {
      setShowButton(false);
    } else {
      if (isEdit) {
        setShowButton(true);
      } else {
        switch (watchStatus) {
          case "draft":
          case "":
          case null:
          case undefined:
            setShowButton(true);
            break;
          default:
            setShowButton(false);
        }
      }
    }
  }, [isEdit, isLoading.deliveryTrip, watchStatus]);

  useEffect(() => {
    if (isEdit) {
      setPrimaryButtonTitle("บันทึก");
      setSecondaryButtonTitle("ยกเลิก");
    }
  }, [isEdit]);

  const primarySubmitHandler = () => {
    logisticPrimaryButtonHandler(
      watchStatus,
      isEdit,
      sendDeliverHandler,
      editSubmitHandler
    );
  };

  const secondarySubmitHandler = async () => {
    logisticSecondaryButtonHandler(
      watchStatus,
      isEdit,
      saveDraftHandler,
      editCancelHandler
    );
  };

  const editClickHandler = () => {
    setViewOnly(false);
    setIsEdit(true);
    setItemQtySnapshot(deliveryTrip.item_list);
  };

  const referenceDeleteHandler = (index) => {
    const { reference_document_id } = getHeaderValues();
    const deletedDocumentId = fields[index].document_id;
    const currentItemList = getValues("item_list");
    remove(index);
    setDeliveryOrderIds((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    setDeliveryOrderIdsSnapshot((prevIds) => [
      ...prevIds.slice(0, index),
      ...prevIds.slice(index + 1),
    ]);
    const newReference = [
      ...reference_document_id.slice(0, index),
      ...reference_document_id.slice(index + 1),
    ];
    const filteredItemList = currentItemList.filter(
      (item) => item.do_reference_document_id !== deletedDocumentId
    );

    setValue("item_list", filteredItemList);
    setHeaderValue("reference_document_id", newReference);
  };

  const onChangeStatusCloseHandler = () => {
    setStep(null);
    setOpenChangeStatus(false);
  };

  const submitDeliveryChanged = async () => {
    setDisabled(true);
    const payload = await submitForm();
    setDisabled(false);
    if (payload) {
      onChangeStatusCloseHandler();
      dispatch(
        updateDeliveryTrip(
          payload,
          user,
          watchStatus,
          false,
          deliveryTrip,
          enqueueSnackbar,
          true,
          step
        )
      );
    }
  };

  const stepMessageHandler = (step) => {
    switch (step) {
      case 3:
        return t("status.delivering");
      case 4:
        return t("status.notCompleted");
      case 5:
        return t("status.completed");
      default:
        return watchStatus;
    }
  };

  const statusMessageHandler = (status) => {
    switch (status) {
      case "waitDeliver":
        return t("status.waitDeliver");
      case "delivering":
        return t("status.delivering");
      case "completed":
        return t("status.completed");
      case "notCompleted":
        return t("status.notCompleted");
      default:
        return watchStatus;
    }
  };

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ my: 3 }}>
        <SalesHeaderForm
          ref={headerRef}
          control={headerControl}
          errors={headerErrors}
          getValues={getHeaderValues}
          setValue={setHeaderValue}
          setMainValue={setValue}
          mainFormControl={control}
          viewOnly={viewOnly || Boolean(!editPermission && id)}
          documentType="delivery_trip"
          editClickHandler={editClickHandler}
          isEdit={isEdit}
          currentState={state?.id || deliveryTrip}
          referenceDeleteHandler={referenceDeleteHandler}
          setStep={setStep}
          setOpenChangeStatus={setOpenChangeStatus}
          setIsEdit={setIsEdit}
        />
      </Box>
      <Box ref={deliveryPlanRef}>
        <DeliveryPlan
          gridRef={deliveryOrderGridRef}
          control={deliveryPlanControl}
          errors={deliveryPlanErrors}
          viewOnly={viewOnly || Boolean(!editPermission && id)}
          getValues={getDeliveryPlanValues}
          setValue={setDeliveryPlanValue}
          currentRowData={fields}
          setMainValue={setValue}
        />
      </Box>
      <CustomizedBox>
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
          {t("logistic.deliveryOrder.index")}
        </Typography>
        <DeliveryOrderTable
          gridRef={deliveryOrderGridRef}
          isClient
          rowData={fields ?? []}
          referenceDeleteHandler={referenceDeleteHandler}
          viewOnly={viewOnly || Boolean(!editPermission && id)}
          isDeliveryTrip
        />
        {/* {!viewOnly && (
          <CustomizedButton
            sx={{ mt: 3 }}
            title="นำเข้าใบส่งของ"
            variant="outlined"
            onClick={() => setOpenModal(true)}
            color="secondary"
          />
        )} */}
      </CustomizedBox>
      {/* <CustomizedBox margin="0 0 2rem 0">
        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
          {t("logistic.deliveryTrip.cargoPlan")}
        </Typography>
        <DeliveryOrderGroupTable
          ref={gridRef}
          rowData={flattedItemList ?? []}
          vehicle={getDeliveryPlanValues("vehicle")}
        />
      </CustomizedBox> */}
      <DeliveryTripItemListForm
        control={control}
        deliveryOrderIds={deliveryOrderIds}
        deliveryOrderIdsSnapshot={deliveryOrderIdsSnapshot}
        documentType="delivery_trip"
        errors={errors}
        getValues={getValues}
        openModal={openModal}
        setDeliveryOrderIds={setDeliveryOrderIds}
        setDeliveryOrderIdsSnapshot={setDeliveryOrderIdsSnapshot}
        setHeaderValue={setHeaderValue}
        setOpenModal={setOpenModal}
        setValue={setValue}
        viewOnly={viewOnly}
        deliveryPlanControl={deliveryPlanControl}
      />
      <InternalRemark
        control={control}
        disabled={viewOnly || Boolean(!editPermission && id)}
      />
      {showButton && ((createPermission && !id) || (editPermission && id)) && (
        <Box sx={{ display: "flex" }}>
          {secondaryButtonTitle && (
            <Box sx={{ mr: 1 }}>
              <CustomizedButton
                title={secondaryButtonTitle}
                variant="outlined"
                type="reset"
                onClick={secondarySubmitHandler}
                disabled={disabled || isLoading.deliveryTrip}
              />
            </Box>
          )}
          <CustomizedButton
            title={primaryButtonTitle}
            variant="contained"
            onClick={primarySubmitHandler}
            disabled={disabled || isLoading.deliveryTrip}
          />
        </Box>
      )}
      <ModalUI
        title={`ยืนยันการปรับสถานะรอบจัดส่งจาก ${statusMessageHandler(
          watchStatus
        )} เป็น ${stepMessageHandler(step)}`}
        open={openChangeStatus}
        handleClose={onChangeStatusCloseHandler}
        maxWidth="xs"
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            gap: ".5rem",
          }}
        >
          <CustomizedButton
            title="ยกเลิก"
            variant="outlined"
            onClick={onChangeStatusCloseHandler}
          />
          <CustomizedButton
            title="ยืนยัน"
            variant="contained"
            onClick={submitDeliveryChanged}
          />
        </Box>
      </ModalUI>
    </>
  );
};

export default DeliveryTripContainer;
