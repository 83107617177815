import { Box, Typography } from "@mui/material";
import React from "react";

const PDFHeader = ({ documentType, noCompany, imgSize }) => {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <img height={imgSize ?? 40} alt="npr-logo" src="/static/logo.png" />
          {!noCompany && (
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                บริษัท เอ็นพีอาร์ ดิจิทัล พาร์ทเนอร์ จำกัด
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                1000 59-60 ซอย เจริญราษฎร์ 7 แขวงบางคอแหลม
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                เขตบางคอแหลม กรุงเทพมหานคร 10120
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                โทรศัพท์: 02-101-0396
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                เลขประจำตัวผู้เสียภาษีอากร: 0105563019666
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          {documentType !== "โอนย้าย" && documentType !== "สูตรการผลิต/BOM" && (
            <Typography
              sx={{ fontSize: 9, fontWeight: 600, textAlign: "right" }}
            >
              (ต้นฉบับ/Original)
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            {documentType}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PDFHeader;
