import InventoryService from "../../../services/Inventory";
import {
  createActivityLogPayload,
  createActivityLogStatusPayload,
} from "../../../utils/activityLogsPayloadFormatter";
import { goodsIssueActions } from "./goodsIssue-slice";
import ActivityLogsService from "../../../services/ActivityLogs";
import { errorMessageHandler } from "../../../utils/dataTransformer";
import { createLog } from "../../../services/Logs/firebase";

const checkRefFromType = (type) => {
  switch (type) {
    case "ขาย":
    case "ขายออนไลน์":
      return "sales_order";
    case "ผลิตวัตถุดิบ":
    case "ผลิตแปรรูป":
    case "ผลิตแปรรูป - PT":
      return "manufacture_order";
    case "ส่งคืน":
      return "purchase_return";
    default:
      break;
  }
};

export const getAllGoodsIssue =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGIAggrid(input);
      if (params) params.successCallback(allItems.results, allItems.count);
      dispatch(goodsIssueActions.loadedAllItems(allItems.results));
      dispatch(goodsIssueActions.loadedAllCount(allItems.count));
    } catch (err) {
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllGoodsIssueNoItem =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGIAggridNoItem(input);
      dispatch(goodsIssueActions.loadedAllItems(allItems.results));
      dispatch(goodsIssueActions.loadedAllCount(allItems.count));
      if (params) params.successCallback(allItems.results, allItems.count);
    } catch (err) {
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getAllGoodsIssueReport =
  (input, params, enqueueSnackbar) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("allItems"));
    try {
      const allItems = await InventoryService.getAllGIReportAggrid(input);
      if (params) params.successCallback(allItems.results, allItems.count);
      dispatch(goodsIssueActions.loadedAllItems(allItems.results));
      dispatch(goodsIssueActions.loadedAllCount(allItems.count));
    } catch (err) {
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "allItems" }));
      params.failCallback();
      enqueueSnackbar("มีบางอย่างผิดพลาด กรุณาลองใหม่ภายหลัง", {
        variant: "error",
      });
    }
  };

export const getGoodsIssueById = (input) => async (dispatch) => {
  dispatch(goodsIssueActions.onLoading("item"));
  try {
    const Item = await InventoryService.getGI(input);
    dispatch(goodsIssueActions.loadedItem(Item));
  } catch (err) {
    dispatch(goodsIssueActions.rejectedActions({ ...err, name: "item" }));
  }
};

export const createGoodsIssueWithApprove =
  (input, enqueueSnackbar, navigate, user, location) => async (dispatch) => {
    dispatch(goodsIssueActions.onLoading("item"));
    try {
      const item = await InventoryService.createGIWithApprove(input);
      const soType = ["ขาย", "ขายออนไลน์"];
      const moType = ["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"];
      if (enqueueSnackbar)
        enqueueSnackbar("สร้างเอกสารนำออกสำเร็จ", {
          variant: "success",
        });
      const createActivityLog = createActivityLogPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_issue",
        "สร้างใบนำออก"
      );
      const createActivityLogApprove = createActivityLogStatusPayload(
        { ...input, creator_document_id: user.document_id },
        "goods_issue",
        null,
        "finished"
      );
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLog,
      });
      await ActivityLogsService.createActivityLogs({
        createActivityLogInput: createActivityLogApprove,
      });
      if (item.reference_document_id) {
        const createActivityLog = createActivityLogPayload(
          {
            document_id: item.reference_document_id,
            creator_document_id: user.document_id,
          },
          checkRefFromType(item.type),
          "สร้างใบนำออก"
        );
        await ActivityLogsService.createActivityLogs({
          createActivityLogInput: createActivityLog,
        });
      }
      if (navigate)
        navigate(`/inventory/issue/${encodeURIComponent(item.document_id)}`);
      if (soType.includes(input.type))
        await input.goods_issue_list.forEach(async (goods) => {
          await InventoryService.updateItemQuantity(
            goods.stock_entry_list.item_document_id,
            {
              current_committed_sales_qty: {
                decrement: goods.posted_quantity,
              },
            }
          );
        });
      else if (moType.includes(input.type))
        await input.goods_issue_list.forEach(async (goods) => {
          await InventoryService.updateItemQuantity(
            goods.stock_entry_list.item_document_id,
            {
              current_committed_manufacture_qty: {
                decrement: goods.posted_quantity,
              },
            }
          );
        });
      createLog(
        "info",
        "GoodsIssueCreateWithApprove",
        location,
        user?.email,
        input,
        item
      );
    } catch (err) {
      if (enqueueSnackbar)
        err.response.errors.forEach((error) => {
          if (errorMessageHandler(error.message)) {
            enqueueSnackbar(
              errorMessageHandler(
                error.message,
                "รหัสเอกสารนี้มีในระบบแล้ว กรุณาเรียกรหัสเอกสารใหม่"
              ),
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("สร้างเอกสารนำออกไม่สำเร็จ", {
              variant: "error",
            });
          }
        });
      createLog(
        "error",
        "GoodsIssueCreateWithApprove",
        location,
        user?.email,
        input,
        err
      );
      dispatch(goodsIssueActions.rejectedActions({ ...err, name: "item" }));
    }
  };
