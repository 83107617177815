import React, { useEffect, useState } from "react";
import {
  dateComparator,
  filterParamsOptions,
  filterStatusValueFormatter,
} from "../../utils/filterparams";
import CustomizedStatus from "../../components/Custom/CustomizedStatus";
import { Box, Typography } from "@mui/material";
import {
  unixToDateTimeWithFormat,
  unixToDateWithFormat,
} from "../../utils/date-converter";
import CustomizedAvatar from "../../components/Custom/CustomizedAvatar";
import { formatNumber } from "../../utils/dataTransformer";
import { useTranslation } from "react-i18next";

const useDeliveryTripColumnDef = (isReport) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "document_id",
      headerName: t("logistic.documentId"),
      filter: "agTextColumnFilter",
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressDoubleClickExpand: true,
      },
      width: 200,
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
    {
      field: "created_date",
      headerName: t("logistic.createdDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      sort: "desc",
      hide: true,
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "issue_date",
      headerName: t("logistic.issueDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      hide: true,
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "delivery_date",
      headerName: t("logistic.deliveryDate"),
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
      valueFormatter: (params) => unixToDateWithFormat(params.value),
    },
    {
      field: "vehicle",
      headerName: t("logistic.vehicleId"),
      filter: "agTextColumnFilter",
      width: 150,
      cellRenderer: (params) => {
        if (params.value) {
          return (
            <Box
              sx={{
                py: 1,
                px: 2,
                border: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 16,
              }}
            >
              <Typography>{params.value.license_plate}</Typography>
            </Box>
          );
        }
      },
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      sortable: false,
    },
    {
      field: "total_volume",
      headerName: t("logistic.sumVolume"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.value),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "total_weight",
      headerName: t("logistic.sumWeight"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
      valueFormatter: (params) => formatNumber(params.value),
      cellStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    {
      field: "goods_deliver_list",
      headerName: t("logistic.goodsDeliver"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => <CustomizedAvatar avatars={params.value} />,
      sortable: false,
    },
    {
      field: "trip",
      headerName: t("logistic.deliveryTrip.trip"),
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("number"),
      },
    },
    {
      field: "in_date",
      headerName: t("logistic.inDate"),
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        if (params.value) {
          return unixToDateTimeWithFormat(params.value);
        }
        return "-";
      },
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
    },
    {
      field: "out_date",
      headerName: t("logistic.outDate"),
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        if (params.value) {
          return unixToDateTimeWithFormat(params.value);
        }
        return "-";
      },
      filterParams: {
        filterOptions: filterParamsOptions("date"),
      },
    },
    {
      field: "employee_list",
      headerName: t("sales.employeeList"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
      cellRenderer: (params) => <CustomizedAvatar avatars={params.value} />,
    },
    {
      field: "render_status",
      headerName: t("sales.status"),
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        valueFormatter: (params) => {
          return filterStatusValueFormatter(params.value);
        },
        values: [
          "draft",
          "waitDeliver",
          "delivering",
          "completed",
          "notCompleted",
          "cancelled",
        ],
      },
      cellRenderer: (params) => <CustomizedStatus status={params.value} />,
      cellStyle: {
        display: "flex",
        justifycontent: "center",
        alignItems: "center",
      },
    },
    {
      field: "internal_remark",
      headerName: t("logistic.internalRemark"),
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: filterParamsOptions("string"),
      },
    },
  ]);

  useEffect(() => {
    if (isReport) {
      setColumnDefs([
        {
          field: "document_id",
          headerName: t("logistic.documentId"),
          filter: "agTextColumnFilter",
          width: 200,
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "issue_date",
          headerName: t("logistic.issueDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            // comparator: dateComparator,
          },
          hide: true,
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "delivery_date",
          headerName: t("logistic.deliveryDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            // comparator: dateComparator,
          },
          valueFormatter: (params) => unixToDateWithFormat(params.value),
        },
        {
          field: "do_customer",
          headerName: t("reports.delivery.customer"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "vehicle_license_plate",
          headerName: t("logistic.vehicleId"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          width: 150,
          cellRenderer: (params) => {
            if (params.value) {
              return (
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    border: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 16,
                  }}
                >
                  <Typography>{params.value}</Typography>
                </Box>
              );
            }
          },
          sortable: false,
        },
        {
          field: "goods_deliver_list",
          headerName: t("logistic.goodsDeliver"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "trip",
          headerName: t("logistic.deliveryTrip.trip"),
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
        },
        {
          field: "vehicle_type",
          headerName: t("reports.delivery.vehicle_type"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
        },
        {
          field: "render_status",
          headerName: t("sales.status"),
          sortable: false,
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params) => {
              return filterStatusValueFormatter(params.value);
            },
            values: [
              "draft",
              "waitDeliver",
              "delivering",
              "completed",
              "notCompleted",
              "cancelled",
            ],
          },
          cellRenderer: (params) => <CustomizedStatus status={params.value} />,
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "reference_document_id",
          headerName: t("reports.reference_document_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          hide: true,
        },
        {
          field: "external_ref_id",
          headerName: t("reports.external_ref_id"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          hide: true,
        },
        {
          field: "delivery_type",
          headerName: t("reports.delivery.delivery_type"),
          filter: "agSetColumnFilter",
        },
        {
          field: "in_date",
          headerName: t("logistic.inDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            // comparator: dateComparator,
          },
          valueFormatter: (params) => {
            if (params.value) {
              return unixToDateTimeWithFormat(params.value);
            }
            return "-";
          },
          hide: true,
        },
        {
          field: "out_date",
          headerName: t("logistic.outDate"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            // comparator: dateComparator,
          },
          valueFormatter: (params) => {
            if (params.value) {
              return unixToDateTimeWithFormat(params.value);
            }
            return "-";
          },
          hide: true,
        },
        {
          field: "do_address",
          headerName: t("reports.contact.address_list.address"),
        },
        {
          field: "do_sub_district",
          headerName: t("reports.contact.address_list.sub_district"),
        },
        {
          field: "do_district",
          headerName: t("reports.contact.address_list.district"),
        },
        {
          field: "do_province",
          headerName: t("reports.contact.address_list.province"),
        },
        {
          field: "do_document_id",
          headerName: t("reports.delivery.order.document_id"),
        },
        {
          field: "do_issue_date",
          headerName: t("reports.delivery.order.issue_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "do_delivery_date",
          headerName: t("reports.delivery.order.delivery_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            comparator: dateComparator,
          },
          hide: true,
        },
        {
          field: "do_qty_sales",
          headerName: t("logistic.salesQty"),
          valueGetter: (params) =>
            params.data.do_qty_sales
              ? formatNumber(params.data.do_qty_sales)
              : 0,
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
          hide: true,
        },
        {
          field: "do_qty_delivery",
          headerName: t("logistic.deliveryQty"),
          valueGetter: (params) =>
            params.data.do_qty_delivery
              ? formatNumber(params.data.do_qty_delivery)
              : 0,
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
        },
        {
          field: "do_employee_list",
          headerName: t("reports.delivery.order.employee_list"),
          filter: "agSetColumnFilter",
          hide: true,
        },
        {
          field: "do_status",
          headerName: t("reports.delivery.order.status"),
          filterParams: {
            valueFormatter: (params) => {
              return filterStatusValueFormatter(params.value);
            },
            values: [
              "draft",
              "waitDeliver",
              "completed",
              "notCompleted",
              "cancelled",
            ],
          },
          cellRenderer: (params) => <CustomizedStatus status={params.value} />,
          cellStyle: {
            display: "flex",
            justifycontent: "center",
            alignItems: "center",
          },
        },
        {
          field: "delivery_remark",
          headerName: t("reports.remark"),
          filter: "agTextColumnFilter",
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "total_volume",
          headerName: t("logistic.sumVolume"),
          valueGetter: (params) => formatNumber(params.data.total_volume),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
        },
        {
          field: "total_weight",
          headerName: t("logistic.sumWeight"),
          valueGetter: (params) => formatNumber(params.data.total_weight),
          cellStyle: {
            display: "flex",
            justifyContent: "flex-end",
          },
          filter: "agNumberColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("number"),
          },
        },
        {
          field: "created_date",
          headerName: t("reports.created_date"),
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("date"),
            // comparator: dateComparator,
          },
          valueGetter: (params) => unixToDateWithFormat(params.value),
          sort: "desc",
          hide: true,
        },
        {
          field: "updated_date",
          headerName: t("reports.updated_date"),
          width: 175,
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          field: "created_by",
          headerName: t("reports.created_by"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          hide: true,
        },
        {
          field: "employee_list",
          headerName: t("sales.employeeList"),
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: filterParamsOptions("string"),
          },
          hide: true,
        },
        {
          field: "internal_remark",
          headerName: t("logistic.internalRemark"),
          hide: true,
          suppressColumnsToolPanel: true,
        },
      ]);
    }
  }, [isReport, t]);
  return columnDefs;
};

export default useDeliveryTripColumnDef;
