import { createSlice } from "@reduxjs/toolkit";
import { unixToDate } from "../../../utils/date-converter";
import { initialState } from "./goodsAdjustment-intitials";

const goodsAdjustmentSlice = createSlice({
  name: "goodsAdjustment",
  initialState,
  reducers: {
    onLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    rejectedActions(state, action) {
      state.isLoading[action.payload.name] = false;
      state.error = action.payload;
    },
    loadedAllItems(state, action) {
      state.allItems = action.payload;
      state.isLoading.allItems = false;
    },
    loadedAllCount(state, action) {
      state.count = action.payload;
    },
    loadedItem(state, action) {
      const formatItem = {
        ...action.payload,
        created_date: unixToDate(action.payload.created_date),
        document_date: unixToDate(action.payload.document_date),
        goods_adjustment_list: action.payload.goods_adjustment_list.map(
          (list) => {
            return {
              ...list,
              stock_entry_list: list.stock_entry_list[0],
            };
          }
        ),
      };
      state.item = formatItem;
      state.isLoading.item = false;
    },
    resetAllItems(state) {
      state.allItems = [];
    },
    resetItem(state) {
      state.item = initialState.item;
    },
  },
});

export const goodsAdjustmentActions = goodsAdjustmentSlice.actions;

export default goodsAdjustmentSlice.reducer;
