import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import Inventory from "./pages/Inventory";
import Items from "./pages/Inventory/Items";
import ItemsImporterWithUomGroup from "./pages/Inventory/Items/ImporterWithUomGroup";
import GoodsReceive from "./pages/Inventory/Receive";
import GoodsReceiveReturn from "./pages/Inventory/Return";
import GoodsIssue from "./pages/Inventory/Issue";
import GoodsTransfer from "./pages/Inventory/Transfer";
import Adjustment from "./pages/Inventory/Adjustment";
import InventoryReport from "./pages/Inventory/Report";
import Contact from "./pages/Contact";
import Contacts from "./pages/Contact/contacts";
import NewContactContainer from "./pages/Contact/NewContactContainer";
import ContactImporter from "./pages/Contact/Importer";
import ContactReportIndex from "./pages/Contact/Report";
import ContactReportPage from "./pages/Contact/Report/ContactReportPage";
import Sales from "./pages/Sales";
import Quotation from "./pages/Sales/Quotation";
import SalesOrder from "./pages/Sales/Order";
import SalesReturn from "./pages/Sales/Return";
import SalesReport from "./pages/Sales/Report";
import Purchase from "./pages/Purchase";
import PurchaseRequest from "./pages/Purchase/Request";
import PurchaseOrder from "./pages/Purchase/Order";
import PurchaseReturn from "./pages/Purchase/Return";
import PurchaseReport from "./pages/Purchase/Report";
import Logistic from "./pages/Logistic";
import DeliveryOrder from "./pages/Logistic/DeliveryOrder";
import DeliveryTrip from "./pages/Logistic/DeliveryTrip";
import LogisticReport from "./pages/Logistic/Report";
import DeliveryOrderReport from "./pages/Logistic/Report/DeliveryOrderReport";
import DeliveryTripReport from "./pages/Logistic/Report/DeliveryTripReport";
import Manufacture from "./pages/Manufacture";
import Bom from "./pages/Manufacture/Bom";
import ManufacturingOrder from "./pages/Manufacture/Order";
import WorkOrder from "./pages/Manufacture/WorkOrder";
import ManufactureReport from "./pages/Manufacture/Report";
import BomReport from "./pages/Manufacture/Report/BomReport";
import ManufactureOrderReport from "./pages/Manufacture/Report/ManufactureOrderReport";
import WorkOrderReport from "./pages/Manufacture/Report/WorkOrderReport";
import UnderqualityItemsReport from "./pages/Manufacture/Report/UnderqualityItemsReport";
import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import Rbac from "./pages/User/Rbac";
import Setting from "./pages/Setting";
import Login from "./pages/Login";
import RequireAuth from "./pages/RequireAuth";
import AccountContainer from "./pages/User/Account/Container";
import ItemMasterContainer from "./pages/Inventory/Items/Container";
import { useAuth, usePermission } from "./hooks/use-auth";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import RbacContainer from "./pages/User/Rbac/Container";
import GoodsReceiveContainer from "./pages/Inventory/Receive/Container";
import GoodsReceiveReturnContainer from "./pages/Inventory/Return/Container";
import AdjustmentContainer from "./pages/Inventory/Adjustment/Container";
import GoodsTransferContainer from "./pages/Inventory/Transfer/Container";
import DashboardLayout from "./components/Layout/DashboardLayout";
import Conversion from "./pages/Setting/Inventory/Conversion";
import Attribute from "./pages/Setting/Inventory/Attribute";
import InventorySetting from "./pages/Setting/Inventory";
import UomGroup from "./pages/Setting/Inventory/UomGroup";
import Uom from "./pages/Setting/Inventory/Uom";
import UomGroupImporter from "./pages/Setting/Inventory/UomGroupImporter";
import Location from "./pages/Setting/Inventory/Location";
import Category from "./pages/Setting/Inventory/Category";
import NotFound from "./pages/NotFound";
import GoodsReceiptReport from "./pages/Inventory/Report/GoodsReceiptReport";
import GoodsReceiptReturnReport from "./pages/Inventory/Report/GoodsReceiptReturnReport";
import GoodsIssueReport from "./pages/Inventory/Report/GoodsIssueReport";
import GoodsTransferReport from "./pages/Inventory/Report/GoodsTransferReport";
import GoodsAdjustmentReport from "./pages/Inventory/Report/GoodsAdjustmentReport";
import StockEntry from "./pages/Inventory/Report/StockEntryReport";
import TraceEntry from "./pages/Inventory/Report/TraceEntryReport";
import ItemNoEntry from "./pages/Inventory/Report/ItemNoEntry";
import ReturnContainer from "./pages/Sales/Return/Container";
import SalesByCustomer from "./pages/Sales/Report/SalesByCustomer";
import SalesByItem from "./pages/Sales/Report/SalesByItem";
import QuotationPDF from "./pages/Sales/Quotation/Pdf";
import SalesOrderPDF from "./pages/Sales/Order/Pdf";
import ItemCurrentStockLocation from "./pages/Inventory/Report/ItemCurrentStockLocation";
import ItemCurrentStockSerialNumber from "./pages/Inventory/Report/ItemCurrentStockSerialNumber";
import NotActiveSerialNumber from "./pages/Inventory/Report/NotActiveSerialNumber";
import ItemCurrentStock from "./pages/Inventory/Report/ItemCurrentStock";
import QuotationContainer from "./pages/Sales/Quotation/Container";
import OrderContainer from "./pages/Sales/Order/Container";
import ItemMasterReport from "./pages/Inventory/Report/ItemMasterReport";
import ApprovalSetting from "./pages/Setting/Approval";
import SalesApproval from "./pages/Setting/Approval/Sales";
import PurchaseApproval from "./pages/Setting/Approval/Purchase";
import LogisticApproval from "./pages/Setting/Approval/Logistic";
import ManufactureApproval from "./pages/Setting/Approval/Manufacture";
import CompanyInfoSetting from "./pages/Setting/CompanyInfo";
import Approval from "./pages/Approval";
import DeliveryOrderContainer from "./pages/Logistic/DeliveryOrder/Container";
import QuotationImporter from "./pages/Sales/Quotation/importer";
import SalesOrderImporter from "./pages/Sales/Order/importer";
import SalesTemplate from "./pages/Setting/Remark/Sales";
import PurchaseTemplate from "./pages/Setting/Remark/Purchase";
import LogisticTemplate from "./pages/Setting/Remark/Logistic";
import LogisticSetting from "./pages/Setting/Logistic";
import VehicleSetting from "./pages/Setting/Logistic/Vehicle";
import VehicleInfo from "./pages/Setting/Logistic/Vehicle/VehicleInfo";
import QuotationReport from "./pages/Sales/Report/QuotationReport";
import SalesOrderReport from "./pages/Sales/Report/SalesOrderReport";
import SalesReturnReport from "./pages/Sales/Report/SalesReturnReport";
import { RemoveTrailingSlash } from "./pages/RemoveTrailingSlash";
import DeliveryTripContainer from "./pages/Logistic/DeliveryTrip/Container";
import GoodsIssueContainer from "./pages/Inventory/Issue/Container";
import GoodsTransferPDF from "./pages/Inventory/Transfer/Pdf";
import AuthVerify from "./pages/AuthVerify";
import DeliveryOrderPDF from "./pages/Logistic/DeliveryOrder/Pdf";
import DeliveryTripPDF from "./pages/Logistic/DeliveryTrip/Pdf";
import PurchaseRequestContainer from "./pages/Purchase/Request/Container";
import PurchaseOrderContainer from "./pages/Purchase/Order/Container";
import PurchaseReturnContainer from "./pages/Purchase/Return/Container";
import BomContainer from "./pages/Manufacture/Bom/Container";
import BomImporter from "./pages/Manufacture/Bom/Importer";
import PurchaseOrderPDF from "./pages/Purchase/Order/Pdf";
import PurchaseRequestReport from "./pages/Purchase/Report/RequestReport";
import PurchaseOrderReport from "./pages/Purchase/Report/OrderReport";
import PurchaseReturnReport from "./pages/Purchase/Report/ReturnReport";
import PurchaseByVendor from "./pages/Purchase/Report/PurchaseByVendor";
import PurchaseByItem from "./pages/Purchase/Report/PurchaseByItem";
import PurchaseRequestPDF from "./pages/Purchase/Request/Pdf";
import RemarkSetting from "./pages/Setting/Remark";
import ManufactureOrderContainer from "./pages/Manufacture/Order/Container";
import WorkOrderContainer from "./pages/Manufacture/WorkOrder/Container";
import ManufactureSetting from "./pages/Setting/Manufacture";
import WorkCenterSetting from "./pages/Setting/Manufacture/WorkCenter";
import ManufactureTemplate from "./pages/Setting/Remark/Manufacture";
import BomPdf from "./pages/Manufacture/Bom/Pdf";
import ManufactureOrderPdf from "./pages/Manufacture/Order/Pdf";
import Unauthorized from "./pages/Unauthoried";
import Landing from "./pages/Landing";
import OnlineVerify from "./pages/OnlineVerify";
import Logout from "./pages/Logout";
// import DeliveryTripReportView from "./pages/Logistic/Report/DeliveryTripReportView";
// import ItemCurrentStockView from "./pages/Inventory/Report/ItemCurrentStockView";
// import ItemCurrentStockSerialNumberView from "./pages/Inventory/Report/ItemCurrentStockSerialNumberView";
// import GoodsReceiveReportView from "./pages/Inventory/Report/GoodsReceiveReportView";
// import GoodsIssueReportView from "./pages/Inventory/Report/GoodsIssueReportView";
// import StockEntryReportView from "./pages/Inventory/Report/StockEntryReportView";
// import DeliveryOrderReportView from "./pages/Logistic/Report/DeliveryOrderReportView";
// import SalesOrderReportView from "./pages/Sales/Report/SalesOrderReportView";
// import TraceEntryReportView from "./pages/Inventory/Report/TraceEntryReportView";
// import NotActiveSerialNumberView from "./pages/Inventory/Report/NotActiveSerialNumberView";
// import ItemNoEntryReportView from "./pages/Inventory/Report/ItemNoEntryReportView";
// import ItemCurrentStockLocationView from "./pages/Inventory/Report/ItemCurrentStockLocationView";

function App() {
  const {
    isInitialized,
    isAuthenticated,
    isAccessTokenExpired,
    isRefreshTokenExpired,
  } = useAuth();
  const {
    itemPermission,
    goodsReceivePermission,
    goodsReceiveReturnPermission,
    goodsIssuePermission,
    goodsAdjustmentPermission,
    goodsTransferPermission,
    inventoryReportPermission,
    contactPermission,
    // customerPermission,
    // vendorPermission,
    contactReportPermission,
    quotationPermission,
    salesOrderPermission,
    salesReturnPermission,
    salesReportPermission,
    purchaseRequestPermission,
    purchaseOrderPermission,
    purchaseReturnPermission,
    purchaseReportPermission,
    deliveryOrderPermission,
    deliveryTripPermission,
    deliveryReportPermission,
    bomPermission,
    manufactureOrderPermission,
    workOrderPermission,
    manufactureReportPermission,
    accountPermission,
    rbacPermission,
    companyInfoPermission,
    approvalSettingPermission,
    salesRemarkPermission,
    purchaseRemarkPermission,
    logisticRemarkPermission,
    manufactureRemarkPermission,
    locationPermission,
    categoryPermission,
    uomPermission,
    attributePermission,
    manufactureSettingPermission,
    logisticSettingPermission,
  } = usePermission();

  return (
    <>
      <RemoveTrailingSlash />
      <AuthVerify />
      <OnlineVerify />
      {(!isInitialized || isAccessTokenExpired) && !isRefreshTokenExpired && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {isInitialized && (
        <>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated && !isRefreshTokenExpired ? (
                  <Navigate replace to="/landing" />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route
              path="/login"
              element={
                isAuthenticated && !isRefreshTokenExpired ? (
                  <Navigate replace to="/landing" />
                ) : (
                  <Login />
                )
              }
            />
            {!isAccessTokenExpired && (
              <>
                <Route
                  element={
                    <RequireAuth
                      allowedPermissions={["GENERAL__GENERAL__VIEW"]}
                    />
                  }
                >
                  <Route element={<DashboardLayout />}>
                    <>
                      <Route path="/landing" element={<Landing />} />
                      <Route path="/inventory">
                        <Route index element={<Inventory />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[itemPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="items">
                            <Route index element={<Items />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[itemPermission.CREATE]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<ItemMasterContainer />}
                              />
                              <Route
                                path="importer"
                                element={<ItemsImporterWithUomGroup />}
                              />
                            </Route>
                            <Route
                              path=":id"
                              element={<ItemMasterContainer />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[goodsReceivePermission.VIEW]}
                            />
                          }
                        >
                          <Route path="receive">
                            <Route index element={<GoodsReceive />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    goodsReceivePermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<GoodsReceiveContainer />}
                              />
                            </Route>
                            <Route
                              path=":id"
                              element={<GoodsReceiveContainer />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                goodsReceiveReturnPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="return">
                            <Route index element={<GoodsReceiveReturn />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    goodsReceiveReturnPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<GoodsReceiveReturnContainer />}
                              />
                            </Route>
                            <Route
                              path=":id"
                              element={<GoodsReceiveReturnContainer />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[goodsIssuePermission.VIEW]}
                            />
                          }
                        >
                          <Route path="issue">
                            <Route index element={<GoodsIssue />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    goodsIssuePermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<GoodsIssueContainer isCreate />}
                              />
                            </Route>
                            <Route
                              path=":id"
                              element={<GoodsIssueContainer />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                goodsAdjustmentPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="adjustment">
                            <Route index element={<Adjustment />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    goodsAdjustmentPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<AdjustmentContainer isCreate />}
                              />
                            </Route>
                            <Route
                              path=":id"
                              element={<AdjustmentContainer />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                goodsTransferPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="transfer">
                            <Route index element={<GoodsTransfer />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    goodsTransferPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<GoodsTransferContainer isCreate />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route
                                index
                                element={<GoodsTransferContainer />}
                              />
                              <Route
                                path="pdf"
                                element={<GoodsTransferPDF />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                inventoryReportPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="report">
                            <Route index element={<InventoryReport />} />
                            <Route
                              path="items"
                              element={<ItemMasterReport />}
                            />
                            <Route
                              path="receive"
                              element={<GoodsReceiptReport />}
                            />
                            {/* <Route
                              path="receive"
                              element={<GoodsReceiveReportView />}
                            /> */}
                            <Route
                              path="return"
                              element={<GoodsReceiptReturnReport />}
                            />
                            <Route
                              path="issue"
                              element={<GoodsIssueReport />}
                            />
                            {/* <Route
                              path="issue"
                              element={<GoodsIssueReportView />}
                            /> */}
                            <Route
                              path="transfer"
                              element={<GoodsTransferReport />}
                            />
                            <Route
                              path="adjustment"
                              element={<GoodsAdjustmentReport />}
                            />
                            <Route
                              path="stock-entry"
                              element={<StockEntry />}
                            />
                            {/* <Route
                              path="stock-entry"
                              element={<StockEntryReportView />}
                            /> */}
                            <Route
                              path="trace-entry"
                              element={<TraceEntry />}
                            />
                            {/* <Route
                              path="trace-entry"
                              element={<TraceEntryReportView />}
                            /> */}
                            <Route
                              path="items-no-entry"
                              element={<ItemNoEntry />}
                            />
                            {/* <Route
                              path="items-no-entry"
                              element={<ItemNoEntryReportView />}
                            /> */}
                            <Route
                              path="itemcurrent-stock"
                              element={<ItemCurrentStock />}
                            />
                            {/* <Route
                              path="itemcurrent-stock"
                              element={<ItemCurrentStockView />}
                            /> */}
                            <Route
                              path="itemcurrent-stock-location"
                              element={<ItemCurrentStockLocation />}
                            />
                            {/* <Route
                              path="itemcurrent-stock-location"
                              element={<ItemCurrentStockLocationView />}
                            /> */}
                            <Route
                              path="itemcurrent-stock-serial-number"
                              element={<ItemCurrentStockSerialNumber />}
                            />
                            {/* <Route
                              path="itemcurrent-stock-serial-number"
                              element={<ItemCurrentStockSerialNumberView />}
                            /> */}
                            <Route
                              path="not-active-serial-number"
                              element={<NotActiveSerialNumber />}
                            />
                            {/* <Route
                              path="not-active-serial-number"
                              element={<NotActiveSerialNumberView />}
                            /> */}
                          </Route>
                        </Route>
                      </Route>
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[contactPermission.VIEW]}
                          />
                        }
                      >
                        <Route path="/contact">
                          <Route index element={<Contact />} />
                          <Route path="contacts" element={<Contacts />} />
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[contactPermission.CREATE]}
                              />
                            }
                          >
                            <Route
                              path="add"
                              element={<NewContactContainer />}
                            />
                          </Route>
                          <Route
                            path="edit/:contactId"
                            element={<NewContactContainer />}
                          />
                          <Route
                            path="importer"
                            element={<ContactImporter />}
                          />
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[
                                  contactReportPermission.VIEW,
                                ]}
                              />
                            }
                          >
                            <Route path="report">
                              <Route index element={<ContactReportIndex />} />
                              <Route
                                path="all"
                                element={<ContactReportPage />}
                              />
                              <Route
                                path="customer"
                                element={<ContactReportPage />}
                              />
                              <Route
                                path="vendor"
                                element={<ContactReportPage />}
                              />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route path="/sales">
                        <Route index element={<Sales />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[quotationPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="quotation">
                            <Route index element={<Quotation />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    quotationPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<QuotationContainer />}
                              />
                              <Route
                                path="importer"
                                element={<QuotationImporter />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route index element={<QuotationContainer />} />
                              <Route path="pdf" element={<QuotationPDF />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[salesOrderPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="order">
                            <Route index element={<SalesOrder />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    salesOrderPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route path="add" element={<OrderContainer />} />
                              <Route
                                path="importer"
                                element={<SalesOrderImporter />}
                              />
                            </Route>

                            <Route path=":id">
                              <Route index element={<OrderContainer />} />
                              <Route path="pdf" element={<SalesOrderPDF />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[salesReturnPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="return">
                            <Route index element={<SalesReturn />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    salesReturnPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route path="add" element={<ReturnContainer />} />
                            </Route>
                            <Route path=":id">
                              <Route index element={<ReturnContainer />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[salesReportPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="report">
                            <Route index element={<SalesReport />} />
                            <Route
                              path="sales-by-customer"
                              element={<SalesByCustomer />}
                            />
                            <Route
                              path="sales-by-item"
                              element={<SalesByItem report />}
                            />
                            <Route
                              path="quotation"
                              element={<QuotationReport />}
                            />
                            <Route
                              path="order"
                              element={<SalesOrderReport />}
                            />
                            {/* <Route
                              path="order"
                              element={<SalesOrderReportView />}
                            /> */}
                            <Route
                              path="return"
                              element={<SalesReturnReport />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="/purchase">
                        <Route index element={<Purchase />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                purchaseRequestPermission.VIEW,
                                purchaseRequestPermission.VIEW_SELF,
                              ]}
                            />
                          }
                        >
                          <Route path="request">
                            <Route index element={<PurchaseRequest />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    purchaseRequestPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                element={
                                  <RequireAuth
                                    allowedPermissions={[
                                      purchaseRequestPermission.CREATE,
                                    ]}
                                  />
                                }
                              >
                                <Route
                                  path="add"
                                  element={<PurchaseRequestContainer />}
                                />
                              </Route>
                            </Route>
                            {/* <Route path="importer" element={<QuotationImporter />} /> */}
                            <Route path=":id">
                              <Route
                                index
                                element={<PurchaseRequestContainer />}
                              />
                              <Route
                                path="pdf"
                                element={<PurchaseRequestPDF />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                purchaseOrderPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="order">
                            <Route index element={<PurchaseOrder />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    purchaseOrderPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<PurchaseOrderContainer />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route
                                index
                                element={<PurchaseOrderContainer />}
                              />
                              <Route
                                path="pdf"
                                element={<PurchaseOrderPDF />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                purchaseReturnPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="return">
                            <Route index element={<PurchaseReturn />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    purchaseReturnPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<PurchaseReturnContainer />}
                              />
                            </Route>
                            {/* <Route path="importer" element={<QuotationImporter />} /> */}
                            <Route path=":id">
                              <Route
                                index
                                element={<PurchaseReturnContainer />}
                              />
                              {/* <Route path="pdf" element={<QuotationPDF />} /> */}
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                purchaseReportPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="report">
                            <Route index element={<PurchaseReport />} />
                            <Route
                              path="purchase-by-vendor"
                              element={<PurchaseByVendor />}
                            />
                            <Route
                              path="purchase-by-item"
                              element={<PurchaseByItem />}
                            />
                            <Route
                              path="request"
                              element={<PurchaseRequestReport />}
                            />
                            <Route
                              path="order"
                              element={<PurchaseOrderReport />}
                            />
                            <Route
                              path="return"
                              element={<PurchaseReturnReport />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="/logistic">
                        <Route index element={<Logistic />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                deliveryOrderPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="delivery-order">
                            <Route index element={<DeliveryOrder />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    deliveryOrderPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<DeliveryOrderContainer />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route
                                index
                                element={<DeliveryOrderContainer />}
                              />
                              <Route
                                path="pdf"
                                element={<DeliveryOrderPDF />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[deliveryTripPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="delivery-trip">
                            <Route index element={<DeliveryTrip />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    deliveryTripPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<DeliveryTripContainer />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route
                                index
                                element={<DeliveryTripContainer />}
                              />
                              <Route path="pdf" element={<DeliveryTripPDF />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                deliveryReportPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="report">
                            <Route index element={<LogisticReport />} />
                            <Route
                              path="delivery-order"
                              element={<DeliveryOrderReport />}
                            />
                            {/* <Route
                              path="delivery-order"
                              element={<DeliveryOrderReportView />}
                            /> */}
                            <Route
                              path="delivery-trip"
                              element={<DeliveryTripReport />}
                            />
                            {/* <Route
                              path="delivery-trip"
                              element={<DeliveryTripReportView />}
                            /> */}
                          </Route>
                        </Route>
                      </Route>
                      <Route path="/manufacture">
                        <Route index element={<Manufacture />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[bomPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="bom">
                            <Route index element={<Bom />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[bomPermission.CREATE]}
                                />
                              }
                            >
                              <Route path="add" element={<BomContainer />} />
                              <Route
                                path="importer"
                                element={<BomImporter />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route index element={<BomContainer />} />
                              <Route path="pdf" element={<BomPdf />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                manufactureOrderPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="order">
                            <Route index element={<ManufacturingOrder />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    manufactureOrderPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<ManufactureOrderContainer />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route
                                index
                                element={<ManufactureOrderContainer />}
                              />
                              <Route
                                path="pdf"
                                element={<ManufactureOrderPdf />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[workOrderPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="work-order">
                            <Route index element={<WorkOrder />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    workOrderPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<WorkOrderContainer />}
                              />
                            </Route>
                            <Route path=":id">
                              <Route index element={<WorkOrderContainer />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                manufactureReportPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="report">
                            <Route index element={<ManufactureReport />} />
                            <Route path="bom" element={<BomReport />} />
                            <Route
                              path="order"
                              element={<ManufactureOrderReport />}
                            />
                            <Route
                              path="work-order"
                              element={<WorkOrderReport />}
                            />
                            <Route
                              path="under-quality"
                              element={<UnderqualityItemsReport />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route path="/user">
                        <Route index element={<User />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[accountPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="account">
                            <Route index element={<UserAccount />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[
                                    accountPermission.CREATE,
                                  ]}
                                />
                              }
                            >
                              <Route
                                path="add"
                                element={<AccountContainer addable />}
                              />
                            </Route>
                            <Route
                              path=":employeeId"
                              element={<AccountContainer viewOnly />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[rbacPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="rbac">
                            <Route index element={<Rbac />} />
                            <Route
                              element={
                                <RequireAuth
                                  allowedPermissions={[rbacPermission.CREATE]}
                                />
                              }
                            >
                              <Route path="add" element={<RbacContainer />} />
                            </Route>
                            <Route
                              path=":roleId"
                              element={<RbacContainer viewOnly />}
                            />
                          </Route>
                        </Route>
                        <Route path="setting">
                          <Route index element={<AccountContainer setting />} />
                        </Route>
                      </Route>
                      <Route path="/setting">
                        <Route index element={<Setting />} />
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                locationPermission.VIEW,
                                categoryPermission.VIEW,
                                uomPermission.VIEW,
                                attributePermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="inventory">
                            <Route index element={<InventorySetting />} />
                            <Route path="attribute" element={<Attribute />} />
                            <Route path="uom" element={<Uom />} />
                            <Route path="location" element={<Location />} />
                            <Route path="category" element={<Category />} />
                            <Route path="uomgroup">
                              <Route index element={<UomGroup />} />
                              <Route path=":id" element={<Conversion />} />
                              <Route
                                path="importer"
                                element={<UomGroupImporter />}
                              />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                approvalSettingPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="approval">
                            <Route index element={<ApprovalSetting />} />
                            <Route path="sales" element={<SalesApproval />} />
                            <Route
                              path="purchase"
                              element={<PurchaseApproval />}
                            />
                            <Route
                              path="logistic"
                              element={<LogisticApproval />}
                            />
                            <Route
                              path="manufacture"
                              element={<ManufactureApproval />}
                            />
                          </Route>
                        </Route>
                        <Route path="remark">
                          <Route index element={<RemarkSetting />} />
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[
                                  salesRemarkPermission.VIEW,
                                ]}
                              />
                            }
                          >
                            <Route path="sales" element={<SalesTemplate />} />
                          </Route>
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[
                                  purchaseRemarkPermission.VIEW,
                                ]}
                              />
                            }
                          >
                            <Route
                              path="purchase"
                              element={<PurchaseTemplate />}
                            />
                          </Route>
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[
                                  logisticRemarkPermission.VIEW,
                                ]}
                              />
                            }
                          >
                            <Route
                              path="logistic"
                              element={<LogisticTemplate />}
                            />
                          </Route>
                          <Route
                            element={
                              <RequireAuth
                                allowedPermissions={[
                                  manufactureRemarkPermission.VIEW,
                                ]}
                              />
                            }
                          >
                            <Route
                              path="manufacture"
                              element={<ManufactureTemplate />}
                            />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                logisticSettingPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="logistic">
                            <Route index element={<LogisticSetting />} />
                            <Route path="vehicle">
                              <Route index element={<VehicleSetting />} />
                              <Route
                                element={
                                  <RequireAuth
                                    allowedPermissions={[
                                      logisticSettingPermission.CREATE,
                                    ]}
                                  />
                                }
                              >
                                <Route path="add" element={<VehicleInfo />} />
                              </Route>
                              <Route path=":id" element={<VehicleInfo />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[companyInfoPermission.VIEW]}
                            />
                          }
                        >
                          <Route path="company-info">
                            <Route index element={<CompanyInfoSetting />} />
                          </Route>
                        </Route>
                        <Route
                          element={
                            <RequireAuth
                              allowedPermissions={[
                                manufactureSettingPermission.VIEW,
                              ]}
                            />
                          }
                        >
                          <Route path="manufacture">
                            <Route index element={<ManufactureSetting />} />
                            <Route
                              path="work-center"
                              element={<WorkCenterSetting />}
                            />
                          </Route>
                        </Route>
                      </Route>
                      <Route
                        element={
                          <RequireAuth
                            allowedPermissions={[
                              approvalSettingPermission.VIEW,
                            ]}
                          />
                        }
                      >
                        <Route path="approval">
                          <Route index element={<Approval />} />
                        </Route>
                      </Route>
                    </>
                  </Route>
                </Route>
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/*" element={<NotFound />}></Route>
                <Route path="logout" element={<Logout />} />
              </>
            )}
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
